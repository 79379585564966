import { Nullable } from "@kitware/vtk.js/types";
import { getPatientName } from "./data";
/**
 * Check if a file is a DICOM or not.
 */
export function isDICOM(file: File): boolean {
  const extension = file.name.split('.').pop()?.toLowerCase();
  return extension ? extension === 'dcm': file.type === 'application/dicom';
}

function addDigit(value: number): number | string {
  return value.toString().length === 1 ? `0${value}` : value;
}


export function getExportFileName(): Nullable<string> {
  const patientName = getPatientName();
  if (patientName) {
    const today = new Date();

    // NAME_YYYYMMDD_HHhMMmSSs.json
    const year = today.getFullYear();

    const month: number | string =  addDigit(today.getMonth() + 1);
    const day: number | string = addDigit(today.getDate());
    const hours: number | string = addDigit(today.getHours());
    const minutes: number | string = addDigit(today.getMinutes());
    const seconds: number | string = addDigit(today.getSeconds());

    return `${patientName}_${year}${month}${day}_${hours}h${minutes}m${seconds}s.json`
  }
  return null;
}
