import Vue from 'vue';
import { defineStore } from 'pinia';

import { Nullable } from '@kitware/vtk.js/types';

import { ClassicCorrector } from '@/src/types/corrector';
import { getDefaultCorrector } from '../utils/correctors';

interface State {
  originalsCorrectors: Nullable<Record<string, ClassicCorrector[]>>;
  corrections: Nullable<ClassicCorrector[]>;
  correctorNames: Nullable<String[]>;
}

export const useCorrectorsStore = defineStore('correctors', {
  state: (): State => ({
    originalsCorrectors: null,
    corrections: null,
    correctorNames: null,
  }),
  getters: {
    getCorrectorList(state) {
      return (correctorName: string) => state.originalsCorrectors ? state.originalsCorrectors[correctorName] : null;
    }
  },
  actions: {
    async loadCorrectors() {
      const token = await Vue.prototype.$auth.getTokenSilently({ audience: process.env.VUE_APP_API_URL });
      const params = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      };
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/api/correctors/list`,
        params
      );
      this.originalsCorrectors = (await response.json()) || null;

      if (this.originalsCorrectors) {
        // Compute possible corrections
        this.corrections = [...Object.values(this.originalsCorrectors).flat()];

        this.corrections.push(getDefaultCorrector('Classic') as ClassicCorrector);

        // Ensure corrections is composed of unique correction
        const uniqueIds: string[] = [];
        this.corrections = this.corrections.filter((correction) => {
          const id: string = `${correction.translations.Coronal}/${correction.translations.Sagittal}/${correction.rotations.Coronal}/${correction.rotations.Sagittal}`;
          const found = uniqueIds.find(i => i === id) !== undefined;

          if (!found) {
            uniqueIds.push(id);
          }
          
          return !found
        });
        this.correctorNames = Object.keys(this.originalsCorrectors);
      }
    },
  },
});
