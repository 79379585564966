var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vtk-container-wrapper vtk-three-container",attrs:{"id":"threed-view"}},[_c('div',{staticClass:"vtk-container",class:_vm.active ? 'active' : ''},[_c('div',{staticClass:"vtk-sub-container"},[_c('div',{ref:"vtkContainerRef",staticClass:"vtk-view"})]),_c('div',{staticClass:"overlay-no-events tool-layer"},[_c('crop-tool',{attrs:{"view-id":_vm.viewID}}),_c('pan-tool',{attrs:{"view-id":_vm.viewID}})],1),_c('view-overlay-grid',{staticClass:"overlay-no-events view-annotations",scopedSlots:_vm._u([{key:"top-left",fn:function(){return [_c('div',{staticClass:"annotation-cell"},[_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pointer-events-all",attrs:{"dark":"","x-small":"","icon":""},on:{"click":_vm.resetCamera}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"py-1",attrs:{"small":""}},[_vm._v("mdi-camera-flip-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('app.3DView.reset')))])]),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.topLeftLabel))])],1)]},proxy:true},{key:"middle-left",fn:function(){return [_c('v-container',{staticClass:"pa-0 buttons-3d",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pointer-events-all",attrs:{"dark":"","icon":"","large":""},on:{"click":_vm.showProfile}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"py-1"},[_vm._v("mdi-face-man-profile")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('app.3DView.volumicView')))])]),_c('v-tooltip',{attrs:{"right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pointer-events-all",attrs:{"dark":"","icon":"","large":""},on:{"click":_vm.showVolumicRendering}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"py-1"},[_vm._v("mdi-face-man")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('app.3DView.longitudinalView')))])]),_c('v-tooltip',{attrs:{"right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pointer-events-all",attrs:{"dark":"","icon":"","large":""},on:{"click":_vm.showLongitudinalRendering}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"py-1"},[_vm._v("mdi-tooth-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('app.3DView.upperView')))])]),_c('v-tooltip',{attrs:{"right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pointer-events-all",attrs:{"dark":"","icon":"","large":""},on:{"click":_vm.showOrHideInactiveImplants}},'v-btn',attrs,false),on),[(!_vm.inactiveImplantsVisibility)?_c('v-icon',{staticClass:"py-1"},[_vm._v("mdi-eye")]):_c('v-icon',{staticClass:"py-1"},[_vm._v("mdi-eye-off")])],1)]}}])},[(_vm.inactiveImplantsVisibility)?_c('span',[_vm._v(_vm._s(_vm.$t('app.3DView.hideInactiveImplants')))]):_c('span',[_vm._v(_vm._s(_vm.$t('app.3DView.showInactiveImplants')))])])],1)],1)],1)]},proxy:true}])}),_c('transition',{attrs:{"name":"loading"}},[(_vm.isImageLoading)?_c('div',{staticClass:"overlay-no-events loading"},[_c('div',[_vm._v(_vm._s(_vm.$t('app.3DView.loadingImage')))]),_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"blue"}})],1)]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }