import i18n from "@/translation/i18n";
import { Nullable } from "@kitware/vtk.js/types";

const GENERIC_NAME = 'Generic';

export function isGenericManufacturerOrModel(manufacturerOrModel: string) {
  return !!(manufacturerOrModel === GENERIC_NAME);
}

export function isGenericImplant(manufacturer: Nullable<string>, model: Nullable<string>) {
  return manufacturer && model && isGenericManufacturerOrModel(manufacturer) && isGenericManufacturerOrModel(model);
}

export function formatImplantReference(
  model: string,
  diameter: number,
  length: number,
  reference: string,
) {
  if (isGenericManufacturerOrModel(model)) {
    return `${i18n.t('implantModel.Generic')} ref.${diameter}-${length}`;
  }
  return `${model} ref.${reference}`;
}
