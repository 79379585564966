import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import macro from '@kitware/vtk.js/macros';
import vtk from '@kitware/vtk.js/vtk';

// ----------------------------------------------------------------------------
// vtkScalarsFilter methods
// ----------------------------------------------------------------------------

function vtkScalarsFilter(publicAPI, model) {
  // Set our classname
  model.classHierarchy.push('vtkScalarsFilter');

  const createColorDataArray = (size) => {
    const nbElement = 4;
    /* eslint-disable prefer-destructuring */
    const array = new Uint8Array(size * nbElement);
    for (let i = 0; i < size * nbElement; i += nbElement) {
      array[i + 0] = model.color[0];
      array[i + 1] = model.color[1];
      array[i + 2] = model.color[2];
      array[i + 3] = model.opacity;
    }
    return vtkDataArray.newInstance({
      name: 'color',
      numberOfComponents: nbElement,
      values: array,
    });
  }
  publicAPI.requestData = (inData, outData) => {
    const input = inData[0];
    const output = vtk(input);
    output
      .getPointData()
      .setScalars(
        createColorDataArray(input.getNumberOfPoints())
      );
    outData[0] = output;
  };
}

// ----------------------------------------------------------------------------

const defaultValues = (initialValues) => ({
  opacity: 255,
  color: [255,255,255],
  ...initialValues
});

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, defaultValues(initialValues));

  macro.setGet(publicAPI, model, ['opacity']);
  macro.setGetArray(publicAPI, model, ['color'], 3);
  macro.obj(publicAPI, model);
  macro.algo(publicAPI, model, 1, 1);

  // Object specific methods
  vtkScalarsFilter(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'vtkScalarsFilter');

// ----------------------------------------------------------------------------

export default { newInstance, extend };
