import { defineStore } from 'pinia';

interface State {
  selectedModuleIndex: number;
}

export const useModuleStore = defineStore('modules', {
  state: (): State => ({
    selectedModuleIndex: 0,
  }),
});
