import Vue from 'vue';

export function getDemoFilePaths() {
  return ['demo_data/demo.dcm'];
}

export async function getDemoFiles() {
  const token = await Vue.prototype.$auth.getTokenSilently({ audience: process.env.VUE_APP_API_URL });

  const files = await Promise.all(
    getDemoFilePaths().map((path) => {
      return fetch(`${process.env.VUE_APP_API_URL}/api/files/load?path=${path}`, {
        headers: {
          Authorization: `Bearer ${token}` 
        }
      }).then((response) => {
        return response.arrayBuffer();
      }).then((arrayBuffer) => {
        // @ts-ignore valid path
        return new File([arrayBuffer], path.split('/').at(-1));
      });
  }));

  return files;
}

export function isDemo() {
  return Vue.prototype.$auth.user.demo;
}
