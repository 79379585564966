import macro from '@kitware/vtk.js/macros';
import vtkAppendPolyData from '@kitware/vtk.js/Filters/General/AppendPolyData';
import vtkBoundingBox, {
  getMinPoint,
} from '@kitware/vtk.js/Common/DataModel/BoundingBox';
import vtkCutter from '@kitware/vtk.js/Filters/Core/Cutter';
import vtkPlane from '@kitware/vtk.js/Common/DataModel/Plane';


import * as vtkMath from '@kitware/vtk.js/Common/Core/Math';

// ----------------------------------------------------------------------------
// vtkMultiCutter methods
// ----------------------------------------------------------------------------

function vtkMultiCutter(publicAPI, model) {
  // Set our classname
  model.classHierarchy.push('vtkMultiCutter');

  publicAPI.requestData = (inData, outData) => {
    const polydata = inData[0];

    const normalizedNormal = [...model.normal];
    const norm = vtkMath.normalize(normalizedNormal);
    if (norm === 0.0) {
      macro.vtkErrorMacro('normal cannot have a norm of 0');
      return;
    }

    const bounds = polydata.getBounds();
    const lengths = vtkBoundingBox.getLengths(bounds);
    const length = vtkMath.dot(lengths, normalizedNormal);
    const increment = length / (model.nbSlices);
    const plane = vtkPlane.newInstance();

    plane.setNormal(normalizedNormal);
    plane.setOrigin(getMinPoint(bounds));

    const append = vtkAppendPolyData.newInstance();
    const cutter = vtkCutter.newInstance();
    cutter.setCutFunction(plane);
    cutter.setInputData(polydata);

    for(let i = 1; i <= model.nbSlices; i++) {
      const newOrigin = vtkMath.multiplyAccumulate(
        plane.getOrigin(),
        plane.getNormal(),
        increment,
        []
      );

      plane.setOrigin(newOrigin);
      cutter.update();
      append.addInputData(cutter.getOutputData());
      append.update();
    }

    outData[0] = append.getOutputData();
  };
}

// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
  normal: [1, 0, 0],
  nbSlices: 50,
};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  // Build VTK API
  macro.setGet(publicAPI, model, ['nbSlices']);
  macro.setGetArray(publicAPI, model, ['normal'], 3);

  // Make this a VTK object
  macro.obj(publicAPI, model);

  // Also make it an algorithm with one input and one output
  macro.algo(publicAPI, model, 1, 1);

  // Object specific methods
  vtkMultiCutter(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'vtkMultiCutter');

// ----------------------------------------------------------------------------

export default { newInstance, extend };
