
import { defineStore } from 'pinia';

interface State {
  isLoading: boolean;
}

export const useView2DStore = defineStore('view2D', {
  state: (): State => ({
    isLoading: false
  }),
});
