export interface ToothState {
  color: string;
  hoverColor?: string;
  activeColor?: string;
  activeHoverColor?: string;
}

// prettier-ignore
export const teethIds = [
  11, 12, 13, 14, 15, 16, 17, 18,
  21, 22, 23, 24, 25, 26, 27, 28,
  31, 32, 33, 34, 35, 36, 37, 38,
  41, 42, 43, 44, 45, 46, 47, 48
]

/**
 * Check if a tooth is maxillary or mandibular.
 * Upper teeth are maxillary.
 * @param toothId: number
 * @returns 
 */
export function isMaxillary(toothId: number): boolean {
  return (toothId >= 11 && toothId <= 18) || (toothId >= 21 && toothId <= 28);
}

/**
 * Check if a tooth is anterior.
 * @param toothId: number
 * @returns 
 */
export function isAnterior(toothId: number): boolean {
  return (
    toothId === 11 || toothId === 12 || toothId === 13 ||
    toothId === 21 || toothId === 22 || toothId === 23 ||
    toothId === 31 || toothId === 32 || toothId === 33 ||
    toothId === 41 || toothId === 42 || toothId === 43
  );
}

/**
 * Check if a tooth is in one of the two left (from patient point of view)
 * quadrants (2 or 3).
 * @param toothId: number
 * @returns 
 */
export function isLeftQuadrant(toothId: number): boolean {
  return (toothId >= 21 && toothId <= 28) || (toothId >= 31 && toothId <= 38);
}

// Hold the 3 different states (colors) of a tooth (default, rod and implant)
export const teethStates = {
  default: {
    color: 'white',
    hoverColor: '#BDBDBD',
    activeColor: '#616161',
    activeHoverColor: '#424242',
  },
  rod: {
    color: '#81D4FA',
    hoverColor: '#29B6F6',
    activeColor: '#0288D1',
    activeHoverColor: '#0277BD',
  },
  implant: {
    color: '#A5D6A7',
    hoverColor: '#66BB6A',
    activeColor: '#388E3C',
    activeHoverColor: '#2E7D32',
  },
} as Record<string, ToothState>;

/**
 * List of tooth "paths" for the ToothSelector component.
 * Each tooth is composed by an unique id, a svg path (d), and a x/y position for its label.
 */
export const getTeeth = () => [
  {
    id: 18,
    d: 'm54.17779,503.28931c0,0 -3.30702,-20.75137 0.32923,-25.68627c3.63625,-4.9349 2.96264,-9.34723 8.14525,-11.90534c5.1826,-2.55811 8.8732,-3.88083 14.97088,-6.53562c6.09768,-2.65478 41.33345,2.46144 48.39164,4.9349c7.05819,2.47347 16.28157,-1.55839 19.39836,17.66176c3.11678,19.22015 -3.89598,31.68728 -4.11906,31.47127c0.22308,0.21601 -3.6729,9.82609 -13.54271,13.72207c-9.86981,3.89598 -34.54433,1.03893 -37.66111,1.03893c-3.11678,0 -24.88882,-3.04728 -29.60943,-8.31142c-4.7206,-5.26413 -5.37284,-8.4834 -6.30306,-16.39028z',
    x: '83.67411',
    y: '500.94494',
  },
  {
    id: 17,
    d: 'm61.96489,418.50091c0.07541,-6.29376 2.38814,-15.99297 3.13061,-18.78791c0.74247,-2.79494 1.01059,-15.0713 31.94045,-16.00152c30.92986,-0.93022 64.46263,24.32443 59.81152,34.32431c-4.65111,9.99988 -2.32555,1.86044 -4.41855,14.88354c-2.093,13.0231 -1.16278,7.44177 -6.04644,16.04632c-4.88366,8.60455 -8.13944,8.8371 -11.62777,9.53477c-3.48833,0.69767 -22.09276,3.02322 -32.7903,-2.79066c-10.69755,-5.81388 -27.44153,-3.95344 -36.27863,-14.18588c-8.8371,-10.23244 -3.79629,-16.72922 -3.72089,-23.02298z',
    x: '91.55935',
    y: '427.82725',
  },
  {
    id: 16,
    d: 'm85.33852,311.50499l9.91049,-4.12244c0.11572,0.16472 10.93348,-5.37608 18.32121,-3.00145c7.38774,2.37463 18.99703,9.76236 22.6909,10.55391c3.69387,0.79154 16.09471,3.95772 21.37166,8.44313c5.27695,4.48541 13.45623,11.0816 11.34545,20.31627c-2.11078,9.23467 0.26385,24.01014 -5.80465,28.49555c-6.0685,4.48541 2.11078,10.55391 -21.63551,14.77547c-23.74629,4.22156 -61.21266,-13.98393 -67.28116,-23.2186c-6.0685,-9.23467 2.11078,-17.1501 3.16617,-18.46934c1.05539,-1.31924 0,-7.38774 -0.5277,-11.6093c-0.5277,-4.22156 -0.47307,-11.29083 1.90156,-15.51239l6.54158,-6.65081z',
    y: '359.36902',
    x: '105.89615',
  },
  {
    id: 15,
    d: 'm93.78165,265.59547c1.05539,-0.26385 12.40084,-12.13699 21.63551,-13.72008c9.23467,-1.58309 15.03932,-1.58309 23.74629,4.22156c8.70697,5.80465 17.94164,14.77547 19.26088,17.41395c1.31924,2.63848 11.87315,13.19238 6.0685,19.52473c-5.80465,6.33234 -10.02621,8.70697 -22.6909,8.97082c-12.66469,0.26385 -18.20549,2.90232 -41.16024,-5.80465c-22.95475,-8.70697 -7.91543,-30.34248 -6.86004,-30.60633z',
    y: '286.96817',
    x: '108.76351',
  },
  {
    id: 14,
    d: 'm106.71018,215.72827l10.17434,-11.77401l15.56701,-8.17928c0.11572,0.16471 6.18422,-3.00146 11.46117,0.42856c5.27695,3.43002 21.10781,7.38774 33.7725,22.6909c12.66469,15.30317 7.38774,13.72008 4.74926,18.20549c-2.63848,4.48541 -31.39787,13.45623 -38.78561,12.13699c-7.38774,-1.31924 -16.88625,-2.37463 -29.02324,-13.98393c-12.13699,-11.6093 -7.65158,-18.46934 -7.91543,-19.52473z',
    y: '232.48833',
    x: '124.17557',
  },
  {
    id: 13,
    d: 'm174.51904,202.27203c11.6093,0.79154 31.39787,-4.48541 29.02324,-12.92854c-2.37463,-8.44313 4.74926,-2.63848 -11.34545,-20.58012c-16.09471,-17.94164 -15.56701,-18.46934 -24.27399,-23.48244c-8.70697,-5.01311 -22.16321,-6.59619 -28.2317,-2.63848c-6.0685,3.95772 -16.35856,6.59619 -14.51162,22.42705c1.84693,15.83086 1.84693,18.73319 7.65158,23.74629c5.80465,5.01311 30.07864,12.66469 41.68793,13.45623z',
    y: '181.23426',
    x: '145.32236',
  },
  {
    id: 12,
    d: 'm171.88056,137.36551l-0.37957,-6.23321c0.11572,0.16471 4.60113,-10.3892 7.50345,-10.91689c2.90232,-0.5277 3.43002,-1.58309 3.3143,-1.7478c0.11572,0.16471 8.295,-9.8615 11.72502,-10.12535c3.43002,-0.26385 16.09471,-8.70697 23.74629,7.38774c7.65158,16.09471 12.40084,34.03635 12.13699,37.46637c-0.26385,3.43002 4.22156,15.30317 -4.48541,16.88625c-8.70697,1.58309 -21.10781,-2.37463 -25.59322,-5.80465c-4.48541,-3.43002 -17.41395,-12.13699 -18.99703,-13.45623c-1.58309,-1.31924 -7.91543,-6.33234 -8.97082,-13.45623z',
    y: '145.39226',
    x: '186.18224',
  },
  {
    id: 11,
    d: 'm218.84545,97.78835c-0.26385,6.33234 -0.26385,5.01311 0.26385,8.17928c17.94164,29.02324 28.2317,45.3818 33.50866,48.81182c5.27695,3.43002 9.49852,8.17928 20.31627,6.0685c10.81775,-2.11078 11.87315,-13.19238 15.56701,-17.94164c3.69387,-4.74926 9.76236,-25.85707 8.17928,-36.93868c-1.58309,-11.0816 -1.31924,-21.63551 -5.01311,-23.74629c-3.69387,-2.11078 -36.93868,2.11078 -37.05439,1.94607c0.11572,0.16471 -26.26905,5.96936 -26.38477,5.80465c0.11572,0.16471 -9.11895,1.48395 -9.3828,7.81629z',
    y: '124.51428',
    x: '248.81617',
  },
  {
    id: 28,
    d: 'm477.68003,466.6474l2.25891,10.38921c0.90727,4.91396 0.11572,12.82939 0.37957,14.14862c0.26385,1.31924 -3.69387,7.65158 7.12389,15.30317c10.81775,7.65158 16.09471,7.38774 23.74629,6.86004c7.65158,-0.5277 34.03635,-5.27695 38.52176,-6.0685c4.48541,-0.79154 22.95475,-11.0816 21.37166,-24.27399c-1.58309,-13.19238 -4.74926,-14.24777 -6.33234,-22.95475c-1.58309,-8.70697 1.84693,-14.24777 -21.10781,-17.94164c-22.95475,-3.69387 -36.14713,3.69387 -36.67483,4.48541c-0.5277,0.79154 -30.60633,7.91545 -29.28709,20.05242z',
    y: '486.24973',
    x: '508.04343',
  },
  {
    id: 27,
    d: 'm481.63774,385.91003c0.5277,0 16.88625,-8.97082 16.77053,-9.13554c0.11572,0.16472 23.86201,-8.2784 23.74629,-8.44313c0.11572,0.16472 26.23664,-5.11223 34.94362,12.30172c8.70697,17.41395 5.01311,14.77547 6.0685,23.74629c1.05539,8.97082 3.43002,13.45623 -2.37463,18.73319c-5.80465,5.27695 -7.35533,8.37753 -12.51656,11.18073c-5.16123,2.8032 -5.68893,5.17783 -17.82592,4.91398c-12.13699,-0.26385 -42.47948,8.17928 -50.39491,-4.74926c-7.91543,-12.92854 -6.33234,-18.20549 -9.35039,-30.77105c-3.01805,-12.56556 10.40578,-17.77692 10.93348,-17.77692z',
    y: '416.3578',
    x: '504.45924',
  },
  {
    id: 26,
    d: 'm458.41915,315.19883c6.86004,-4.4854 12.92854,-8.44313 12.92854,-8.44313c0,0 16.88625,-11.34545 18.20549,-11.0816c1.31924,0.26385 16.35856,-6.86004 23.74629,-2.11078c7.38774,4.74926 21.89936,-0.26385 24.01014,22.6909c2.11078,22.95475 8.17928,23.74629 2.11078,31.66172c-6.0685,7.91543 -1.05539,9.76236 -17.67779,18.20549c-16.6224,8.44313 -39.04946,6.33234 -39.16518,6.16764c0.11572,0.1647 -16.24283,2.80318 -24.68596,-6.95919c-8.44313,-9.76236 -7.65158,-26.38477 -7.76731,-26.54947c0.11572,0.1647 -4.89738,-14.87461 8.295,-23.58159z',
    y: '340.55193',
    x: '481.96842',
  },
  {
    id: 25,
    d: 'm452.0868,261.90161l9.38279,-9.13553c0.11572,0.16471 10.93348,-14.61076 29.66666,-11.97228c18.73319,2.63848 26.12092,17.41395 26.12092,17.41395c0,0 4.48541,15.30317 -2.11078,20.31627c-6.59619,5.01311 -11.0816,13.45623 -28.2317,13.72008c-17.1501,0.26385 -11.87315,2.11078 -30.60633,-2.37463c-18.73319,-4.48541 -14.77547,-17.41394 -4.22156,-27.96785z',
    y: '276.93241',
    x: '468.25883',
  },
  {
    id: 24,
    d: 'm429.3959,212.5621l11.22973,-11.77401c0.11572,0.16471 11.46117,-9.8615 19.3766,-10.3892c7.91543,-0.5277 15.03932,-6.86004 31.39787,5.5408c16.35856,12.40084 8.44313,31.13403 7.12389,31.66172c-1.31924,0.5277 -3.95772,12.13699 -26.12092,10.29006c-22.16321,-1.84693 -27.17631,0.5277 -39.04946,-4.22156c-11.87315,-4.74926 -7.38775,-12.137 -3.95772,-21.10781z',
    y: '222.45257',
    x: '450.69623',
  },
  {
    id: 23,
    d: 'm406.96884,167.97184c0.79154,-2.11078 11.6093,-12.13699 11.6093,-12.40084c0,-0.26385 12.92854,-13.72008 12.81282,-13.88479c0.11571,0.16471 11.46116,-15.13845 32.83283,-5.63994c21.37166,9.49852 19.52473,34.3002 13.19238,39.04946c-6.33234,4.74926 -25.06553,13.98393 -32.45326,15.30317c-7.38774,1.31924 -16.35856,3.16617 -25.32938,1.31924c-8.97082,-1.84693 -12.66469,-6.33234 -13.98393,-11.34545c-1.31924,-5.01311 0.5277,-10.29006 1.31924,-12.40084z',
    y: '170.84008',
    x: '430.98313',
  },
  {
    id: 22,
    d: 'm372.14095,138.15705l7.00817,-21.80022c0.11571,0.16471 4.86497,-11.70844 8.03114,-11.97228c3.16617,-0.26385 5.27695,-6.86004 15.83086,0.26385c10.55391,7.12389 20.31627,5.80465 24.01014,18.20549c3.69387,12.40084 -19.26088,29.81479 -24.27399,34.56405c-5.01311,4.74926 -18.99703,13.45623 -28.49555,9.23467c-9.49852,-4.22156 -3.95771,-24.01014 -2.11078,-28.49555z',
    y: '142.88331',
    x: '381.87958',
  },
  {
    id: 21,
    d: 'm304.85979,87.76214l-0.90726,18.83232c0.11572,0.16471 0.37957,18.10635 3.28189,23.911c2.90232,5.80465 4.22156,17.41395 8.70697,21.63551c4.48541,4.22156 17.41395,9.76236 20.84397,6.0685c3.43002,-3.69387 9.76236,-2.63848 26.91246,-22.95475c17.1501,-20.31627 14.51162,-38.52176 14.39591,-38.68647c0.11572,0.16471 -0.67583,-10.65304 -25.47751,-14.87461c-24.80168,-4.22156 -32.18942,-3.69387 -36.93868,-2.37463c-4.74926,1.31924 -7.91543,1.84693 -10.81775,8.44313z',
    y: '123.70786',
    x: '323.45715',
  },
  {
    id: 48,
    d: 'm61.06453,584.05964c-1.31924,8.70697 -2.63848,17.41395 -2.63848,17.67779c0,0.26385 -5.5408,12.13699 8.17928,22.95475c13.72008,10.81775 24.80168,9.76236 29.02324,11.0816c4.22156,1.31924 35.61944,1.31924 38.78561,-0.5277c3.16617,-1.84693 13.72008,-1.84693 15.30317,-27.44016c1.58309,-25.59322 5.5408,-17.67779 -0.26385,-28.7594c-5.80465,-11.0816 -46.70104,-29.02324 -59.10188,-27.44016c-12.40084,1.58309 -9.23467,-0.26385 -15.83086,6.86004c-6.59619,7.12389 -12.13699,16.88625 -13.45623,25.59322z',
    y: '605.24517',
    x: '88.69199',
  },
  {
    id: 47,
    d: 'm69.24381,658.20082l0.67582,13.02768c0.11572,0.1647 1.6988,18.63404 8.295,31.82642c6.59619,13.19238 4.22156,14.24777 18.46934,19.26088c14.24777,5.01311 27.44016,-5.27695 35.61944,-8.97082c8.17928,-3.69387 21.63551,-5.5408 23.74629,-8.70697c2.11078,-3.16617 5.27695,-7.12389 -0.5277,-29.81479c-5.80465,-22.6909 -6.33234,-25.32938 -12.66469,-31.13403c-6.33234,-5.80465 -26.64862,-4.48541 -32.18942,-4.48541c-5.5408,0 -22.95475,3.16617 -30.34248,5.80465c-7.38774,2.63848 -8.97082,9.49852 -11.0816,13.19238z',
    y: '689.56352',
    x: '97.83172',
  },
  {
    id: 46,
    d: 'm165.54821,739.20205c0.79154,2.63848 7.12389,12.66469 7.65158,17.94164c0.5277,5.27695 0.82396,8.11375 3.842,13.29154c3.01804,5.17779 4.07343,13.09322 -5.16124,20.74481c-9.23467,7.65158 -21.63551,12.66469 -25.85707,14.51162c-4.22156,1.84693 -24.53783,8.17928 -30.87018,6.33234c-6.33234,-1.84693 -13.15997,-15.36869 -14.09964,-18.63403c-0.93967,-3.26533 -4.10585,-9.59768 -8.8551,-17.77695c-4.74926,-8.17928 -3.69387,-13.72008 -1.58309,-20.58012c2.11078,-6.86004 -0.26385,-12.40084 0.26385,-15.30317c0.5277,-2.90232 4.48541,-15.03932 30.07864,-15.30317c25.59322,-0.26385 31.13403,1.84693 34.03635,3.16617c2.90232,1.31924 9.76236,8.97082 10.55391,11.6093z',
    y: '774.59866',
    x: '116.82798',
  },
  {
    id: 45,
    d: 'm129.40108,820.99482c0,0 11.6093,-7.38774 11.87315,-7.38774c0.26385,0 10.55391,-4.22156 24.01014,-0.79154c13.45623,3.43002 10.55391,1.31924 27.44016,10.29006c16.88625,8.97082 -7.65158,29.02324 -10.14193,30.44166c-10.17434,9.1355 -10.70204,14.41245 -31.28216,11.24628c-20.58012,-3.16617 -26.91246,-16.09471 -27.02818,-16.25938c0.11572,0.16468 -9.11895,-13.02771 5.12882,-27.53933z',
    y: '845.74502',
    x: '141.73816',
  },
  {
    id: 44,
    d: 'm143.12116,884.58212c4.48541,-6.06851 13.19238,-12.66469 13.72008,-12.66469c0.5277,0 14.77547,-5.80465 15.30317,-5.27695c0.5277,0.5277 10.81775,-2.63848 20.84397,0.26385c10.02621,2.90232 17.94164,10.81775 18.99703,13.72008c1.05539,2.90232 4.22156,6.86004 -0.5277,15.83086c-4.74926,8.97082 -9.49852,18.73319 -25.06553,23.2186c-15.56701,4.48541 -34.56405,-3.69387 -37.73022,-8.44313c-3.16617,-4.74926 -10.02621,-20.58011 -5.5408,-26.64862z',
    y: '902.73382',
    x: '161.80968',
  },
  {
    id: 43,
    d: 'm173.46364,936.03244c6.59619,-6.86001 16.35856,-14.51162 16.35856,-14.51162c0,0 19.26088,-10.55391 21.63551,-9.49852c2.37463,1.05539 24.53783,-6.0685 23.2186,11.34545c-1.31924,17.41395 -6.0685,23.2186 -11.0816,32.45326c-5.01311,9.23467 -3.69387,19.26088 -21.89936,17.94164c-18.20549,-1.31924 -28.7594,-18.99703 -28.87511,-19.16174c0.11572,0.16471 -5.95278,-11.70846 0.64341,-18.56847z',
    y: '953.3606',
    x: '185.2862',
  },
  {
    id: 42,
    d: 'm225.44164,962.68106l7.27201,-12.3017c0.11572,0.16471 5.12883,-5.90379 11.46117,-10.3892c6.33234,-4.48541 15.03932,-11.87315 18.20549,-11.87315c3.16617,0 13.72008,-3.69387 11.6093,30.34248c-2.11078,34.03635 -2.63848,28.2317 -8.17928,32.71711c-5.5408,4.48541 -14.24777,5.27695 -24.53783,-1.58309c-10.29006,-6.86004 -26.12092,-3.43002 -15.83086,-26.91246z',
    y: '975.85145',
    x: '235.10659',
  },
  {
    id: 41,
    d: 'm276.62809,973.23497l4.63354,-13.3571l8.44313,-12.40084c0.11572,0.16471 16.21043,-21.4708 23.59816,5.70551c7.38774,27.17631 4.74926,34.82789 4.22156,38.52176c-0.5277,3.69387 -1.84693,11.0816 -15.83086,10.29006c-13.98393,-0.79154 -24.01014,-1.31924 -25.59322,-7.12389c-1.58309,-5.80465 -3.69387,-11.0816 0.5277,-21.63551z',
    y: '988.75464',
    x: '281.88039',
  },
  {
    id: 38,
    d: 'm474.25,597.51587l1.73122,11.70842c0.11572,0.16472 -0.93967,17.57867 23.86201,14.14865c24.80168,-3.43002 31.13403,-1.05539 31.01831,-1.22011c8.55884,-4.32069 12.51656,-2.7376 17.79351,-8.54225c5.27695,-5.80465 17.94164,-4.74926 14.51162,-26.38477c-3.43002,-21.63551 0.79154,-18.20549 -1.84693,-27.17631c-2.63848,-8.97082 -0.79154,-24.53783 -30.34248,-20.31627c-29.55094,4.22156 -35.35559,11.34545 -35.47131,11.18073c-1.20352,1.74781 -29.17138,6.49704 -21.25595,46.60191z',
    y: '591.62521',
    x: '501.50227',
  },
  {
    id: 37,
    d: 'm468.18151,672.97629l-0.90726,14.08308c0.11572,0.1647 -3.3143,7.81628 13.57195,13.35708c16.88625,5.5408 27.17631,8.44313 34.82789,8.44313c7.65158,0 12.92854,3.16617 20.58012,-2.63848c7.65158,-5.80465 10.02621,-9.49852 11.0816,-16.09471c1.05539,-6.59619 2.11078,-17.1501 3.16617,-27.44016c-0.26385,-6.33234 14.24777,-32.98096 -22.95475,-34.3002c-37.20252,-1.31924 -43.53487,3.95772 -48.54797,6.33234c-5.01311,2.37463 -8.70697,16.0947 -10.81775,38.25791z',
    y: '677.10838',
    x: '494.96112',
  },
  {
    id: 36,
    d: 'm459.47453,743.42361l-2.75419,15.93002c0.11572,0.16469 -7.53587,13.09323 1.17111,21.00866c8.70697,7.91543 19.52473,10.02621 25.59322,13.72008c6.0685,3.69387 12.13699,8.44313 22.95475,6.86004c10.81775,-1.58309 21.37166,-5.01311 21.10781,-13.45623c-0.26385,-8.44313 6.86004,-13.72008 10.55391,-20.58012c3.69387,-6.86004 7.91543,-19.26088 0.79154,-36.93868c-7.12389,-17.67779 -21.10781,-15.83086 -29.40281,-15.20401c-9.11895,-0.36301 -20.4644,-4.58457 -27.58829,-1.4184c-7.12389,3.16617 -19.26088,5.80469 -22.42705,30.07864z',
    y: '766.35499',
    x: '482.77484',
  },
  {
    id: 35,
    d: 'm442.06059,837.08957c0.26385,0.26385 9.23467,9.49852 9.11895,9.33379c0.11572,0.16472 13.04425,10.19094 20.43199,9.66324c7.38774,-0.5277 15.83086,5.80465 27.17631,-2.37463c11.34545,-8.17928 15.56701,-19.78858 14.77547,-27.17631c-0.79154,-7.38774 -10.81775,-22.95475 -22.42705,-22.6909c-11.6093,0.26385 -12.92854,-5.5408 -36.67483,2.63848c-23.74629,8.17928 -12.66469,30.34248 -12.40084,30.60633z',
    y: '838.57662',
    x: '461.53845',
  },
  {
    id: 34,
    d: 'm423.8551,880.0967l6.48047,14.0831c0.11572,0.16468 5.92037,7.28856 8.55884,9.1355c2.63848,1.84693 11.6093,9.76236 18.99703,10.81775c7.38774,1.05539 21.10781,-4.74926 25.59322,-9.23467c4.48541,-4.48541 27.44016,-25.06553 -5.01311,-41.42409c-32.45326,-16.35856 -57.25495,4.7493 -54.61647,16.6224z',
    y: '894.57976',
    x: '446.03682',
  },
  {
    id: 33,
    d: 'm424.38279,960.30643c-8.70697,-10.81775 -13.45623,-20.31627 -16.35856,-25.06553c-2.90232,-4.74926 -5.5408,-11.87315 -2.37463,-22.16321c3.16617,-10.29006 18.46934,-10.29006 31.66172,-3.43002c13.19238,6.86004 20.05242,8.17928 30.87018,21.63551c10.81775,13.45623 -18.43692,31.06845 -21.22353,33.87164c-2.78661,2.80319 -13.86821,5.96936 -22.57518,-4.84839z',
    y: '945.56497',
    x: '422.38105',
  },
  {
    id: 32,
    d: 'm367.39169,958.72334l-0.37957,-14.94018c0.11572,0.16471 -1.73122,-7.48687 3.80959,-15.13845c5.5408,-7.65158 24.01014,2.63848 29.81479,9.76236c5.80465,7.12389 19.78858,22.16321 20.05242,26.64862c0.26385,4.48541 3.43002,8.70697 -15.30317,18.20549c-18.73319,9.49852 -27.44016,7.38774 -30.87018,1.84693c-3.43002,-5.5408 -5.5408,-11.34545 -7.12389,-26.38477z',
    y: '970.74401',
    x: '376.5929',
  },
  {
    id: 31,
    d: 'm322.27374,971.65188l1.99506,-10.98247c0.11572,0.16471 1.17111,-7.48687 2.49035,-10.65304c1.31924,-3.16617 13.45623,-27.17631 28.2317,1.84693c14.77547,29.02324 13.98393,32.45326 12.92854,36.41098c-1.05539,3.95772 -1.58309,5.80465 -15.03932,10.81775c-13.45623,5.01311 -27.96785,1.58309 -29.55094,-2.90232c-1.58309,-4.48541 -3.69387,-13.19238 -1.05539,-24.53783z',
    y: '987.14173',
    x: '329.46068',
  },
];

/**
 * List of tooth "paths" for the ToothSelectorReport component.
 * Each tooth is composed by an unique id, a svg path (d), and a x/y position for its label.
 */
export const getTeethReport = () => [
  {
    id: 18,
    d: 'm1.37689,431.26746c0,0 -3.30702,-20.75137 0.32923,-25.68627c3.63625,-4.9349 2.96264,-9.34723 8.14525,-11.90534c5.1826,-2.55811 8.8732,-3.88083 14.97088,-6.53562c6.09768,-2.65478 41.33345,2.46144 48.39164,4.9349c7.05819,2.47347 16.28157,-1.55839 19.39836,17.66176c3.11678,19.22015 -3.89598,31.68728 -4.11906,31.47127c0.22308,0.21601 -3.6729,9.82609 -13.54271,13.72207c-9.86981,3.89598 -34.54433,1.03893 -37.66111,1.03893c-3.11678,0 -24.88882,-3.04728 -29.60943,-8.31142c-4.7206,-5.26413 -5.37284,-8.4834 -6.30306,-16.39028l0.00001,0z',
    x: '102.15533',
    y: '433.53848',
    index: {
      ellipse: {
        x: "45.52117",
        y: "422.50018",
        r: "12.5"
      },
      lines: [
        {
          transform: 'rotate(10.2746 45.4153 422.279)',
          y2: "455.61233",
          x2: "45.44061",
          y1: "388.94524",
          x1: "45.38996",
        },
        {
          transform: 'rotate(101.449 45.4857 422.305)',
          y2: "465.9158",
          x2: "45.89326",
          y1: "378.69368",
          x1: "45.07806",
        }
      ],
      orientation: {
        palatal: {
          transform: "rotate(11.5929 59.955 424.95)",
          d: "m45.75989,418.32594l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        vestibular: {
          transform: "rotate(-170.454 30.0906 418.884)",
          d: "m15.89548,412.25973l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        mesial: {
          transform: "rotate(-80.4776 47.356 407.218)",
          d: "m33.16084,400.59395l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        distal: {
          transform: "rotate(100.451 42.6897 437.083)",
          d: "m28.49452,430.45836l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
      }
    },
  },
  {
    id: 17,
    d: 'm9.16399,346.47906c0.07541,-6.29376 2.38814,-15.99297 3.13061,-18.78791c0.74247,-2.79494 1.01059,-15.0713 31.94045,-16.00152c30.92986,-0.93022 64.46263,24.32443 59.81152,34.32431c-4.65111,9.99988 -2.32555,1.86044 -4.41855,14.88354c-2.093,13.0231 -1.16278,7.44177 -6.04644,16.04632c-4.88366,8.60455 -8.13944,8.8371 -11.62777,9.53477c-3.48833,0.69767 -22.09276,3.02322 -32.7903,-2.79066c-10.69755,-5.81388 -27.44153,-3.95344 -36.27863,-14.18588c-8.8371,-10.23244 -3.79629,-16.72922 -3.72089,-23.02298l0,0.00001z',
    x: '108.50211',
    y: '370.16439',
    index: {
      ellipse: {
        x: "55.8313",
        y: "349.1837",
        r: "12.5"
      },
      lines: [
        {
          transform: 'rotate(10.2746 55.7254 348.963)',
          y2: "382.29585",
          x2: "55.75074",
          y1: "315.62876",
          x1: "55.70009"
        },
        {
          transform: 'rotate(101.449 55.7958 348.989)',
          y2: "392.59933",
          x2: "56.20339",
          y1: "305.3772",
          x1: "55.38819",
        }
      ],
      orientation: {
        palatal: {
          transform: "rotate(11.5929 70.221 352.156)",
          d: "m56.02578,345.53145l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        vestibular: {
          transform: "rotate(-170.454 40.3565 346.09)",
          d: "m26.16137,339.46524l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        mesial: {
          transform: "rotate(-80.4776 57.6219 334.423)",
          d: "m43.42673,327.79946l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        distal: {
          transform: "rotate(100.451 52.9556 364.289)",
          d: "m38.76041,357.66386l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
      }
    },
  },
  {
    id: 16,
    d: 'm32.53762,239.48314l9.91049,-4.12244c0.11572,0.16472 10.93348,-5.37608 18.32121,-3.00145c7.38774,2.37463 18.99703,9.76236 22.6909,10.55391c3.69387,0.79154 16.09471,3.95772 21.37166,8.44313c5.27695,4.48541 13.45623,11.0816 11.34545,20.31627c-2.11078,9.23467 0.26385,24.01014 -5.80465,28.49555c-6.0685,4.48541 2.11078,10.55391 -21.63551,14.77547c-23.74629,4.22156 -61.21266,-13.98393 -67.28116,-23.2186c-6.0685,-9.23467 2.11078,-17.1501 3.16617,-18.46934c1.05539,-1.31924 0,-7.38774 -0.5277,-11.6093c-0.5277,-4.22156 -0.47307,-11.29083 1.90156,-15.51239l6.54158,-6.65081z',
    x: '120.78762',
    y: '291.96256',
    index: {
      ellipse: {
        x: "70.42294",
        y: "274.31069",
        r: "12.5"
      },
      lines: [
        {
          transform: 'rotate(14.9382 69.9072 276.143)',
          y2: "311.13867",
          x2: "69.93255",
          y1: "241.14831",
          x1: "69.8819",
        },
        {
          transform: 'rotate(106.113 69.9219 274.431)',
          y2: "320.11365",
          x2: "70.32953",
          y1: "228.74902",
          x1: "69.51432",
        }
      ],
      orientation: {
        palatal: {
          transform: "rotate(16.7556 84.8439 278.821)",
          d: "m70.64875,272.19659l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        vestibular: {
          transform: "rotate(-165.291 55.6466 270.092)",
          d: "m41.45159,263.46702l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        mesial: {
          transform: "rotate(-75.3149 73.892 260.026)",
          d: "m59.69597,253.40191l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        distal: {
          transform: "rotate(105.614 66.5565 289.352)",
          d: "m52.36057,282.72677l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
      }
    },
  },
  {
    id: 15,
    d: 'm40.98075,193.57362c1.05539,-0.26385 12.40084,-12.13699 21.63551,-13.72008c9.23467,-1.58309 15.03932,-1.58309 23.74629,4.22156c8.70697,5.80465 17.94164,14.77547 19.26088,17.41395c1.31924,2.63848 11.87315,13.19238 6.0685,19.52473c-5.80465,6.33234 -10.02621,8.70697 -22.6909,8.97082c-12.66469,0.26385 -18.20549,2.90232 -41.16024,-5.80465c-22.95475,-8.70697 -7.91543,-30.34248 -6.86004,-30.60633z',
    x: '118.52677',
    y: '227.76684',
    index: {
      ellipse: {
        x: "75.05165",
        y: "207.334",
        r: "9.35943"
      },
      lines: [
        {
          transform: 'rotate(17.2506 75.9013 205.18)',
          y2: "229.33797",
          x2: "76.20649",
          y1: "181.02162",
          x1: "75.5961",
        },
        {
          transform: 'rotate(17.2506 75.908 206.826)',
          y2: "207.07687",
          x2: "39.80832",
          y1: "206.57432",
          x1: "112.00769",
        }
      ],
      orientation: {
        distal: {
          transform: "rotate(106.118 71.8691 217.653)",
          d: "m61.25258,212.69905l11.58165,0l9.65137,4.95437l-9.65137,4.95437l-11.58165,0l0,-9.90875z"
        },
        mesial: {
          transform: "rotate(-73.7873 78.8686 195.722)",
          d: "m68.25205,190.76738l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        vestibular: {
          transform: "rotate(-164.882 63.4343 203.439)",
          d: "m52.81778,198.48451l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        palatal: {
          transform: "rotate(14.1607 87.6146 210.642)",
          d: "m76.99814,205.68717l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
      }
    },
  },
  {
    id: 14,
    d: 'm53.90928,143.70642l10.17434,-11.77401l15.56701,-8.17928c0.11572,0.16471 6.18422,-3.00146 11.46117,0.42856c5.27695,3.43002 21.10781,7.38774 33.7725,22.6909c12.66469,15.30317 7.38774,13.72008 4.74926,18.20549c-2.63848,4.48541 -31.39787,13.45623 -38.78561,12.13699c-7.38774,-1.31924 -16.88625,-2.37463 -29.02324,-13.98393c-12.13699,-11.6093 -7.65158,-18.46934 -7.91543,-19.52473l0,0.00001z',
    x: '137.52858',
    y: '177.90239',
    index: {
      ellipse: {
        x: "91.58007",
        y: "152.5818",
        r: "9.35943"
      },
      lines: [
        {
          transform: "rotate(14.813 91.8114 152.382)",
          y2: "175.48271",
          x2: "92.1166",
          y1: "129.28206",
          x1: "91.50621",
        },
        {
          transform: "rotate(14.813 94.3908 152.56)",
          y2: "152.81146",
          x2: "56.80463",
          y1: "152.30891",
          x1: "131.97694",
        }
      ],
      orientation: {
        distal: {
          transform: "rotate(104.899 88.5668 162.164)",
          d: "m77.95067,157.21041l11.58165,0l9.65137,4.95437l-9.65137,4.95438l-11.58165,0l0,-9.90875z"
        },
        mesial: {
          transform: "rotate(-75.0068 95.0976 140.09)",
          d: "m84.48132,135.13523l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z",
        },
        vestibular: {
          transform: "rotate(-166.101 79.8312 148.133)",
          d: "m69.21468,143.17866l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z" 
        },
        palatal: {
          transform: "rotate(12.9412 104.16 154.82)",
          d: "m93.54291,149.86528l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
      }
    },
  },
  {
    id: 13,
    d: 'm121.71814,130.25018c11.6093,0.79154 31.39787,-4.48541 29.02324,-12.92854c-2.37463,-8.44313 4.74926,-2.63848 -11.34545,-20.58012c-16.09471,-17.94164 -15.56701,-18.46934 -24.27399,-23.48244c-8.70697,-5.01311 -22.16321,-6.59619 -28.2317,-2.63848c-6.0685,3.95772 -16.35856,6.59619 -14.51162,22.42705c1.84693,15.83086 1.84693,18.73319 7.65158,23.74629c5.80465,5.01311 30.07864,12.66469 41.68793,13.45623l0.00001,0.00001z',
    x: '156.62408',
    y: '142.54578',
    index: {
      ellipse: {
        x: "109.38582",
        y: "101.19655",
        r: "9.99658"
      },
      lines: [
        {
          transform: "rotate(-151.263 109.529 100.863)",
          y2: "125.26047",
          x2: "109.96488",
          y1: "76.4657",
          x1: "109.09344"
        },
        {
          transform: "rotate(-151.263 111.404 102.493)",
          y2: "102.76115",
          x2: "70.6231",
          y1: "102.22438",
          x1: "152.18599",
        }
      ],
      orientation: {
        distal: {
          transform: "rotate(119.343 104.443 110.545)",
          d: "m93.82729,105.59166l11.58165,0l9.65137,4.95437l-9.65137,4.95438l-11.58165,0l0,-9.90875z"
        },
        mesial: {
          transform: "rotate(-60.5632 114.729 89.7701)",
          d: "m104.11271,84.81561l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        vestibular: {
          transform: "rotate(-151.657 97.939 94.2654)",
          d: "m87.32251,89.31105l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        palatal: {
          transform: "rotate(27.3848 120.345 106.809)",
          d: "m109.72867,101.85436l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
      }
    },
  },
  {
    id: 12,
    d: 'm119.07966,65.34366l-0.37957,-6.23321c0.11572,0.16471 4.60113,-10.3892 7.50345,-10.91689c2.90232,-0.5277 3.43002,-1.58309 3.3143,-1.7478c0.11572,0.16471 8.295,-9.8615 11.72502,-10.12535c3.43002,-0.26385 16.09471,-8.70697 23.74629,7.38774c7.65158,16.09471 12.40084,34.03635 12.13699,37.46637c-0.26385,3.43002 4.22156,15.30317 -4.48541,16.88625c-8.70697,1.58309 -21.10781,-2.37463 -25.59322,-5.80465c-4.48541,-3.43002 -17.41395,-12.13699 -18.99703,-13.45623c-1.58309,-1.31924 -7.91543,-6.33234 -8.97082,-13.45623z',
    x: '180.04805',
    y: '119.01148',
    index: {
      ellipse: {
        x: "150.57357",
        y: "67.94362",
        r: "9.99658"
      },
      lines: [
        {
          transform: "rotate(-132.267 151.48 66.9241)",
          y2: "88.35397",
          x2: "151.80606",
          y1: "45.49421",
          x1: "151.15412"
        },
        {
          transform: "rotate(-132.267 153.541 71.4557)",
          y2: "71.7241",
          x2: "122.2964",
          y1: "71.18734",
          x1: "184.7866"
        }
      ],
      orientation: {
        distal: {
          transform: "rotate(138.6 143.983 76.5139)",
          d: "m133.36842,71.56037l11.58165,0l9.65137,4.95437l-9.65137,4.95438l-11.58165,0l0,-9.90875z"
        },
        mesial: {
          transform: "rotate(-41.3058 160.544 60.2947)",
          d: "m149.92781,55.34046l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        vestibular: {
          transform: "rotate(-132.4 143.211 59.0011)",
          d: "m132.59464,54.04682l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        palatal: {
          transform: "rotate(46.6422 160.227 78.2327)",
          d: "m149.61008,73.27825l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
      }
    },
  },
  {
    id: 11,
    d: 'm166.04455,25.7665c-0.26385,6.33234 -0.26385,5.01311 0.26385,8.17928c17.94164,29.02324 28.2317,45.3818 33.50866,48.81182c5.27695,3.43002 9.49852,8.17928 20.31627,6.0685c10.81775,-2.11078 11.87315,-13.19238 15.56701,-17.94164c3.69387,-4.74926 9.76236,-25.85707 8.17928,-36.93868c-1.58309,-11.0816 -1.31924,-21.63551 -5.01311,-23.74629c-3.69387,-2.11078 -36.93868,2.11078 -37.05439,1.94607c0.11572,0.16471 -26.26905,5.96936 -26.38477,5.80465c0.11572,0.16471 -9.11895,1.48395 -9.3828,7.81629z',
    x: '210.37426',
    y: '111.97967',
    index: {
      ellipse: {
        x: "210.17031",
        y: "44.98955",
        r: "11.78474"
      },
      lines: [
        {
          transform: "rotate(-7.76304 211.083 50.4263)",
          y2: "87.48011",
          x2: "211.46751",
          y1: "13.37247",
          x1: "210.69896"
        },
        {
          transform: "rotate(-7.76304 210.16 44.7939)",
          y2: "45.11028",
          x2: "177.93771",
          y1: "44.47751",
          x1: "242.38294"
        }
      ],
      orientation: {
        mesial: {
          transform: "rotate(-7.16364 225.693 42.6604)",
          d: "m211.49778,36.03598l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        distal: {
          transform: "rotate(-189.211 195.464 46.5192)",
          d: "m181.26895,39.89405l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        vestibular: {
          transform: "rotate(-99.2341 208.061 29.9196)",
          d: "m193.86504,23.29589l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        palatal: {
          transform: "rotate(81.6945 213.246 59.7016)",
          d: "m199.04996,53.07635l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
      }
    },
  },
  {
    id: 28,
    d: 'm424.87913,394.62555l2.25891,10.38921c0.90727,4.91396 0.11572,12.82939 0.37957,14.14862c0.26385,1.31924 -3.69387,7.65158 7.12389,15.30317c10.81775,7.65158 16.09471,7.38774 23.74629,6.86004c7.65158,-0.5277 34.03635,-5.27695 38.52176,-6.0685c4.48541,-0.79154 22.95475,-11.0816 21.37166,-24.27399c-1.58309,-13.19238 -4.74926,-14.24777 -6.33234,-22.95475c-1.58309,-8.70697 1.84693,-14.24777 -21.10781,-17.94164c-22.95475,-3.69387 -36.14713,3.69387 -36.67483,4.48541c-0.5277,0.79154 -30.60633,7.91545 -29.28709,20.05242l-0.00001,0.00001z',
    x: "395.75529",
    y: '416.79198',
    index: {
      ellipse: {
        y: "405.29701",
        x: "472.30784",
        r: "12.5"
      },
      lines: [
        {
          transform: "rotate(-6.3599 472.143 405.115)",
          y2: "438.44874",
          x2: "472.16826",
          y1: "371.78165",
          x1: "472.11761"
        },
        {
          transform: "rotate(84.8145 472.217 405.119)",
          y2: "448.73053",
          x2: "472.62549",
          y1: "361.50841",
          x1: "471.81029"
        }
      ],
      orientation: {
        vestibular: {
          transform: "rotate(-4.59884 488.505 403.431)",
          d: "m474.3096,396.8065l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        palatal: {
          transform: "rotate(-186.646 458.133 405.933)",
          d: "m443.93767,399.30844l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        mesial: {
          transform: "rotate(-96.6693 471.46 389.913)",
          d: "m457.26421,383.28991l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        distal: {
          transform: "rotate(84.2593 475.308 419.897)",
          d: "m461.11207,413.27269l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
      }
    },
  },
  {
    id: 27,
    d: 'm428.83684,313.88818c0.5277,0 16.88625,-8.97082 16.77053,-9.13554c0.11572,0.16472 23.86201,-8.2784 23.74629,-8.44313c0.11572,0.16472 26.23664,-5.11223 34.94362,12.30172c8.70697,17.41395 5.01311,14.77547 6.0685,23.74629c1.05539,8.97082 3.43002,13.45623 -2.37463,18.73319c-5.80465,5.27695 -7.35533,8.37753 -12.51656,11.18073c-5.16123,2.8032 -5.68893,5.17783 -17.82592,4.91398c-12.13699,-0.26385 -42.47948,8.17928 -50.39491,-4.74926c-7.91543,-12.92854 -6.33234,-18.20549 -9.35039,-30.77105c-3.01805,-12.56556 10.40578,-17.77692 10.93348,-17.77692l-0.00001,-0.00001',
    x: '386.53007',
    y: '358.18212',
    index: {
      ellipse: {
        y: "332.18698",
        x: "468.5839",
        r: "12.5"
      },
      lines: [
        {
          transform: "rotate(-111.951 465.05 333.495)",
          y2: "378.53978",
          x2: "465.32683",
          y1: "288.45102",
          x1: "464.77275"
        },
        {
          transform: "rotate(-20.7767 469.083 334.016)",
          y2: "367.10193",
          x2: "469.49048",
          y1: "300.93021",
          x1: "468.67527"
        }
      ],
      orientation: {
        palatal: {
          transform: "rotate(-202.798 454.354 337.829)",
          d: "m440.15839,331.20421l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        vestibular: {
          transform: "rotate(-20.7506 482.831 326.977)",
          d: "m468.63586,320.35235l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        mesial: {
          transform: "rotate(-112.821 462.699 318.734)",
          d: "m448.50307,312.11042l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        distal: {
          transform: "rotate(68.1075 474.736 346.464)",
          d: "m460.54013,339.83973l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
      }
    },
  },
  {
    id: 26,
    d: 'm405.61825,243.17698c6.86004,-4.4854 12.92854,-8.44313 12.92854,-8.44313c0,0 16.88625,-11.34545 18.20549,-11.0816c1.31924,0.26385 16.35856,-6.86004 23.74629,-2.11078c7.38774,4.74926 21.89936,-0.26385 24.01014,22.6909c2.11078,22.95475 8.17928,23.74629 2.11078,31.66172c-6.0685,7.91543 -1.05539,9.76236 -17.67779,18.20549c-16.6224,8.44313 -39.04946,6.33234 -39.16518,6.16764c0.11572,0.1647 -16.24283,2.80318 -24.68596,-6.95919c-8.44313,-9.76236 -7.65158,-26.38477 -7.76731,-26.54947c0.11572,0.1647 -4.89738,-14.87461 8.295,-23.58159l0,0.00001z',
    x: '368.65464',
    y: '287.50446',
    index: {
      ellipse: {
        x: "444.99805",
        y: "262.24266",
        r: "12.5"
      },
      lines: [
        {
          transform: "rotate(-104.499 441.885 262.936)",
          y2: "306.72135",
          x2: "442.16244",
          y1: "219.15062",
          x1: "441.60836"
        },
        {
          transform: "rotate(-13.3247 444.517 261.006)",
          y2: "298.93916",
          x2: "444.92436",
          y1: "223.07195",
          x1: "444.10915"
        }
      ],
      orientation: {
        vestibular: {
          transform: "rotate(-12.0206 459.722 258.244)",
          d: "m445.52682,251.61969l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        palatal: {
          transform: "rotate(-194.067 429.928 264.648)",
          d: "m415.7333,258.02345l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        mesial: {
          transform: "rotate(-104.091 441.075 247.042)",
          d: "m426.87861,240.41825l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        distal: {
          transform: "rotate(76.8375 448.762 276.278)",
          d: "m434.56657,269.65266l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
      }
    },
  },
  {
    id: 25,
    d: 'm399.2859,189.87976l9.38279,-9.13553c0.11572,0.16471 10.93348,-14.61076 29.66666,-11.97228c18.73319,2.63848 26.12092,17.41395 26.12092,17.41395c0,0 4.48541,15.30317 -2.11078,20.31627c-6.59619,5.01311 -11.0816,13.45623 -28.2317,13.72008c-17.1501,0.26385 -11.87315,2.11078 -30.60633,-2.37463c-18.73319,-4.48541 -14.77547,-17.41394 -4.22156,-27.96785l0,-0.00001z',
    x: '360.07326',
    y: '217.73108',
    index: {
      ellipse: {
        x: "427.58194",
        y: "197.12006",
        r: "9.35943"
      },
      lines: [
        {
          transform: "rotate(-18.3244 427.593 195.446)",
          y2: "219.60455",
          x2: "427.8978",
          y1: "171.2882",
          x1: "427.28741"
        },
        {
          transform: "rotate(-18.3244 427.983 196.208)",
          y2: "196.45954",
          x2: "391.88312",
          y1: "195.95699",
          x1: "464.08248"
        }
      ],
      orientation: {
        mesial: {
          transform: "rotate(252.295 424.294 185.433)",
          d: "m413.67397,180.48801l11.58165,0l9.65137,4.95437l-9.65137,4.95438l-11.58165,0l0,-9.90875z"
        },
        distal: {
          transform: "rotate(72.3892 432.5 207.119)",
          d: "m421.88366,202.16386l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        vestibular: {
          transform:"rotate(-18.7046 440.651 191.765)",
          d: "m430.0345,186.81102l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        palatal: {
          transform: "rotate(160.337 416.201 199.616)",
          d: "m405.5853,194.6588l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
      }
    },
  },
  {
    id: 24,
    d: 'm376.595,140.54025l11.22973,-11.77401c0.11572,0.16471 11.46117,-9.8615 19.3766,-10.3892c7.91543,-0.5277 15.03932,-6.86004 31.39787,5.5408c16.35856,12.40084 8.44313,31.13403 7.12389,31.66172c-1.31924,0.5277 -3.95772,12.13699 -26.12092,10.29006c-22.16321,-1.84693 -27.17631,0.5277 -39.04946,-4.22156c-11.87315,-4.74926 -7.38775,-12.137 -3.95772,-21.10781l0.00001,0z',
    x: '346.10041',
    y: '167.35381',
    index: {
      ellipse: {
        x: "411.83599",
        y: "143.41475",
        r: "9.99658"
      },
      lines: [
        {
          transform: "rotate(-12.7892 412.561 142.288)",
          y2: "165.97506",
          x2: "412.88703",
          y1: "118.60077",
          x1: "412.23509"
        },
        {
          transform: "rotate(-12.7892 410.59 143.475)",
          y2: "143.74339",
          x2: "374.19577",
          y1: "143.20662",
          x1: "446.98496"
        }
      ],
      orientation: {
        mesial: {
          transform: "rotate(257.66 409.896 131.59)",
          d: "m399.28149,126.64209l11.58165,0l9.65137,4.95437l-9.65137,4.95438l-11.58165,0l0,-9.90875z"
        },
        distal: {
          transform: "rotate(77.754 416.034 153.943)",
          d: "m405.41785,148.98828l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        vestibular: {
          transform:"rotate(-13.3398 425.584 139.419)",
          d: "m414.96777,134.46509l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        palatal: {
          transform: "rotate(165.702 400.508 144.951)",
          d: "m389.89193,139.99526l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
      }
    },
  },
  {
    id: 23,
    d: 'm354.16794,95.94999c0.79154,-2.11078 11.6093,-12.13699 11.6093,-12.40084c0,-0.26385 12.92854,-13.72008 12.81282,-13.88479c0.11571,0.16471 11.46116,-15.13845 32.83283,-5.63994c21.37166,9.49852 19.52473,34.3002 13.19238,39.04946c-6.33234,4.74926 -25.06553,13.98393 -32.45326,15.30317c-7.38774,1.31924 -16.35856,3.16617 -25.32938,1.31924c-8.97082,-1.84693 -12.66469,-6.33234 -13.98393,-11.34545c-1.31924,-5.01311 0.5277,-10.29006 1.31924,-12.40084l0,-0.00001z',
    x: '331.00269',
    y: '133.69006',
    index: {
      ellipse: {
        x: "392.34494",
        y: "91.72908",
        r: "9.99658"
      },
      lines: [
        {
          transform: "rotate(-30.6054 392.495 91.7003)",
          y2: "115.38747",
          x2: "392.82162",
          y1: "68.01318",
          x1: "392.16968"
        },
        {
          transform: "rotate(-30.6054 389.072 93.4729)",
          y2: "93.74107",
          x2: "353.28163",
          y1: "93.20431",
          x1: "424.86247"
        }
      ],
      orientation: {
        mesial: {
          transform: "rotate(239.485 386.507 82.1417)",
          d: "m375.89358,77.18996l11.58165,0l9.65137,4.95437l-9.65137,4.95438l-11.58165,0l0,-9.90875z"
        },
        distal: {
          transform: "rotate(59.5791 399.308 101.466)",
          d: "m388.69169,96.51151l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        vestibular: {
          transform: "rotate(-31.5147 403.851 84.6885)",
          d: "m393.2348,79.73439l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        palatal: {
          transform: "rotate(147.527 381.752 97.7662)",
          d: "m371.13583,92.81128l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
      }
    },
  },
  {
    id: 22,
    d: 'm319.34005,66.1352l7.00817,-21.80022c0.11571,0.16471 4.86497,-11.70844 8.03114,-11.97228c3.16617,-0.26385 5.27695,-6.86004 15.83086,0.26385c10.55391,7.12389 20.31627,5.80465 24.01014,18.20549c3.69387,12.40084 -19.26088,29.81479 -24.27399,34.56405c-5.01311,4.74926 -18.99703,13.45623 -28.49555,9.23467c-9.49852,-4.22156 -3.95771,-24.01014 -2.11078,-28.49555l0.00001,-0.00001z',
    x: '302.41199',
    y: '117.01535',
    index: {
      ellipse: {
        x: "343.81907",
        y: "60.70079",
        r: "9.99658"
      },
      lines: [
        {
          transform: "rotate(31.4641 341.113 65.3989)",
          y2: "97.67964",
          x2: "341.43929",
          y1: "33.11826",
          x1: "340.78735"
        },
        {
          transform: "rotate(31.4641 344.812 60.6579)",
          y2: "60.92628",
          x2: "322.26073",
          y1: "60.38952",
          x1: "367.36383"
        }
      ],
      orientation: {
        mesial: {
          transform: "rotate(212.731 334.173 55.2347)",
          d: "m323.55839,50.28118l11.58165,0l9.65137,4.95437l-9.65137,4.95438l-11.58165,0l0,-9.90875z"
        },
        distal: {
          transform: "rotate(32.8247 354.303 66.7296)",
          d: "m343.68625,61.77534l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z" 
        },
        vestibular: {
          transform: "rotate(-58.2691 350.807 49.7034)",
          d: "m340.19068,44.74913l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        palatal: {
          transform: "rotate(120.773 336.961 71.3294)",
          d: "m326.34479,66.37486l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
      }
    },
  },
  {
    id: 21,
    d: 'm252.05889,15.74029l-0.90726,18.83232c0.11572,0.16471 0.37957,18.10635 3.28189,23.911c2.90232,5.80465 4.22156,17.41395 8.70697,21.63551c4.48541,4.22156 17.41395,9.76236 20.84397,6.0685c3.43002,-3.69387 9.76236,-2.63848 26.91246,-22.95475c17.1501,-20.31627 14.51162,-38.52176 14.39591,-38.68647c0.11572,0.16471 -0.67583,-10.65304 -25.47751,-14.87461c-24.80168,-4.22156 -32.18942,-3.69387 -36.93868,-2.37463c-4.74926,1.31924 -7.91543,1.84693 -10.81775,8.44313z',
    x: '263.98958',
    y: '111.68607',
    index: {
      ellipse: {
        x: "284.61303",
        y: "42.14525",
        r: "11.78474"
      },
      lines: [
        {
          transform: "rotate(6.1038 284.228 47.3428)",
          y2: "84.63957",
          x2: "284.61266",
          y1: "10.04594",
          x1: "283.84411"
        },
        {
          transform: "rotate(6.1038 286.456 42.146)",
          y2: "42.46241",
          x2: "252.52058",
          y1: "41.82963",
          x1: "320.39162"
        }
      ],
      orientation: {
        distal: {
          transform: "rotate(5.63731 300.383 43.5073)",
          d: "m286.18757,36.88294l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z" 
        },
        mesial: {
          transform: "rotate(-176.41 270.05 40.5718)",
          d: "m255.85507,33.94594l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        vestibular: {
          transform: "rotate(-86.4332 286.013 27.1758)",
          d: "m271.81588,20.55144l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        palatal: {
          transform: "rotate(94.4955 284.47 57.368)",
          d: "m270.27362,50.74375l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
      }
    },
  },
  {
    id: 48,
    d: 'm552.26363,45.03779c-1.31924,8.70697 -2.63848,17.41395 -2.63848,17.67779c0,0.26385 -5.5408,12.13699 8.17928,22.95475c13.72008,10.81775 24.80168,9.76236 29.02324,11.0816c4.22156,1.31924 35.61944,1.31924 38.78561,-0.5277c3.16617,-1.84693 13.72008,-1.84693 15.30317,-27.44016c1.58309,-25.59322 5.5408,-17.67779 -0.26385,-28.7594c-5.80465,-11.0816 -46.70104,-29.02324 -59.10188,-27.44016c-12.40084,1.58309 -9.23467,-0.26385 -15.83086,6.86004c-6.59619,7.12389 -12.13699,16.88625 -13.45623,25.59322l0,0.00002z',
    x: '650.66031',
    y: '76.42846',
    index: {
      ellipse: {
        x: "597.66682",
        y: "56.49276",
        r: "12.5"
      },
      lines: [
        {
          transform: "rotate(15.1766 597.26 57.4416)",
          y2: "95.7604",
          x2: "597.28595",
          y1: "19.12287",
          x1: "597.2353"
        },
        {
          transform: "rotate(106.351 597.648 56.2957)",
          y2: "99.90636",
          x2: "598.05518",
          y1: "12.68424",
          x1: "597.23998"
        }
      ],
      orientation: {
        lingual: {
          transform: "rotate(16.5125 611.695 60.4226)",
          d: "m597.50002,53.7982l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        vestibular: {
          transform: "rotate(-165.534 582.461 51.8177)",
          d: "m568.26601,45.19358l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        distal: {
          transform: "rotate(-75.558 600.664 41.6748)",
          d: "m586.46795,35.05064l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        mesial: {
          transform: "rotate(105.371 593.453 71.0302)",
          d: "m579.25752,64.4055l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
      }
    },
  },
  {
    id: 47,
    d: 'm560.44291,119.17897l0.67582,13.02768c0.11572,0.1647 1.6988,18.63404 8.295,31.82642c6.59619,13.19238 4.22156,14.24777 18.46934,19.26088c14.24777,5.01311 27.44016,-5.27695 35.61944,-8.97082c8.17928,-3.69387 21.63551,-5.5408 23.74629,-8.70697c2.11078,-3.16617 5.27695,-7.12389 -0.5277,-29.81479c-5.80465,-22.6909 -6.33234,-25.32938 -12.66469,-31.13403c-6.33234,-5.80465 -26.64862,-4.48541 -32.18942,-4.48541c-5.5408,0 -22.95475,3.16617 -30.34248,5.80465c-7.38774,2.63848 -8.97082,9.49852 -11.0816,13.19238l0,0.00001z',
    x: '654.74643',
    y: '130.56964',
    index: {
      ellipse: {
        x: "605.45469",
        y: "139.22324",
        r: "12.5"
      },
      lines: [
        {
          transform: "rotate(-11.6436 605.52 140.253)",
          y2: "178.57222",
          x2: "605.5457",
          y1: "101.93469",
          x1: "605.49505"
        },
        {
          transform: "rotate(79.5308 605.349 139.055)",
          y2: "178.37617",
          x2: "605.7572",
          y1: "99.733",
          x1: "604.94199"
        }
      ],
      orientation: {
        lingual: {
          transform: "rotate(-9.62527 619.705 136.42)",
          d: "m605.51016,129.796l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        vestibular: {
          transform: "rotate(-191.672 589.67 141.574)",
          d: "m575.47516,134.95037l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        distal: {
          transform: "rotate(-101.696 601.544 124.449)",
          d: "m587.34812,117.82394l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        mesial: {
          transform: "rotate(79.2332 608.002 153.98)",
          d: "m593.80609,147.35527l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
      }
    },
  },
  {
    id: 46,
    d: 'm656.74731,200.1802c0.79154,2.63848 7.12389,12.66469 7.65158,17.94164c0.5277,5.27695 0.82396,8.11375 3.842,13.29154c3.01804,5.17779 4.07343,13.09322 -5.16124,20.74481c-9.23467,7.65158 -21.63551,12.66469 -25.85707,14.51162c-4.22156,1.84693 -24.53783,8.17928 -30.87018,6.33234c-6.33234,-1.84693 -13.15997,-15.36869 -14.09964,-18.63403c-0.93967,-3.26533 -4.10585,-9.59768 -8.8551,-17.77695c-4.74926,-8.17928 -3.69387,-13.72008 -1.58309,-20.58012c2.11078,-6.86004 -0.26385,-12.40084 0.26385,-15.30317c0.5277,-2.90232 4.48541,-15.03932 30.07864,-15.30317c25.59322,-0.26385 31.13403,1.84693 34.03635,3.16617c2.90232,1.31924 9.76236,8.97082 10.55391,11.6093l-0.00001,0.00002z',
    x: '669.76134',
    y: '214.31807',
    index: {
      ellipse: {
        x: "625.33057",
        y: "226.88234",
        r: "12.5"
      },
      lines: [
        {
          transform: "rotate(-23.437 624.942 225.714)",
          y2: "267.53804",
          x2: "625.21899",
          y1: "183.89081",
          x1: "624.66491"
        },
        {
          transform: "rotate(67.7374 625.192 226.74)",
          y2: "266.06068",
          x2: "625.60006",
          y1: "187.41751",
          x1: "624.78485"
        }
      ],
      orientation: {
        lingual: {
          transform: "rotate(-22.4718 639.807 221.11)",
          d: "m625.61152,214.48589l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        vestibular: {
          transform: "rotate(-204.519 611.67 232.814)",
          d: "m597.47526,226.19057l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        distal: {
          transform: "rotate(-114.543 619.439 213.477)",
          d: "m605.24382,206.85078l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        mesial: {
          transform: "rotate(66.3867 632.301 240.832)",
          d: "m618.10524,234.20852l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
      }
    },
  },
  {
    id: 45,
    d: 'm620.60018,281.97297c0,0 11.6093,-7.38774 11.87315,-7.38774c0.26385,0 10.55391,-4.22156 24.01014,-0.79154c13.45623,3.43002 10.55391,1.31924 27.44016,10.29006c16.88625,8.97082 -7.65158,29.02324 -10.14193,30.44166c-10.17434,9.1355 -10.70204,14.41245 -31.28216,11.24628c-20.58012,-3.16617 -26.91246,-16.09471 -27.02818,-16.25938c0.11572,0.16468 -9.11895,-13.02771 5.12882,-27.53933l0,-0.00001z',
    x: '697.38481',
    y: '292.56',
    index: {
      ellipse: {
        x: "646.82827",
        y: "299.88273",
        r: "9.99658"
      },
      lines: [
        {
          transform: "rotate(-12.4163 646.975 299.803)",
          y2: "325.81769",
          x2: "647.31829",
          y1: "273.7883",
          x1: "646.63296"
        },
        {
          transform: "rotate(-12.4163 651.607 298.661)",
          y2: "298.92946",
          x2: "614.94369",
          y1: "298.3927",
          x1: "688.27022"
        }
      ],
      orientation: {
        distal: {
          transform: "rotate(256.56 643.248 289.071)",
          d: "m632.61747,284.12526l11.58165,0l9.65137,4.95437l-9.65137,4.95438l-11.58165,0l0,-9.90875z"
        },
        mesial: {
          transform: "rotate(76.6544 649.82 311.318)",
          d: "m639.2029,306.36247l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        lingual: {
          transform: "rotate(-14.4394 659.09 296.612)",
          d: "m648.47375,291.65746l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        vestibular: {
          transform: "rotate(164.602 634.123 302.62)",
          d: "m623.50715,297.65937l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
      }
    },
  },
  {
    id: 44,
    d: 'm634.32026,345.56027c4.48541,-6.06851 13.19238,-12.66469 13.72008,-12.66469c0.5277,0 14.77547,-5.80465 15.30317,-5.27695c0.5277,0.5277 10.81775,-2.63848 20.84397,0.26385c10.02621,2.90232 17.94164,10.81775 18.99703,13.72008c1.05539,2.90232 4.22156,6.86004 -0.5277,15.83086c-4.74926,8.97082 -9.49852,18.73319 -25.06553,23.2186c-15.56701,4.48541 -34.56405,-3.69387 -37.73022,-8.44313c-3.16617,-4.74926 -10.02621,-20.58011 -5.5408,-26.64862z',
    x: '709.7547',
    y: '338.19216',
    index: {
      ellipse: {
        x: "668.48011",
        y: "352.98337",
        r: "9.99658"
      },
      lines: [
        {
          transform: "rotate(-14.2303 669.041 354.608)",
          y2: "379.95674",
          x2: "669.36713",
          y1: "329.26016",
          x1: "668.71519"
        },
        {
          transform: "rotate(-14.2303 670.139 352.392)",
          y2: "352.66029",
          x2: "636.75816",
          y1: "352.12353",
          x1: "703.51922"
        }
      ],
      orientation: {
        distal: {
          transform: "rotate(256.56 665.885 342.062)",
          d: "m655.25439,337.11624l11.58165,0l9.65137,4.95437l-9.65137,4.95438l-11.58165,0l0,-9.90875z"
        },
        mesial: {
          transform: "rotate(76.6544 672.457 364.309)",
          d: "m661.83982,359.35345l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        lingual: {
          transform: "rotate(-14.4394 681.727 349.603)",
          d: "m671.11067,344.64844l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        vestibular: {
          transform: "rotate(164.602 656.76 355.611)",
          d: "m646.14407,350.65035l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
      }
    },
  },
  {
    id: 43,
    d: 'm664.66274,397.01059c6.59619,-6.86001 16.35856,-14.51162 16.35856,-14.51162c0,0 19.26088,-10.55391 21.63551,-9.49852c2.37463,1.05539 24.53783,-6.0685 23.2186,11.34545c-1.31924,17.41395 -6.0685,23.2186 -11.0816,32.45326c-5.01311,9.23467 -3.69387,19.26088 -21.89936,17.94164c-18.20549,-1.31924 -28.7594,-18.99703 -28.87511,-19.16174c0.11572,0.16471 -5.95278,-11.70846 0.64341,-18.56847l-0.00001,0z',
    x: '724.69975',
    y: '367.62546',
    index: {
      ellipse: {
        x: "697.4545",
        y: "402.286",
        r: "9.99658"
      },
      lines: [
        {
          transform: "rotate(41.9593 698.774 401.034)",
          y2: "433.71327",
          x2: "699.10049",
          y1: "368.35425",
          x1: "698.44855"
        },
        {
          transform: "rotate(41.9593 697.511 402.114)",
          y2: "402.38231",
          x2: "673.82289",
          y1: "401.84555",
          x1: "721.19862"
        }
      ],
      orientation: {
        mesial: {
          transform: "rotate(400.989 706.283 408.893)",
          d: "m695.66587,403.93856l11.58165,0l9.65137,4.95437l-9.65137,4.95438l-11.58165,0l0,-9.90875z"
        },
        distal: {
          transform: "rotate(221.083 687.933 394.646)",
          d: "m677.27391,389.75984l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        vestibular: {
          transform: "rotate(129.99 689.031 411.98)",
          d: "m678.41367,407.02916l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        lingual: {
          transform: "rotate(309.031 705.693 392.411)",
          d: "m695.05073,387.6616l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
      }
    },
  },
  {
    id: 42,
    d: 'm716.64074,423.65921l7.27201,-12.3017c0.11572,0.16471 5.12883,-5.90379 11.46117,-10.3892c6.33234,-4.48541 15.03932,-11.87315 18.20549,-11.87315c3.16617,0 13.72008,-3.69387 11.6093,30.34248c-2.11078,34.03635 -2.63848,28.2317 -8.17928,32.71711c-5.5408,4.48541 -14.24777,5.27695 -24.53783,-1.58309c-10.29006,-6.86004 -26.12092,-3.43002 -15.83086,-26.91246l0,0.00001z',
    x: '754.10989',
    y: '380.27948',
    index: {
      ellipse: {
        x: "742.62866",
        y: "425.68939",
        r: "9.99658"
      },
      lines: [
        {
          transform: "rotate(21.8263 745.109 419.886)",
          y2: "451.20599",
          x2: "745.43455",
          y1: "388.56537",
          x1: "744.78261"
        },
        {
          transform: "rotate(21.8263 742.622 425.965)",
          y2: "426.2334",
          x2: "718.93438",
          y1: "425.69663",
          x1: "766.31012"
        }
      ],
      orientation: {
        mesial: {
          transform: "rotate(383.573 754.18 428.964)",
          d: "m743.56373,424.00934l11.58165,0l9.65137,4.95437l-9.65137,4.95438l-11.58165,0l0,-9.90875z"
        },
        distal: {
          transform: "rotate(203.667 732.446 420.847)",
          d: "m721.81521,415.93106l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        vestibular: {
          transform: "rotate(112.574 738.641 437.073)",
          d: "m728.02293,432.12041l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        lingual: {
          transform: "rotate(291.615 748.702 413.597)",
          d: "m738.22308,408.76384l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z" 
        },
      }
    },
  },
  {
    id: 41,
    d: 'm767.82719,434.21312l4.63354,-13.3571l8.44313,-12.40084c0.11572,0.16471 16.21043,-21.4708 23.59816,5.70551c7.38774,27.17631 4.74926,34.82789 4.22156,38.52176c-0.5277,3.69387 -1.84693,11.0816 -15.83086,10.29006c-13.98393,-0.79154 -24.01014,-1.31924 -25.59322,-7.12389c-1.58309,-5.80465 -3.69387,-11.0816 0.5277,-21.63551l-0.00001,0.00001z',
    x: '780.86504',
    y: '390.42276',
    index: {
      ellipse: {
        x: "789.35479",
        y: "436.36732",
        r: "9.99658"
      },
      lines: [
        {
          transform: "rotate(9.80407 790.381 431.282)",
          y2: "461.10653",
          x2: "790.70599",
          y1: "401.45783",
          x1: "790.05405"
        },
        {
          transform: "rotate(9.80407 789.791 436.274)",
          y2: "436.54329",
          x2: "769.82031",
          y1: "436.00653",
          x1: "809.7646"
        }
      ],
      orientation: {
        mesial: {
          transform: "rotate(369.049 801.08 436.387)",
          d: "m790.46363,431.4366l11.58165,0l9.65137,4.95437l-9.65137,4.95438l-11.58165,0l0,-9.90875z"
        },
        distal: {
          transform: "rotate(189.143 778.016 433.967)",
          d: "m767.39378,429.03243l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        vestibular: {
          transform: "rotate(98.0498 788.069 448.138)",
          d: "m777.45114,443.18387l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        lingual: {
          transform: "rotate(277.091 792.06 422.906)",
          d: "m781.58066,417.93439l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
      }
    },
  },
  {
    id: 38,
    d: 'm965.4491,58.49402l1.73122,11.70842c0.11572,0.16472 -0.93967,17.57867 23.86201,14.14865c24.80168,-3.43002 31.13403,-1.05539 31.01831,-1.22011c8.55884,-4.32069 12.51656,-2.7376 17.79351,-8.54225c5.27695,-5.80465 17.94164,-4.74926 14.51162,-26.38477c-3.43002,-21.63551 0.79154,-18.20549 -1.84693,-27.17631c-2.63848,-8.97082 -0.79154,-24.53783 -30.34248,-20.31627c-29.55094,4.22156 -35.35559,11.34545 -35.47131,11.18073c-1.20352,1.74781 -29.17138,6.49704 -21.25595,46.60191z',
    x: '932.1466',
    y: '69.12085',
    index: {
      ellipse: {
        x: "1010.4669",
        y: "42.28888",
        r: "12.5"
      },
      lines: [
        {
          transform: "rotate(-14.6536 1011.48 43.9632)",
          y2: "81.84694",
          x2: "1011.50949",
          y1: "6.07935",
          x1: "1011.45884"
        },
        {
          transform: "rotate(76.5208 1009.35 42.489)",
          y2: "86.65873",
          x2: "1009.75382",
          y1: "-1.68078",
          x1: "1008.93862"
        }
      ],
      orientation: {
        vestibular: {
          transform: "rotate(-13.9193 1025.38 37.5453)",
          d: "m1011.18144,30.92082l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        lingual: {
          transform: "rotate(-195.967 995.813 44.9348)",
          d: "m981.61915,38.31211l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        distal: {
          transform: "rotate(-105.991 1006.37 26.9691)",
          d: "m992.17816,20.34209l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        mesial: {
          transform: "rotate(74.9392 1015.02 55.9302)",
          d: "m1000.82659,49.30698l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
      }
    },
  },
  {
    id: 37,
    d: 'm959.38061,133.95444l-0.90726,14.08308c0.11572,0.1647 -3.3143,7.81628 13.57195,13.35708c16.88625,5.5408 27.17631,8.44313 34.82789,8.44313c7.65158,0 12.92854,3.16617 20.58012,-2.63848c7.65158,-5.80465 10.02621,-9.49852 11.0816,-16.09471c1.05539,-6.59619 2.11078,-17.1501 3.16617,-27.44016c-0.26385,-6.33234 14.24777,-32.98096 -22.95475,-34.3002c-37.20252,-1.31924 -43.53487,3.95772 -48.54797,6.33234c-5.01311,2.37463 -8.70697,16.0947 -10.81775,38.25791l0,0.00001z',
    x: '932.2488',
    y: '133.07954',
    index: {
      ellipse: {
        x: "1001.5536",
        y: "128.96718",
        r: "12.5"
      },
      lines: [
        {
          transform: "rotate(9.88203 1001.72 128.331)",
          y2: "165.44417",
          x2: "1001.74727",
          y1: "91.21691",
          x1: "1001.69662"
        },
        {
          transform: "rotate(101.056 1000.57 128.583)",
          y2: "167.33347",
          x2: "1000.97886",
          y1: "89.83308",
          x1: "1000.16366"
        }
      ],
      orientation: {
        vestibular: {
          transform: "rotate(11.5984 1017.37 131.776)",
          d: "m1003.17545,125.15054l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        lingual: {
          transform: "rotate(-170.449 987.509 125.709)",
          d: "m973.31727,119.08748l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        distal: {
          transform: "rotate(-80.4733 1004.77 114.045)",
          d: "m990.58316,107.42031l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        mesial: {
          transform: "rotate(100.457 1000.1 143.903)",
          d: "m985.91008,137.278l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
      }
    },
  },
  {
    id: 36,
    d: 'm950.67363,204.40176l-2.75419,15.93002c0.11572,0.16469 -7.53587,13.09323 1.17111,21.00866c8.70697,7.91543 19.52473,10.02621 25.59322,13.72008c6.0685,3.69387 12.13699,8.44313 22.95475,6.86004c10.81775,-1.58309 21.37166,-5.01311 21.10781,-13.45623c-0.26385,-8.44313 6.86004,-13.72008 10.55391,-20.58012c3.69387,-6.86004 7.91543,-19.26088 0.79154,-36.93868c-7.12389,-17.67779 -21.10781,-15.83086 -29.40281,-15.20401c-9.11895,-0.36301 -20.4644,-4.58457 -27.58829,-1.4184c-7.12389,3.16617 -19.26088,5.80469 -22.42705,30.07864z',
    x: '920.42616',
    y: '209.87859',
    index: {
      ellipse: {
        x: "991.15628",
        y: "216.07211",
        r: "12.5"
      },
      lines: [
        {
          transform: "rotate(12.0477 990.954 217.291)",
          y2: "257.43124",
          x2: "990.97962",
          y1: "177.15093",
          x1: "990.92897"
        },
        {
          transform: "rotate(103.222 990.89 215.817)",
          y2: "254.36101",
          x2: "991.29797",
          y1: "177.27319",
          x1: "990.48277"
        }
      ],
      orientation: {
        vestibular: {
          transform: "rotate(14.687 1005.88 219.52)",
          d: "m991.68568,212.89559l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        lingual: {
          transform: "rotate(-167.361 976.392 211.857)",
          d: "m962.20417,205.23706l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        distal: {
          transform: "rotate(-77.3847 994.248 201.133)",
          d: "m980.05876,194.51631l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
        mesial: {
          transform: "rotate(103.546 987.987 230.699)",
          d: "m973.79402,224.07435l15.48561,0l12.90467,6.6244l-12.90467,6.6244l-15.48561,0l0,-13.2488z"
        },
      }
    },
  },
  {
    id: 35,
    d: 'm933.25969,298.06772c0.26385,0.26385 9.23467,9.49852 9.11895,9.33379c0.11572,0.16472 13.04425,10.19094 20.43199,9.66324c7.38774,-0.5277 15.83086,5.80465 27.17631,-2.37463c11.34545,-8.17928 15.56701,-19.78858 14.77547,-27.17631c-0.79154,-7.38774 -10.81775,-22.95475 -22.42705,-22.6909c-11.6093,0.26385 -12.92854,-5.5408 -36.67483,2.63848c-23.74629,8.17928 -12.66469,30.34248 -12.40084,30.60633z',
    x: '903.36692',
    y: '286.33333',
    index: {
      ellipse: {
        x: "972.3971",
        y: "290.53842",
        r: "9.99658"
      },
      lines: [
        {
          transform: "rotate(14.0304 972.525 290.624)",
          y2: "315.32224",
          x2: "972.85052",
          y1: "265.92544",
          x1: "972.19858"
        },
        {
          transform: "rotate(14.0304 967.951 289.257)",
          y2: "289.52518",
          x2: "932.50786",
          y1: "288.98842",
          x1: "1003.39499"
        }
      ],
      orientation: {
        distal: {
          transform: "rotate(284.321 975.008 280.04)",
          d: "m964.36504,275.08286l11.58165,0l9.65137,4.95437l-9.65137,4.95438l-11.58165,0l0,-9.90875z"
        },
        mesial: {
          transform: "rotate(104.415 970.447 302.798)",
          d: "m959.82868,297.84298l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        vestibular: {
          transform: "rotate(13.3216 985.499 294.101)",
          d: "m974.8828,289.14647l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z" 
        },
        lingual: {
          transform: "rotate(192.363 960.608 287.782)",
          d: "m949.9906,282.81523l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
      }
    },
  },
  {
    id: 34,
    d: 'm915.0542,341.07485l6.48047,14.0831c0.11572,0.16468 5.92037,7.28856 8.55884,9.1355c2.63848,1.84693 11.6093,9.76236 18.99703,10.81775c7.38774,1.05539 21.10781,-4.74926 25.59322,-9.23467c4.48541,-4.48541 27.44016,-25.06553 -5.01311,-41.42409c-32.45326,-16.35856 -57.25495,4.7493 -54.61647,16.6224l0.00002,0.00001z',
    x: '891.33382',
    y: '330.68145',
    index: {
      ellipse: {
        x: "953.31174",
        y: "347.47885",
        r: "9.99658"
      },
      lines: [
        {
          transform: "rotate(29.7864 953.877 346.782)",
          y2: "371.85899",
          x2: "954.20284",
          y1: "321.70529",
          x1: "953.5509"
        },
        {
          transform: "rotate(29.7864 949.375 345.035)",
          y2: "345.30314",
          x2: "918.35037",
          y1: "344.76638",
          x1: "980.39987"
        }
      ],
      orientation: {
        distal: {
          transform: "rotate(300.955 959.17 337.685)",
          d: "m948.53593,332.70206l11.58165,0l9.65137,4.95437l-9.65137,4.95438l-11.58165,0l0,-9.90875z"
        },
        mesial: {
          transform: "rotate(121.049 948.269 358.166)",
          d: "m937.64911,353.21243l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        vestibular: {
          transform: "rotate(29.9556 965.18 354.139)",
          d: "m954.56349,349.18428l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        lingual: {
          transform: "rotate(208.997 943.146 340.949)",
          d: "m932.53195,335.96999l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
      }
    },
  },
  {
    id: 33,
    d: 'm915.58189,421.28458c-8.70697,-10.81775 -13.45623,-20.31627 -16.35856,-25.06553c-2.90232,-4.74926 -5.5408,-11.87315 -2.37463,-22.16321c3.16617,-10.29006 18.46934,-10.29006 31.66172,-3.43002c13.19238,6.86004 20.05242,8.17928 30.87018,21.63551c10.81775,13.45623 -18.43692,31.06845 -21.22353,33.87164c-2.78661,2.80319 -13.86821,5.96936 -22.57518,-4.84839z',
    x: '873.45893',
    y: '362.4219',
    index: {
      ellipse: {
        x: "928.17212",
        y: "396.8212",
        r: "9.99658"
      },
      lines: [
        {
          transform: "rotate(-42.9171 924.227 393.109)",
          y2: "425.22611",
          x2: "924.42903",
          y1: "360.99112",
          x1: "924.02482"
        },
        {
          transform: "rotate(-42.9171 928.006 396.75)",
          y2: "397.01814",
          x2: "904.31787",
          y1: "396.48138",
          x1: "951.6936"
        }
      ],
      orientation: {
        distal: {
          transform: "rotate(318.902 935.302 388.628)",
          d: "m924.7022,383.64448l11.58165,0l9.65137,4.95437l-9.65137,4.95438l-11.58165,0l0,-9.90875z"
        },
        mesial: {
          transform: "rotate(138.996 918.647 404.734)",
          d: "m908.02657,399.78384l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        vestibular: {
          transform: "rotate(47.9029 935.975 406.111)",
          d: "m925.35868,401.15657l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        lingual: {
          transform: "rotate(226.944 919.102 386.77)",
          d: "m908.50228,381.77032l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
      }
    },
  },
  {
    id: 32,
    d: 'm858.59079,419.70149l-0.37957,-14.94018c0.11572,0.16471 -1.73122,-7.48687 3.80959,-15.13845c5.5408,-7.65158 24.01014,2.63848 29.81479,9.76236c5.80465,7.12389 19.78858,22.16321 20.05242,26.64862c0.26385,4.48541 3.43002,8.70697 -15.30317,18.20549c-18.73319,9.49852 -27.44016,7.38774 -30.87018,1.84693c-3.43002,-5.5408 -5.5408,-11.34545 -7.12389,-26.38477l0.00001,0z',
    x: '847.90388',
    y: '377.78276',
    index: {
      ellipse: {
        x: "881.99545",
        y: "420.13884",
        r: "9.99658"
      },
      lines: [
        {
          transform: "rotate(-28.9582 880.323 415.945)",
          y2: "446.74453",
          x2: "880.71275",
          y1: "385.14547",
          x1: "879.93284"
        },
        {
          transform: "rotate(-28.9582 880.748 420.551)",
          y2: "420.74195",
          x2: "858.98643",
          y1: "420.3602",
          x1: "902.51035"
        }
      ],
      orientation: {
        distal: {
          transform: "rotate(332.188 891.591 414.368)",
          d: "m880.99788,409.41787l11.58165,0l9.65137,4.95437l-9.65137,4.95438l-11.58165,0l0,-9.90875z"
        },
        mesial: {
          transform: "rotate(152.282 871.704 426.247)",
          d: "m861.08377,421.30229l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        vestibular: {
          transform: "rotate(61.1891 888.249 431.564)",
          d: "m877.63295,426.60973l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        lingual: {
          transform: "rotate(240.23 876.311 408.892)",
          d: "m865.75621,403.87421l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
      }
    },
  },
  {
    id: 31,
    d: 'm813.47284,432.63003l1.99506,-10.98247c0.11572,0.16471 1.17111,-7.48687 2.49035,-10.65304c1.31924,-3.16617 13.45623,-27.17631 28.2317,1.84693c14.77547,29.02324 13.98393,32.45326 12.92854,36.41098c-1.05539,3.95772 -1.58309,5.80465 -15.03932,10.81775c-13.45623,5.01311 -27.96785,1.58309 -29.55094,-2.90232c-1.58309,-4.48541 -3.69387,-13.19238 -1.05539,-24.53783z',
    x: '817.75069',
    y: '388.64202',
    index: {
      ellipse: {
        x: "835.10592",
        y: "433.92579",
        r: "9.99658"
      },
      lines: [
        {
          transform: "rotate(-10.8555 834.516 430.083)",
          y2: "459.24278",
          x2: "834.84198",
          y1: "400.92333",
          x1: "834.19004"
        },
        {
          transform: "rotate(-10.8555 834.19 433.933)",
          y2: "434.20116",
          x2: "814.89163",
          y1: "433.6644",
          x1: "853.48883"
        }
      ],
      orientation: {
        distal: {
          transform: "rotate(350.198 846.081 431.054)",
          d: "m835.48714,426.10364l11.58165,0l9.65137,4.95437l-9.65137,4.95438l-11.58165,0l0,-9.90875z"
        },
        mesial: {
          transform: "rotate(170.292 823.495 436.202)",
          d: "m812.87473,431.2572l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        vestibular: {
          transform: "rotate(79.1989 837.586 446.375)",
          d: "m826.96915,441.41998l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
        lingual: {
          transform: "rotate(258.24 833.242 421.123)",
          d: "m822.68721,416.10434l11.58165,0l9.65138,4.95437l-9.65138,4.95437l-11.58165,0l0,-9.90874z"
        },
      }
    },
  },
];

/**
 * List of available annotations for the ToothSelector component.
 * Each annotation consists of one or more svg text component.
 */
export const getAnnotations = () => ({
  patientRight: [
    // {
    //   id: 'patientRight.patient',
    //   x: '0',
    //   y: '22',
    //   text: 'PATIENT',
    // },
    {
      id: 'patientRight.right',
      x: '59',
      y: '100',
      text: 'RIGHT',
    },
  ],
  patientLeft: [
    // {
    //   id: 'patientLeft.patient',
    //   x: '455',
    //   y: '22',
    //   text: 'PATIENT',
    // },
    {
      id: 'patientLeft.left',
      x: '459',
      y: '100',
      text: 'LEFT',
    },
  ],
  top: [
    {
      id: 'top',
      x: '237',
      y: '301',
      text: 'MAXILLARY',
    },
  ],
  bottom: [
    {
      id: 'bottom',
      x: '233',
      y: '808',
      text: 'MANDIBULAR',
    },
  ],
});

/**
 * List of available annotations for the ToothSelector component in Report context.
 * Each annotation consists of one or more svg text component.
 */
export const getReportAnnotations = () => ({
  top: [
    {
      id: 'top',
      x: '201',
      y: '212',
      text: 'MAXILLARY',
    },
  ],
  bottom: [
    {
      id: 'bottom',
      x: '749',
      y: '248',
      text: 'MANDIBULAR',
    },
  ],
});
