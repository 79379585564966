/* eslint-disable @typescript-eslint/no-unused-vars */
import macro from '@kitware/vtk.js/macros';

import * as vtkMath from '@kitware/vtk.js/Common/Core/Math';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkCylinderSource from '@kitware/vtk.js/Filters/Sources/CylinderSource';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkSphereSource from '@kitware/vtk.js/Filters/Sources/SphereSource';
import vtkWidgetRepresentation from '@kitware/vtk.js/Widgets/Representations/WidgetRepresentation';
import { getCenter } from '@kitware/vtk.js/Common/DataModel/BoundingBox';


import { getResource, vtpReader } from '@/src/utils/resourceLoader';

import {
  RenderingTypes,
  ViewTypes,
} from '@kitware/vtk.js/Widgets/Core/WidgetManager/Constants';

import vtkResliceCursorContextRepresentation from '@kitware/vtk.js/Widgets/Representations/ResliceCursorContextRepresentation';
import { InteractionMethodsName } from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/Constants';

import { mat4 } from 'gl-matrix';

const EPSILON = 10e-6;

function vtkCustomResliceCursorContextRepresentation(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkCustomResliceCursorContextRepresentation');
  const superClass = { ...publicAPI };

  // If true, restrict reslice cursor interaction so it can only be translated along the 2nd axis
  model.translateAlongOneAxis = false;

  // Reset actors
  model.actors = [];

  // --------------------------------------------------------------------------
  // Generic rendering pipeline
  // --------------------------------------------------------------------------

  model.mapper = vtkMapper.newInstance();
  model.actor = vtkActor.newInstance({ parentProp: publicAPI });
  model.mapper.setInputConnection(publicAPI.getOutputPort());
  model.actor.setMapper(model.mapper);
  publicAPI.addActor(model.actor);

  model.pipelines = {};
  model.pipelines.center = {
    source: vtkPolyData.newInstance(), // empty polydata
    mapper: vtkMapper.newInstance(),
    actor: vtkActor.newInstance({
      parentProp: publicAPI,
      forceTranslucent: true,
    }),
  };
  model.pipelines.center.mapper.setColorModeToDirectScalars();

  model.pipelines.axes = [];

  const squarishCylinderProperties = {
    initAngle: Math.PI / 4,
    otherRadius: 0,
    resolution: 2,
  };

  // Create axis 1
  const axis1 = {};
  axis1.line = {
    source: vtkCylinderSource.newInstance(squarishCylinderProperties),
    mapper: vtkMapper.newInstance(),
    actor: vtkActor.newInstance({ pickable: true, _parentProp: publicAPI }),
  };
  axis1.rotation1 = {
    source: vtkSphereSource.newInstance({phiResolution: 16, thetaResolution: 16}),
    mapper: vtkMapper.newInstance(),
    actor: vtkActor.newInstance({ pickable: true, _parentProp: publicAPI }),
  };
  axis1.rotation2 = {
    source: vtkSphereSource.newInstance({phiResolution: 16, thetaResolution: 16}),
    mapper: vtkMapper.newInstance(),
    actor: vtkActor.newInstance({ pickable: true, _parentProp: publicAPI }),
  };
  // Create axis 2
  const axis2 = {};
  axis2.line = {
    source: vtkCylinderSource.newInstance(squarishCylinderProperties),
    mapper: vtkMapper.newInstance(),
    actor: vtkActor.newInstance({ pickable: true, _parentProp: publicAPI }),
  };
  axis2.rotation1 = {
    source: vtkSphereSource.newInstance({phiResolution: 16, thetaResolution: 16}),
    mapper: vtkMapper.newInstance(),
    actor: vtkActor.newInstance({ pickable: true, _parentProp: publicAPI }),
  };
  axis2.rotation2 = {
    source: vtkSphereSource.newInstance({phiResolution: 16, thetaResolution: 16}),
    mapper: vtkMapper.newInstance(),
    actor: vtkActor.newInstance({ pickable: true, _parentProp: publicAPI }),
  };

  model.pipelines.axes.push(axis1);
  model.pipelines.axes.push(axis2);

  // Define index
  model.pipelines.index = {
    source: null,
    mapper: vtkMapper.newInstance(),
    actor: vtkActor.newInstance({ pickable: false, scale: [2, 2, 2], _parentProp: publicAPI }),
  }

  model.index = {
    inAxis: 0.15,
    vector: null,
  }

  getResource('index', 'index.vtp', vtpReader).then((polydata) => {
    model.pipelines.index.source = polydata;
    model.pipelines.index.mapper.setInputData(model.pipelines.index.source);
    model.pipelines.index.actor.setMapper(model.pipelines.index.mapper);
    model.pipelines.index.actor.getProperty().setColor([1, 1, 0]);
    model.pipelines.index.actor.setVisibility(false);
    publicAPI.addActor(model.pipelines.index.actor);
  });

  // Improve actors rendering
  model.pipelines.center.actor.getProperty().setAmbient(1, 1, 1);
  model.pipelines.center.actor.getProperty().setDiffuse(0, 0, 0);

  model.pipelines.axes.forEach((axis) => {
    Object.values(axis).forEach((lineOrRotationHandle) => {
      vtkWidgetRepresentation.connectPipeline(lineOrRotationHandle);
      const { actor } = lineOrRotationHandle;
      actor.getProperty().setAmbient(1, 1, 1);
      actor.getProperty().setDiffuse(0, 0, 0);
      actor.getProperty().setBackfaceCulling(true);
      publicAPI.addActor(actor);
    });
  });

  vtkWidgetRepresentation.connectPipeline(model.pipelines.center);
  publicAPI.addActor(model.pipelines.center.actor);

  // ------------------------------------------------------

  publicAPI.getCenterMapper = () => {
    return model.pipelines.center.mapper;
  }

  publicAPI.setCenterPolyData = (centerPolydata) => {
    model.pipelines.center.mapper.setInputData(centerPolydata);
  }

  publicAPI.setCenterPolyDataConnection = (centerPolyData) => {
    model.pipelines.center.mapper.setInputConnection(centerPolyData);
  };

  publicAPI.setIndexVisibility = (visibility) => {
    const modified = model.pipelines.index.actor.getVisibility() !== visibility;
    model.pipelines.index.actor.setVisibility(visibility);
    model.pipelines.index.actor.modified();
    publicAPI.modified();

    return modified;
  }
  
  publicAPI.setIndexVector = (vector) => {
    model.index.vector = vector;
    publicAPI.modified();
  }

  // Overridden to avoid a bug when calling "center.actor.getCenter()" as center is a PolyData.
  publicAPI.setLineThickness = (lineThickness) => {
    let scaledLineThickness = lineThickness;
    if (publicAPI.getScaleInPixels()) {
      const centerCoords = model.pipelines.center.actor.getCenter();
      scaledLineThickness *= publicAPI.getPixelWorldHeightAtCoord(centerCoords);
    }
    model.pipelines.axes[0].line.source.setRadius(scaledLineThickness);
    model.pipelines.axes[1].line.source.setRadius(scaledLineThickness);
  };

  publicAPI.setSphereRadiusOnSphere = (radius, source) => {
    if (source.getClassName() === 'vtkPolyData') {
      return;
    }
    let scaledRadius = radius;
    if (publicAPI.getScaleInPixels()) {
      const centerCoords = source.getCenter();
      scaledRadius *= publicAPI.getPixelWorldHeightAtCoord(centerCoords);
    }
    source.setRadius(scaledRadius);
  };

  function updateIndexRender(state) {
    if (
      model.pipelines.index.actor.getVisibility()
      && model.viewType === ViewTypes.XY_PLANE
      && model.index.vector
    ) {
      // Find out on which of the 2 axes the index should be displayed.
      // 1- Project displacement vector on the two axes,
      // 2- Compute the norm of projected vector
      // 3- Select the axis with the highest norm to display the index on.

      // Axis 1:
      const axis1State = state[`get${model.axis1Name}`]();
      const axis1Vector = [0, 0, 0];
      vtkMath.subtract(axis1State.getPoint2(), axis1State.getPoint1(), axis1Vector);
      const projectionVec1 = [0, 0, 0];
      vtkMath.projectVector(model.index.vector, axis1Vector, projectionVec1);
      const norm1 = vtkMath.norm(projectionVec1, 3);

      // Axis 2
      const axis2State = state[`get${model.axis2Name}`]();
      const axis2Vector = [0, 0, 0];
      vtkMath.subtract(axis2State.getPoint2(), axis2State.getPoint1(), axis2Vector);
      const projectionVec2 = [0, 0, 0];
      vtkMath.projectVector(model.index.vector, axis2Vector, projectionVec2);
      const norm2 = vtkMath.norm(projectionVec2, 3);

      // Select the one with the highest norm
      const axisName = norm1 > norm2 ? model.axis1Name : model.axis2Name;

      // Select where to display the index in the axis
      // (whether should be displayed at point1 or point2 position).
      // Compute scalar product between the axis vector (pt2-pt1) and
      // if > 0, index should be placed at pt2 position
      // if < 0, index should be placed at pt1 position
      const axisState = state[`get${axisName}`]();
      const axisVector = [0, 0, 0];
      vtkMath.subtract(axisState.getPoint2(), axisState.getPoint1(), axisVector);
      const dot = vtkMath.dot(model.index.vector, axisVector);
      const position = dot > 0 ?  axisState.getPoint2() : axisState.getPoint1();

      const center = state.getCenter(); 
      const pointCenterVector = [0, 0, 0];
      vtkMath.subtract(position, center, pointCenterVector);
      const length = vtkMath.normalize(pointCenterVector);
      
      vtkMath.normalize(axisVector);
      
      const viewNormal =
        model.inputData[0].getPlanes()[axisState.getInViewType()].normal;
      const x = vtkMath.cross(axisVector, viewNormal, []);

      let indexDistance = 0;
  
      if (publicAPI.getScaleInPixels()) {
        const pixelWorldHeight = publicAPI.getPixelWorldHeightAtCoord(center);
        const { rendererPixelDims } = model.displayScaleParams;
        const totalSize =
          Math.min(rendererPixelDims[0], rendererPixelDims[1]) / 2;
        indexDistance = model.index.inAxis * pixelWorldHeight * totalSize;
      } else {
        indexDistance = model.index.inAxis / 2 * length;
      }

      const indexPosition = [];
      vtkMath.multiplyAccumulate(
        center,
        pointCenterVector,
        indexDistance,
        indexPosition
      );

      const indexMat = [...x, 0, ...axisVector, 0, ...viewNormal, 0, ...indexPosition, 1];
      // Rotate index mesh to always have the arrow pointed outside
      if (vtkMath.dot(pointCenterVector, axisVector) < 0) {
        mat4.rotateZ(indexMat, indexMat, Math.PI);
      }
      model.pipelines.index.actor.setUserMatrix(indexMat);
    } else {
      publicAPI.setIndexVisibility(false);
    }
  }

  function updateAxisRender(state, axis) {
    const color = state.getColor();
    axis.line.actor.getProperty().setColor(color);
    axis.rotation1.actor.getProperty().setColor(color);
    axis.rotation2.actor.getProperty().setColor(color);

    const vector = [0, 0, 0];
    vtkMath.subtract(state.getPoint2(), state.getPoint1(), vector);
    const center = [0, 0, 0];
    vtkMath.multiplyAccumulate(state.getPoint1(), vector, 0.5, center);
    const length = vtkMath.normalize(vector);
    axis.line.source.setHeight(20 * length); // make it an infinite line
    // Rotate the cylinder to be along vector
    const viewNormal =
      model.inputData[0].getPlanes()[state.getInViewType()].normal;
    const x = vtkMath.cross(vector, viewNormal, []);
    const mat = [...x, 0, ...vector, 0, ...viewNormal, 0, ...center, 1];
    axis.line.actor.setUserMatrix(mat);

    // Rotation handles
    let distance = 0;
    if (publicAPI.getScaleInPixels()) {
      const pixelWorldHeight = publicAPI.getPixelWorldHeightAtCoord(center);
      const { rendererPixelDims } = model.displayScaleParams;
      const totalSize =
        Math.min(rendererPixelDims[0], rendererPixelDims[1]) / 2;
      distance =
        publicAPI.getRotationHandlePosition() * pixelWorldHeight * totalSize;
    } else {
      distance = (publicAPI.getRotationHandlePosition() * length) / 2;
    }

    const rotationHandlePosition = [];
    vtkMath.multiplyAccumulate(
      center,
      vector,
      distance,
      rotationHandlePosition
    );
    axis.rotation1.source.setCenter(rotationHandlePosition);
    vtkMath.multiplyAccumulate(
      center,
      vector,
      -distance,
      rotationHandlePosition
    );
    axis.rotation2.source.setCenter(rotationHandlePosition);
  }

  function updateCenterRender(state) {
    const vector = [0, 0, 0];
    vtkMath.subtract(state.getPoint2(), state.getPoint1(), vector);
    const center = [0, 0, 0];
    vtkMath.multiplyAccumulate(state.getPoint1(), vector, 0.5, center);
    vtkMath.normalize(vector);
    const viewNormal =
      model.inputData[0].getPlanes()[state.getInViewType()].normal;
    const x = vtkMath.cross(vector, viewNormal, []);
    const mat = [...x, 0, ...vector, 0, ...viewNormal, 0, ...center, 1];

    let orientationMat = vtkMath.identity(4, []);
    if (model.viewType === ViewTypes.YZ_PLANE) {
      orientationMat = [
        ...[0, 1, 0],
        0,
        ...[0, 0, 1],
        0,
        ...[-1, 0, 0],
        0,
        ...[0, 0, 0],
        1,
      ];
    } else if (model.viewType === ViewTypes.XZ_PLANE) {
      orientationMat = [
        ...[0, -1, 0],
        0,
        ...[0, 0, 1],
        0,
        ...[1, 0, 0],
        0,
        ...[0, 0, 0],
        1,
      ];
    } else if (model.viewType === ViewTypes.XY_PLANE) { // TOP RIGHT
      orientationMat = [
        ...[1, 0, 0],
        0,
        ...[0, 1, 0],
        0,
        ...[0, 0, 1],
        0,
        ...[0, 0, 0],
        1,
      ];
    }

    // Re-orient center actor
    mat4.multiply(mat, mat, orientationMat);
    model.pipelines.center.actor.setUserMatrix(mat);
  }

  publicAPI.requestData = (inData, outData) => {
    const state = inData[0];

    const getAxis1 = `get${model.axis1Name}`;
    const getAxis2 = `get${model.axis2Name}`;
    const axis1State = state[getAxis1]();
    const axis2State = state[getAxis2]();

    updateIndexRender(state);
    updateCenterRender(axis2State);
    updateAxisRender(axis1State, model.pipelines.axes[0]);
    updateAxisRender(axis2State, model.pipelines.axes[1]);

    publicAPI.setLineThickness(state.getLineThickness());
    publicAPI.setSphereRadius(state.getSphereRadius());

    // TODO: return meaningful polydata (e.g. appended lines)
    outData[0] = vtkPolyData.newInstance();
  };

  if (process.env.NODE_ENV === 'development') {
    // Expose ResliceCursorContextRepresentation in debug console
    window.mprRep = window.mprRep || {};
    window.mprRep[model.viewType] = { model, publicAPI };
  }

  /**
   * Override updateActorVisibility so we can hide axis with "showAxis"
   */
  publicAPI.updateActorVisibility = (
    renderingType = RenderingTypes.FRONT_BUFFER,
    ctxVisible = true,
    hVisible = true
  ) => {
    const state = model.inputData[0];
    const visibility =
      hVisible || renderingType === RenderingTypes.PICKING_BUFFER;

    const opacity = state.getOpacity();
    const showAxis = state.getShowAxis();
    const axisOpacity = state.getAxisOpacity();

    const isAxialView = model.viewType === ViewTypes.XY_PLANE;
    const forceAxialDisplay = state.getForceAxisDisplayInAxial() && isAxialView;
    const disableAxisInteractions = state.getDisableAxialInteractions() && isAxialView;

    const translationActors = publicAPI.getTranslationActors();
    const rotationActors = publicAPI.getRotationActors();

    publicAPI.getActors().forEach((actor) => {
      const isNotIndexActor = actor !== model.pipelines.index.actor;
      if (isNotIndexActor) {
        actor.getProperty().setOpacity(opacity);
      }
      let actorVisibility = visibility;

      // Conditionally display axis
      if (translationActors.includes(actor)) {
        actor.getProperty().setOpacity(axisOpacity);
        actorVisibility = forceAxialDisplay ? actorVisibility : actorVisibility && showAxis;
      }

      // Conditionally display rotation handles
      if (rotationActors.includes(actor)) {
        actor.getProperty().setOpacity(axisOpacity);

        actorVisibility =
          actorVisibility && state.getEnableRotation() && showAxis;
      }

      // Conditionally display center handle but always show it for picking
      if (!state.getShowCenter() && actor === model.pipelines.center.actor) {
        actorVisibility =
          actorVisibility && renderingType === RenderingTypes.PICKING_BUFFER;
      }

      if (isNotIndexActor) {
        actor.setVisibility(actorVisibility);
      }

      // Conditionally pick lines
      if (translationActors.includes(actor)) {
        actor.setPickable(!disableAxisInteractions && state.getEnableTranslation());
      }

      // Disable center picking if interactions are disabled
      if (actor === model.pipelines.center.actor) {
        actor.setPickable(!disableAxisInteractions);
      }
    });

    let lineThickness = state.getLineThickness();
    if (renderingType === RenderingTypes.PICKING_BUFFER) {
      lineThickness = Math.max(3, lineThickness);
    }
    publicAPI.setLineThickness(lineThickness);

    let radius = state.getSphereRadius();
    if (renderingType === RenderingTypes.PICKING_BUFFER) {
      radius += 1;
    }
    publicAPI.setSphereRadius(radius);
  };

  /**
   * Override getSelectedState so we can restrict reslice cursor movement
   */
  publicAPI.getSelectedState = (prop, compositeID) => {
    const state = superClass.getSelectedState(prop, compositeID);

    if (state.getTranslateAlongOneAxis()) {
      const axis2State = state[`get${model.axis2Name}`]();
      state.setActiveLineState(axis2State);
      state.setUpdateMethodName(InteractionMethodsName.TranslateAxis);
    }

    return state;
  };
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

function defaultValues(initialValues) {
  return {
    ...initialValues,
  };
}

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, defaultValues(initialValues));

  vtkResliceCursorContextRepresentation.extend(publicAPI, model, initialValues);

  macro.setGet(publicAPI, model, ['translateAlongOneAxis']);
  macro.get(publicAPI, model, ['viewType', "axis1Name", "axis2Name"]);

  // Object specific methods
  vtkCustomResliceCursorContextRepresentation(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(
  extend,
  'vtkCustomResliceCursorContextRepresentation'
);

// ----------------------------------------------------------------------------

export default { newInstance, extend };
