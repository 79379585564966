import vtkSphereSource from '@kitware/vtk.js/Filters/Sources/SphereSource';
import macro from '@kitware/vtk.js/macro';

import vtkVolumeRepresentationProxy from '@kitware/vtk.js/Proxy/Representations/VolumeRepresentationProxy';

import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';

function vtkMPRVolumeRepresentationProxy(publicAPI, model) {
  model.classHierarchy.push('vtkMPRVolumeRepresentationProxy');

  model.mappers = [];

  // Define 3D view cursor
  const cursor = vtkSphereSource.newInstance({
    radius: 1,
  });
  const cursorMapper = vtkMapper.newInstance();
  cursorMapper.setInputConnection(cursor.getOutputPort());
  model.cursorActor = vtkActor.newInstance();
  model.cursorActor.setMapper(cursorMapper);
  model.cursorActor.getProperty().setColor(1, 0, 0);
  model.cursorActor.setVisibility(false); // By default not shown
  model.cursorActor.modified();
  model.actors.push(model.cursorActor);

  publicAPI.addActor = () => {
    const mapper = vtkMapper.newInstance();
    const actor = vtkActor.newInstance({ parentProp: publicAPI });
    actor.setMapper(mapper);
    model.mappers.push(mapper);
    model.actors.push(actor);

    publicAPI.modified();
    return actor;
  };

  publicAPI.updateActor = (actor, inputData) => {
    actor.getMapper().setInputData(inputData);
    publicAPI.modified();
  };

  publicAPI.removeActor = (removedActor) => {
    model.mappers = model.mappers.filter(
      (mapper) => mapper !== removedActor.getMapper()
    );
    model.actors = model.actors.filter((actor) => actor !== removedActor);
    removedActor.delete();
    publicAPI.modified();
  };

  if (process.env.NODE_ENV === 'development') {
    // Expose ResliceCursorWidget in debug console
    // @ts-ignore
    window.volumeRep = window.volumeRep || {};
    window.volumeRep = { model, publicAPI };
  }

  publicAPI.getCursorActor = () => {
    return model.cursorActor;
  }
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  // Object methods
  vtkVolumeRepresentationProxy.extend(publicAPI, model);

  // Object specific methods
  vtkMPRVolumeRepresentationProxy(publicAPI, model);

  console.log(`vtkMPRVolumeRepresentationProxy (${model.proxyName})- created`);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(
  extend,
  'vtkMPRVolumeRepresentationProxy'
);

// ----------------------------------------------------------------------------

export default { newInstance, extend };
