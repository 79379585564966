import { render, staticRenderFns } from "./Crop2DLineHandle.vue?vue&type=template&id=5b82f04e&scoped=true&"
import script from "./Crop2DLineHandle.vue?vue&type=script&lang=ts&"
export * from "./Crop2DLineHandle.vue?vue&type=script&lang=ts&"
import style0 from "@/src/components/styles/utils.css?vue&type=style&index=0&id=5b82f04e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b82f04e",
  null
  
)

export default component.exports