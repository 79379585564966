import { render, staticRenderFns } from "./ModulePanel.vue?vue&type=template&id=34a77c3b&scoped=true&"
import script from "./ModulePanel.vue?vue&type=script&lang=ts&"
export * from "./ModulePanel.vue?vue&type=script&lang=ts&"
import style0 from "./ModulePanel.vue?vue&type=style&index=0&id=34a77c3b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34a77c3b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VIcon,VTab,VTabItem,VTabs,VTabsItems})
