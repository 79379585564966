var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('item-group',{staticClass:"d-flex flex-column align-center",attrs:{"mandatory":"","value":_vm.currentTool},on:{"change":function($event){return _vm.setCurrentTool($event)}}},[_c('div',{staticClass:"my-1 tool-separator"}),_c('groupable-item',{attrs:{"value":_vm.Tools.WindowLevel},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('tool-button',{attrs:{"size":"40","icon":"mdi-circle-half-full","name":_vm.$t('toolStrip.windowLevel'),"buttonClass":['tool-btn', active ? 'tool-btn-selected' : ''],"disabled":_vm.noCurrentImage},on:{"click":toggle}})]}}])}),_c('groupable-item',{attrs:{"value":_vm.Tools.Pan},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('tool-button',{attrs:{"size":"40","icon":"mdi-cursor-move","name":_vm.$t('toolStrip.pan'),"buttonClass":['tool-btn', active ? 'tool-btn-selected' : ''],"disabled":_vm.noCurrentImage},on:{"click":toggle}})]}}])}),_c('groupable-item',{attrs:{"value":_vm.Tools.Zoom},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('tool-button',{attrs:{"size":"40","icon":"mdi-magnify-plus-outline","name":_vm.$t('toolStrip.zoom'),"buttonClass":['tool-btn', active ? 'tool-btn-selected' : ''],"disabled":_vm.noCurrentImage},on:{"click":toggle}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }