import macro from '@kitware/vtk.js/macro';

import vtkSliceRepresentationProxy from '@kitware/vtk.js/Proxy/Representations/SliceRepresentationProxy';
import vtkImageReslice from '@kitware/vtk.js/Imaging/Core/ImageReslice';
import { SlabMode } from '@kitware/vtk.js/Imaging/Core/ImageReslice/Constants';

import { getResliceCursor } from '../../composables/resliceCursorWidget';

function vtkMPRRepresentationProxy(publicAPI, model) {
  model.classHierarchy.push('vtkMPRRepresentationProxy');

  const superClass = { ...publicAPI };

  model.reslice = vtkImageReslice.newInstance({
    slabMode: SlabMode.MEAN,
    slabNumberOfSlices: 1,
    transformInputSampling: false,
    autoCropOutput: true,
    outputDimensionality: 2,
  });
  model.mapper.setInputConnection(model.reslice.getOutputPort());

  // Replace model.mapper by model.reslice
  model.sourceDependencies = [model.reslice];

  function updateResliceInternal(
    interactionContext = {
      viewType: '',
      reslice: null,
      actor: null,
      renderer: null,
      resetFocalPoint: false,
      keepFocalPointPosition: false,
      computeFocalPointOffset: false,
    }
  ) {
    const obj = getResliceCursor().updateReslicePlane(
      interactionContext.reslice,
      interactionContext.viewType
    );
    if (obj.modified) {
      // Get returned modified from setter to know if we have to render
      interactionContext.actor.setUserMatrix(
        interactionContext.reslice.getResliceAxes()
      );
    }
    getResliceCursor().updateCameraPoints(
      interactionContext.renderer,
      interactionContext.viewType,
      interactionContext.resetFocalPoint,
      interactionContext.keepFocalPointPosition,
      interactionContext.computeFocalPointOffset
    );
    model.renderer.getRenderWindow().render();
    return obj.modified;
  }

  publicAPI.updateReslice = (
    interactionContext = {
      resetFocalPoint: false,
      keepFocalPointPosition: false,
      computeFocalPointOffset: false,
    }
  ) => {
    updateResliceInternal({
      viewType: model.viewType,
      reslice: model.reslice,
      actor: model.actor,
      renderer: model.renderer,
      resetFocalPoint: interactionContext.resetFocalPoint,
      keepFocalPointPosition: interactionContext.keepFocalPointPosition,
      computeFocalPointOffset: interactionContext.computeFocalPointOffset,
    });
  };

  publicAPI.setResliceCursor = (viewWidget, viewType, renderer) => {
    // Needed to display the correct slice on each view proxy
    superClass.setSlicingMode('K');

    model.viewWidget = viewWidget;
    model.viewType = viewType;
    model.renderer = renderer;

    if (process.env.NODE_ENV === 'development') {
      // Expose MPRProxyRepresentation in debug console
      window.repProxy = window.repProxy || {};
      window.repProxy[model.viewType] = { model, publicAPI };
    }

    publicAPI.updateReslice({
      resetFocalPoint: true, // At first initialization, center the focal point to the image center
      keepFocalPointPosition: false, // Don't update the focal point as we already set it to the center of the image
      computeFocalPointOffset: true, // Allow to compute the current offset between display reslice center and display focal point
    });
    model.renderer.resetCamera(model.actor.getBounds());
    model.renderer.getRenderWindow().getInteractor().render();
  };

  publicAPI.attachEvents = () => {
    model.viewWidget.getViewWidgets().forEach((viewWidget) => {
      viewWidget.onInteractionEvent(
        ({ computeFocalPointOffset, canUpdateFocalPoint }) => {
          const activeViewType = getResliceCursor()
            .getWidgetState()
            .getActiveViewType();
          const keepFocalPointPosition =
            activeViewType !== model.viewType && canUpdateFocalPoint;
          publicAPI.updateReslice({
            resetFocalPoint: false,
            keepFocalPointPosition,
            computeFocalPointOffset,
          });
        }
      );
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  publicAPI.setSlice = (slice) => {};

  publicAPI.getSliceIndex = () => {};

  // don't set colors on slices
  publicAPI.setColorBy = () => {};

  // disable slicing mode as it should not be changed
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  publicAPI.setSlicingMode = (modeString) => {};
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  // Object methods
  vtkSliceRepresentationProxy.extend(publicAPI, model);

  // Object specific methods
  vtkMPRRepresentationProxy(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(
  extend,
  'vtkMPRRepresentationProxy'
);

// ----------------------------------------------------------------------------

export default { newInstance, extend };
