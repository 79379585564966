<template svg>
  <svg
    version="1.1"
    id="Layer_3"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 200 200"
    style="enable-background: new 0 0 200 200"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          class="st0"
          d="M82.1,29.9L46.4,70.4L55,80.5l47-53.2H88C85.7,27.3,83.6,28.3,82.1,29.9z"
        />
        <path
          class="st0"
          d="M109.6,29.9L59.9,86.1l8.7,10.1l60.9-68.9h-14.1C113.2,27.3,111.1,28.3,109.6,29.9z"
        />
        <path
          class="st0"
          d="M157,27.3h-14c-2.2,0-4.4,1-5.9,2.6l-63.6,72l0,0l0,0l57.4,66.7c1.5,1.7,3.6,2.7,5.9,2.7h13.8L91,102
			L157,27.3z"
        />
        <path
          class="st1"
          d="M96.3,171.3l-49.9-57.9v20.2l30.2,35c1.5,1.7,3.6,2.7,5.9,2.7H96.3z"
        />
        <path
          class="st1"
          d="M123.5,171.3L46.4,81.8v20.2l57.4,66.6c1.5,1.7,3.6,2.7,5.9,2.7H123.5z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'kitware-logo',
};
</script>

<style scoped>
.st0 {
  fill: #3eae2b;
}
.st1 {
  fill: #0068c7;
}
</style>
