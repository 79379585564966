import { computed, ref, watch } from '@vue/composition-api';
import { storeToRefs } from 'pinia';

import { mat4, vec3 } from 'gl-matrix';

import { Nullable, Vector3 } from '@kitware/vtk.js/types';
import * as vtkMath from '@kitware/vtk.js/Common/Core/Math';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkPlane from '@kitware/vtk.js/Common/DataModel/Plane';
import vtkSTLWriter from '@kitware/vtk.js/IO/Geometry/STLWriter';
import { FormatTypes } from '@kitware/vtk.js/IO/Geometry/STLWriter/Constants';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import { ViewTypes } from '@kitware/vtk.js/Widgets/Core/WidgetManager/Constants';
import { getAssociatedLinesName, rotateVector, updateState } from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/helpers';
import { ResliceCursorPlanes } from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/state';

import { ViewProxyType } from '@/src/core/proxies';
import vtkPiecewiseFunctionProxy from '@kitware/vtk.js/Proxy/Core/PiecewiseFunctionProxy';

import { LPSAxis } from '@/src/types/lps';
import { Implant } from '@/src/types/implant';
import { Rod } from '@/src/types/rod';
import { Treatment, TreatmentStepType } from '@/src/types/treatment';
import { 
  WorkingViewTypes,
  CustomOutputGeometries,
  CustomCorrector,
  Correction,
  CustomCorrectorParams,
  IndexOrientation,
} from '@/src/types/corrector';


import { useModuleStore } from '@/src/store/modules';
import { useTreatmentStore } from '@/src/store/treatments';
import { useView3DStore } from '@/src/store/view3D';

import {
  hasCorrection,
  computeIndexOrientation,
  computeLinearShiftingVector,
  computeAngularShiftingVector,
  getCorrecteurGeometries,
  getAxisFromOrientation,
  getPossibleIndexOrientations,
} from '@/src/utils/correctors';
import { getResource, vtpReader } from '@/src/utils/resourceLoader';
import { isMaxillary } from '@/src/utils/teeth';


import vtkLPSView2DProxy from '@/src/vtk/LPSView2DProxy';
import vtkImplantFilter from '@/src/vtk/ImplantFilter';
import vtkTransformFilter from '@/src/vtk/TransformFilter';
import vtkScalarsFilter from '@/src/vtk/ScalarsFilter';
import vtkCustomResliceCursorWidgetState from '@/src/vtk/CustomResliceCursorWidget/state';
import vtkCustomResliceCursorContextRepresentation from '@/src/vtk/CustomResliceCursorWidget/customResliceCursorContextRepresentation';
import vtkLPSView3DProxy from '@/src/vtk/LPSView3DProxy';
import vtkMPRVolumeRepresentationProxy from '@/src/vtk/MPRVolumeRepresentationProxy';

import { 
  isGenericImplant,
  isGenericManufacturerOrModel
} from '@/src/utils/implants';
import { getToothBase } from '@/src/utils/toothBase';
import { getViewType } from '@/src/utils/view';
import vtkMatrixBuilder from '@kitware/vtk.js/Common/Core/MatrixBuilder';
import vtkPolyDataNormals from '@kitware/vtk.js/Filters/Core/PolyDataNormals';
import { useView2DStore } from '../store/view2D';

import {
  getResliceCursor,
  getResliceCursorViewWidgets,
  resetResliceCursorState,
  updateViewResliceCursorFilter,
  getResliceCursorViewWidget,
} from './resliceCursorWidget';
import { useViewProxy } from './useViewProxy';
import { getAppendedCorrector, getCorrectorReference } from './correctors';

import { useCurrentImage } from "./useCurrentImage";
import { useViewConfigStore } from '../store/view-configs';
import { downloadFile } from '../io/state-file/utils';


const ROD_FILES = {
  standard: 'rods/NUVA_NVTZ1mm_clean.vtp',
  large: 'rods/NUVA_NVTZ2mm_clean.vtp',
};

interface Actors3D {
  rod?: vtkActor;
  implant?: vtkActor;
  corrector?: vtkActor;
}

const actors3D = {} as Record<number, Actors3D>;

// --- 3D display --- //

function get3dViewProxy() {
  const { viewProxy } = useViewProxy<vtkLPSView3DProxy>(
    ref<string>('3D'),
    ViewProxyType.Volume
  );
  return viewProxy.value;
}

function get3dRepProxy() {
  const viewProxy = get3dViewProxy();
  return viewProxy.getRepresentations()[0] as vtkMPRVolumeRepresentationProxy;
}

function refresh3DActorsOpacity() {
  const treatmentStore = useTreatmentStore();
  const { selectedTooth } = treatmentStore;

  Object.entries(actors3D).forEach(([tooth, actors]) => {
    Object.values(actors).forEach((actor: vtkActor) => {
      actor
        .getProperty()
        .setOpacity(Number(tooth) === selectedTooth ? 1 : 0.5);
    });
  });
}

function addOrUpdate3DMesh(
  step: TreatmentStepType | 'corrector',
  tooth: number,
  polydata: vtkPolyData,
  resetCamera: boolean
) {
  const rep = get3dRepProxy();
  if (!actors3D[tooth] || !actors3D[tooth][step]) {
    actors3D[tooth] = actors3D[tooth] || {};
    actors3D[tooth][step] = rep.addActor();
  }
  const actor = actors3D[tooth][step]!;
  rep.updateActor(actor, polydata);
  const actorColor: [r: number, g: number, b: number] = step === 'rod' ? [1, 0.5, 0] : [0, 1, 1];
  actor.getProperty().setColor(...actorColor);

  if (resetCamera) {
    get3dViewProxy().resetCamera();
  }
  get3dViewProxy().render();
}

export async function addOrUpdateRod3D(rod: Rod, tooth: number) {
  if (rod) {
    const polydata: vtkPolyData = await getResource(
      `${rod.size}`,
      ROD_FILES[rod.size],
      vtpReader
    );

    addOrUpdate3DMesh(
      'rod',
      tooth,
      polydata,
      true
    );
  }
}

async function addOrUpdateImplant3D(implant: Implant, tooth: number, reset=true) {
  if (!implant.model || !implant.inputSource.transformFilter) {
    return;
  }
  const filter = vtkScalarsFilter.newInstance({
    color: [0, 255, 255],
    opacity: 255
  });

  filter.setInputConnection(implant.inputSource.transformFilter.getOutputPort());
  filter.update();

  addOrUpdate3DMesh(
    'implant',
    tooth,
    filter.getOutputData(),
    reset // Only resetCamera if an implant is added and not if it is updated
  );
}

// -- Custom Correction -- //
function addOrUpdateCustomCorrector3D(corrector: CustomCorrector, toothId: number, reset=false) {
  if (!corrector || corrector.type !== 'Custom' || !corrector.appendedMesh) {
    return;
  }

  addOrUpdate3DMesh(
    'corrector',
    toothId,
    corrector.appendedMesh,
    reset
  );
}

export function updateInactiveImplantsVisibility(): void {
  const view3DStore = useView3DStore();
  const { inactiveImplantsVisibility } = storeToRefs(view3DStore);

  const treatmentStore = useTreatmentStore();
  const { selectedTooth } = treatmentStore;

  if (inactiveImplantsVisibility.value) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(actors3D).forEach(([toothId, actors]) => {
      Object.values(actors).forEach((actor: vtkActor) => {
        actor.setVisibility(true);
      });
    });
  } else {
    Object.entries(actors3D).forEach(([toothId, actors]) => {
      Object.values(actors).forEach((actor: vtkActor) => {
        actor.setVisibility(Number(toothId) === selectedTooth);
      });
    });
  }
  
  get3dViewProxy().render();
}

function clip3DVolume(clippingPlaneOrigin: Vector3, clippingPlaneNormal: Vector3): void {
  const viewRep = get3dRepProxy();

  // Remove current clipping plane
  viewRep.getMapper().removeAllClippingPlanes();;

  const clippingPlane = vtkPlane.newInstance();
  clippingPlane.setOrigin(clippingPlaneOrigin);
  clippingPlane.setNormal(clippingPlaneNormal);
  viewRep.getMapper().addClippingPlane(clippingPlane);
}

function getClippingPlaneParameters(toothId: number, directionVector: Vector3)
: Nullable<{
  origin:Vector3;
  normal: Vector3;
  viewUp: Vector3;
}> {
  const currentTreatment = useTreatmentStore().treatments[toothId];
  let origin: Nullable<Vector3> = null;
  let planes: Nullable<ResliceCursorPlanes> = null;

  if (currentTreatment.implant && currentTreatment.implant.model) {
    origin = currentTreatment.implant.origin as Vector3;
    planes = currentTreatment.implant.planes;
  } else if (currentTreatment.rod) {
    origin = currentTreatment.rod.origin as Vector3;
    planes = currentTreatment.rod.planes;
  }

  if (!origin || !planes) {
    return null;
  }

  // Get axis with normal along the given direction vector
  const planeTypes = [ViewTypes.YZ_PLANE, ViewTypes.XZ_PLANE, ViewTypes.XY_PLANE] as const;
  const foundPlaneType = planeTypes.map((planeType) => {
    const projection: Vector3 = [0, 0, 0];
    const planeNormal = planes![planeType].normal;
    vtkMath.projectVector(
      planeNormal as Vector3,
      directionVector,
      projection
    );
    return vtkMath.norm(projection, 3);
  }).reduce((previousIndex, currentValue, currentIndex, array) => {
    return array[previousIndex] < currentValue ? currentIndex : previousIndex;
  }, 0);

  if (foundPlaneType != null) {
    return {
      origin,
      normal: planes![planeTypes[foundPlaneType]].normal as Vector3,
      viewUp: planes![planeTypes[foundPlaneType]].viewUp as Vector3,
    }
  }

  return null;
}

/**
 * Clip 3D volume to plane with normal along the 
 * vestibular - lingual / palatal direction
 */
function updateClippingPlaneToFrontal(toothId: number) {
  const viewConfigStore = useViewConfigStore(); 
  const { currentImageData, currentImageID } = useCurrentImage();

  const toothBase = getToothBase(toothId);
  if (!toothBase || !toothBase.vestibular) {
    return;
  }
  const clippingPlaneParams = getClippingPlaneParameters(
    toothId,
    toothBase.vestibular
  );

  if (clippingPlaneParams) {
    vtkMath.multiplyScalar(clippingPlaneParams.normal, -1);
    clip3DVolume(clippingPlaneParams.origin, clippingPlaneParams.normal);

    // Update LUT
    if (currentImageID.value && currentImageData.value) {
      const imageDataRange = currentImageData.value.getPointData().getScalars().getRange();
      viewConfigStore.updateVolumeOpacityFunction(
        '3D',
        currentImageID.value,
        {
          mode: vtkPiecewiseFunctionProxy.Mode.Gaussians,
          gaussians: [{
            height: 1,
            position: 0.5,
            width: 0.5,
            xBias: 0.5,
            yBias: 0.5,
          }],
          mappingRange: imageDataRange,
        }
      );

      viewConfigStore.updateVolumeColorTransferFunction(
        '3D',
        currentImageID.value,
        {
          preset: 'Accurator-1',
          mappingRange: imageDataRange,
        }
      );
    }

    // Move camera to look at the cut
    const viewProxy = get3dViewProxy();
    const cameraToRCOriginVector: Vector3 = [0, 0, 0];
    vtkMath.subtract(
      clippingPlaneParams.origin,
      viewProxy.getCamera().getPosition(),
      cameraToRCOriginVector
    );
    const distance = vtkMath.norm(cameraToRCOriginVector, 3);
    const newPosition: Vector3 = [0, 0, 0];
    vtkMath.multiplyAccumulate(
      clippingPlaneParams.origin,
      vtkMath.multiplyScalar(clippingPlaneParams.normal, -1),
      distance,
      newPosition
    );

    viewProxy.moveCamera(
      clippingPlaneParams.origin,
      newPosition,
      clippingPlaneParams.viewUp,
      20
    );
  }
}

/**
 * Clip 3D volume using axial plane
 */
function updateClippingPlaneToLongitudinal(toothId: number) {
  const viewConfigStore = useViewConfigStore(); 
  const { currentImageData, currentImageID } = useCurrentImage();

  const currentTreatment = useTreatmentStore().treatments[toothId];
  let origin: Nullable<Vector3> = null;
  let planes: Nullable<ResliceCursorPlanes> = null;

  if (!currentTreatment.rod) {
    return;
  }
  origin = currentTreatment.rod!.origin as Vector3;
  planes = currentTreatment.rod!.planes;

  const axialPlane = planes[ViewTypes.XY_PLANE];
  const normal: Vector3 = [...axialPlane.normal] as Vector3;

  clip3DVolume(origin, vtkMath.multiplyScalar(normal, -1));

  // Update LUT
  if (currentImageID.value && currentImageData.value) {
    // Update OpacityTransferFunction
    const scalarRange = currentImageData.value.getPointData().getScalars().getRange();
    viewConfigStore.updateVolumeOpacityFunction(
      '3D',
      currentImageID.value,
      {
        mode: vtkPiecewiseFunctionProxy.Mode.Gaussians,
        gaussians: [{
          height: 1,
          position: 0.68,
          width: 0.32,
          xBias: 0.5802005012531328,
          yBias: 1.40,
        }],
        mappingRange: [...scalarRange],
      }
    );
    viewConfigStore.updateVolumeColorTransferFunction(
      '3D',
      currentImageID.value,
      {
        preset: 'Accurator-2',
        mappingRange: [...scalarRange],
      }
    );
  }

  // Move camera to look at the cut tooth from bottom
  const viewProxy = get3dViewProxy();
  const newViewUp: Vector3 = [...axialPlane.viewUp as Vector3];
  
  const cameraToRCOriginVector: Vector3 = [0, 0, 0];
  vtkMath.subtract(
    origin,
    viewProxy.getCamera().getPosition(),
    cameraToRCOriginVector
  );
  const distance = vtkMath.norm(cameraToRCOriginVector, 3);
  const newPosition: Vector3 = [0, 0, 0];
  vtkMath.multiplyAccumulate(
    origin,
    vtkMath.multiplyScalar(normal as Vector3, -1),
    distance,
    newPosition
  );
  viewProxy.moveCamera(
    origin,
    newPosition,
    newViewUp,
    20
  );
}

export function updateClippingPlane(): void {
  const viewConfigStore = useViewConfigStore();
  const treatmentStore = useTreatmentStore();
  const { selectedTooth } = treatmentStore;

  const { currentImageData, currentImageID } = useCurrentImage();
  if (selectedTooth === null) {
    return;
  }

  const viewRep = get3dRepProxy();

  // Remove current clipping plane
  // and reset 3D representation
  viewRep.getMapper().removeAllClippingPlanes();

  const { clippingType } = storeToRefs(useView3DStore());

  if (clippingType.value === 'None') {
    if (currentImageID.value && currentImageData) {
      if (viewConfigStore.getVolumeColorConfig('3D', currentImageID.value)?.transferFunction.preset !== 'CT-Bone') {
        viewConfigStore.setVolumeColorPreset('3D', currentImageID.value, 'CT-Bone');
        viewConfigStore.updateVolumeOpacityFunction(
          '3D',
          currentImageID.value,
          {
            mode: vtkPiecewiseFunctionProxy.Mode.Points,
            shift: 1,
          }
        );
      }
    }
  } else if (clippingType.value === 'Frontal') {
    updateClippingPlaneToFrontal(selectedTooth);
  } else if (clippingType.value === 'Longitudinal') {
    updateClippingPlaneToLongitudinal(selectedTooth);
  }

  // Update view
  get3dViewProxy().render();
}

function getUserMatrix(
  planes: ResliceCursorPlanes,
  origin: vec3
): mat4 {
  return [
    ...planes[ViewTypes.XZ_PLANE].normal,
    0,
    ...planes[ViewTypes.YZ_PLANE].normal,
    0,
    ...planes[ViewTypes.XY_PLANE].normal,
    0,
    ...origin,
    1,
  ] as mat4;
}

function setImplantPositionIn3D(
  step: 'implant' | 'rod' | 'corrector',
  toothId: number,
  planes: ResliceCursorPlanes,
  origin: vec3
) {
  const actors = actors3D[toothId];
  let updated = false;
  if (actors && actors[step] !== null && actors[step] !== undefined) {
    if (planes && origin) {
      const userMatrix = getUserMatrix(planes, origin);

      updated = true;
      actors[step]?.setUserMatrix(userMatrix);
    }
  }
  return updated;
}

export function moveMeshesIn3DView(toothId: number) {
  const treatmentStore = useTreatmentStore();
  const currentTreatment = treatmentStore.treatments[toothId];

  let updated: boolean = false;

  if (currentTreatment) {
    const actors = actors3D[toothId];
    if (actors) {
      let planes = null;
      let origin = null;

      // Update rod position
      if (currentTreatment.rod) {
        planes = currentTreatment.rod.planes;
        origin = currentTreatment.rod.origin;
        if (planes && origin) {
          if (actors.rod !== null && actors.rod !== undefined) {
            updated = setImplantPositionIn3D('rod', toothId, planes, origin!) || updated;
          }
          if (actors.corrector !== null && actors.corrector !== undefined) {
            updated = setImplantPositionIn3D('corrector', toothId, planes, origin!) || updated;
          }
        }
      }

      if (currentTreatment.implant) {
        // Update implant and corrector positions
        planes = currentTreatment.implant.planes;
        origin = currentTreatment.implant.origin;
        if (planes && origin) {
          if (actors.implant !== null && actors.implant !== undefined) {
            updated = setImplantPositionIn3D('implant', toothId, planes, origin!) || updated;
          }
        }
      }
    }
  }

  if (updated) {
    const viewProxy = get3dViewProxy();
    viewProxy.getRenderer().resetCameraClippingRange();
    viewProxy.render();
  }

  updateClippingPlane();
}

export function removeTooth3DActors(toothId: number | null) {
  if (toothId === null) {
    return;
  }

  const treatmentStore = useTreatmentStore();
  const { treatments } = treatmentStore;
  if (!treatments[toothId] && actors3D[toothId]) {
    const rep = get3dRepProxy();
    let updated = false;
    if (actors3D[toothId]) {
      (['rod', 'implant', 'corrector'] as const).forEach((step) => {
        if (actors3D[toothId][step]) {
          rep.removeActor(actors3D[toothId][step]!);
          updated = true;
        }
      });

      delete actors3D[toothId];
    }
    if (updated) {
      get3dViewProxy().resetCamera();
      get3dViewProxy().render();
    }
  }
}

export function removeToothCustomCorrector3DActor(toothId: number) {
  if (actors3D[toothId] && actors3D[toothId].corrector) {
    const rep = get3dRepProxy();
    rep.removeActor(actors3D[toothId].corrector!);
    delete actors3D[toothId].corrector;
    get3dViewProxy().render();
  }
}

// --- 2D display --- //
function updateResliceCursorFilter(reset=true) {
  const sliceViewIDs: LPSAxis[] = ['Axial', 'Coronal', 'Sagittal'];
  sliceViewIDs.forEach((viewID) => {
    const { viewProxy } = useViewProxy<vtkLPSView2DProxy>(
      ref<string>(viewID),
      ViewProxyType.Slice
    );

    updateViewResliceCursorFilter(viewProxy.value, viewID, reset);
  });
}

function configureResliceCursor(step: TreatmentStepType) {
  const widgetState = getResliceCursor().getWidgetState();
  // Update center actor opacity
  widgetState.setOpacity(step === 'rod' ? 0.5 : 1);
  // Set axes actors opacity
  widgetState.setAxisOpacity(0.5);
  
  widgetState.setEnableRotation(step === 'rod');
  widgetState.setShowAxis(step === 'rod');
  widgetState.setTranslateAlongOneAxis(step === 'implant');
  widgetState.setForceAxisDisplayInAxial(step === 'implant');
  widgetState.setDisableAxialInteractions(step === 'implant');
}

export function configureResliceCursorForRods() {
  configureResliceCursor('rod');
}

function configureResliceCursorForImplants() {
  configureResliceCursor('implant');
}

function updateResliceCursor(
  information: {
    origin: Nullable<vec3>;
    planes: Nullable<ResliceCursorPlanes>
  },
  reset=true
) {
  if (information.origin && information.planes) {
    const state = getResliceCursor().getWidgetState();
    const modifiedCenter = state.setCenter([...information.origin] as Vector3);
    const modifiedPlanes = state.setPlanes(structuredClone(information.planes));
    if (modifiedCenter || modifiedPlanes) {
      updateState(state);
      updateResliceCursorFilter(reset);
    }
  } else {
    resetResliceCursorState();
  }
}

// Display the rod model
async function displayRod(rod: Rod, resetCamera=true) {
  const rodPolyData = await getResource(
    rod.size,
    ROD_FILES[rod.size],
    vtpReader
  );

  // Add scalars
  const rodScalarsFilter = vtkScalarsFilter.newInstance({
    color: [255, 127, 0],
    opacity: 255,
  });

  rodScalarsFilter.setInputData(rodPolyData);

  configureResliceCursorForRods();
  updateResliceCursor({
    origin: rod.origin,
    planes: rod.planes
  }, resetCamera);

  getResliceCursorViewWidgets().forEach((viewWidget) => {
    const representation = viewWidget.getRepresentations()[0];
    // @ts-ignore (representation is always a vtkCustomResliceCursorContextRepresentation)
    representation.getCenterMapper().setInputData(rodScalarsFilter.getOutputData());
    representation.setVisibility(true);
    // @ts-ignore: TODO add `updateActorVisibility` to vtkWidgetRepresentation
    representation.updateActorVisibility();
    viewWidget.getInteractor().render();
  });
}

export async function getImplantPolydata(implantReference: string, implantModelPath: string) {
  return getResource(
    `${implantReference}_flipped`, // We can be sure model is not null (see display function)
    implantModelPath,
    vtpReader
  );
}

// Display the implant model
async function displayImplant(implant: Implant, resetCamera=false) {
  const view2DStore = useView2DStore();
  const { isLoading } = storeToRefs(view2DStore);
  isLoading.value = true;
  const implantPolydata = await getImplantPolydata(implant.model!.reference, implant.model!.path)

  // Get marker polydata and add a scalars to display it in yellow
  const markerPolydata = await getResource(
    'marker_flipped',
    'marker_scaled.vtp',
    vtpReader
  );

  configureResliceCursorForImplants();
  updateResliceCursor({
    origin: implant.origin,
    planes: implant.planes,
  }, resetCamera);

  const treatmentStore = useTreatmentStore();
  const { currentImplant, selectedTooth } = storeToRefs(treatmentStore);
  if (currentImplant.value === null) {
    return;
  }

  const transformFilter = vtkTransformFilter.newInstance();
  transformFilter.setInputData(implantPolydata);
  if (implant.model && isGenericImplant(implant.model.manufacturer, implant.model.model)) {
    transformFilter.setDiameter(implant.model.diameter);
    transformFilter.setLength(implant.model.length);
  }
  const output = transformFilter.getOutputData();

  currentImplant.value.inputSource.transformFilter = transformFilter;

  // Update 2D
  getResliceCursorViewWidgets().forEach((viewWidget) => {
    if (currentImplant.value) {
      const representation = viewWidget.getRepresentations()[0];
      
      // @ts-ignore: representation is a vtkCustomResliceCursorContextRepresentation
      const viewType = representation.getViewType() as ViewTypes;
      const isAxialView = viewType === ViewTypes.XY_PLANE;
      
      const implantFilter = vtkImplantFilter.newInstance({
        viewNormal: isAxialView ? [0, 0, 1] : [0, 1, 0],
        implantNbSlices: isAxialView ? 1 : 45,
        implantOpacity: isAxialView ? 255 : 50,
      });
  
      implantFilter.setInputData(output, 0);
      implantFilter.setInputData(markerPolydata, 1);

      currentImplant.value.inputSource.views2D[viewType] = implantFilter;

      // @ts-ignore: representation is a vtkCustomResliceCursorContextRepresentation
      representation.getCenterMapper().setInputConnection(implantFilter.getOutputPort());

      // @ts-ignore: TODO check why extended type definition does not work in this case
      // setVisibility is inherited from vtkProp (where it is defined).
      representation.setVisibility(true);
      
      // @ts-ignore: TODO add `updateActorVisibility` to vtkWidgetRepresentation index.d.ts
      representation.updateActorVisibility();

      viewWidget.getInteractor().render();
    }
  });

  // update 3D
  addOrUpdateImplant3D(
    currentImplant.value,
    selectedTooth.value!,
    resetCamera
  ).then(() => {
    moveMeshesIn3DView(selectedTooth.value!);
    isLoading.value = false;
  });
}

function hideResliceCursor(resetCamera: boolean) {
  getResliceCursorViewWidgets().forEach((viewWidget) => {
    const representation = viewWidget.getRepresentations()[0];
    representation.setVisibility(false);
    viewWidget.getInteractor().render();
    if (resetCamera) {
      resetResliceCursorState();
      updateResliceCursorFilter();
    }
  });
}

async function display(
  treatment: Nullable<Treatment>,
  selectedModuleIndex: number,
  resetCamera = false
) {
  if (treatment === null) {
    // No active tooth
    hideResliceCursor(resetCamera);
  } else if (selectedModuleIndex === 1 && treatment.rod) {
    // RodTab + active tooth
    await displayRod(treatment.rod, resetCamera);
  } else if (selectedModuleIndex === 2) {
    if (treatment.implant?.model) {
      // ImplantTab + active implant + selected model
      await displayImplant(treatment.implant, resetCamera);
      if (treatment.correction?.corrector?.type === 'Custom') {
        addOrUpdateCustomCorrector3D(
          treatment.correction.corrector,
          useTreatmentStore().selectedTooth!,
        );
      }
    } else if (treatment.rod) {
      // ImplantTab + active implant + no selected model
      await displayRod(treatment.rod);
    }
  } else {
    // ImportTab or ExportTab
    hideResliceCursor(resetCamera);
  }

  updateInactiveImplantsVisibility();
}

// --- Index --- //

export function setIndexVisibility(visibility: boolean): void {
  const viewWidget = getResliceCursorViewWidget('Axial');
  let modified = false;
  if (viewWidget) {
    const representation = viewWidget.getRepresentations()[0] as unknown as vtkCustomResliceCursorContextRepresentation;
    if (representation) {
      modified = representation.setIndexVisibility(visibility);
      if (modified) {
        viewWidget.getInteractor().render();
      }
    }
  }
}

function getShiftingVector(treatment: Treatment): Nullable<Vector3> {
  if (!treatment || !treatment.rod || !treatment.implant) {
    return null;
  }

  // Priority to the linear correction
  // translation along the axial plane representation in Coronal and Sagittal views
  // (i.e. translation along the normal of the 'non-axial' plane in views)
  const corrector = treatment.correction?.corrector;
  if (!corrector) {
    return null;
  }
  if (corrector.translations.Coronal !== 0 || corrector.translations.Sagittal !== 0) {
    return computeLinearShiftingVector(
      treatment.implant.origin as Vector3,
      treatment.rod.origin as Vector3,
      treatment.implant.planes![getViewType("Axial")].normal as Vector3,
    );
  }

  if (corrector.rotations.Coronal !== 0 || corrector.rotations.Sagittal !== 0) {
    return computeAngularShiftingVector(treatment.implant.planes!, treatment.rod.planes);
  }

  return null;
}

/**
 * Update index visibility / position on resliceCursor representation
 */
export function updateIndex(): void {
  const treatmentStore = useTreatmentStore();
  const {selectedTooth} = treatmentStore;
  if (selectedTooth === null) {
    setIndexVisibility(false);
    return;
  }
  const treatment: Treatment = treatmentStore.treatments[selectedTooth];

  if (!treatment.correction || treatment.correction === null) {
    setIndexVisibility(false);
    return;
  }

  if (!hasCorrection(treatment.correction)) {
    // case there is no correction applied
    treatment.correction.reference = null;
    treatment.correction.indexOrientation = null;
 
    setIndexVisibility(false);
    return;
  }

  let orientation = null;

  if (treatment.correction.corrector.type === 'Classic') {
    const vector: Nullable<Vector3> = getShiftingVector(treatment);
    const toothBase = getToothBase(selectedTooth);
    if (!toothBase || !toothBase.distal || !toothBase.vestibular) {
      return;
    }
  
    orientation = computeIndexOrientation(
      selectedTooth,
      vector,
      toothBase.distal as Vector3,
      toothBase.vestibular as Vector3,
    );
  
    treatment.correction.indexOrientation = orientation;
  } else {
    orientation = treatment.correction.indexOrientation;
  }

  // update implant reference as it depends on index orientation
  treatment.correction.reference = getCorrectorReference(
    treatment.rod,
    treatment.implant,
    treatment.correction.corrector
  );

  const viewWidget = getResliceCursorViewWidget('Axial');
  if (viewWidget) {
    const representation = viewWidget.getRepresentations()[0] as unknown as vtkCustomResliceCursorContextRepresentation;
    representation.setIndexVector(getAxisFromOrientation(selectedTooth, orientation!)!);
    representation.setIndexVisibility(true);
    viewWidget.getInteractor().render();
  }
}

// -- Drilling -- //
/**
 * In sagittal (profil) view an implant can be translated along one axis. This is the drilling.
 * This method computes and returns the axis of translation.
 * In sagittal view, it corresponds to the axis1.
 */
function getDrillingAxis(): Nullable<Vector3> {
  const viewWidget = getResliceCursorViewWidget('Sagittal');
  if (viewWidget) {
    const widgetState = viewWidget.getWidgetState();

    const representation = viewWidget.getRepresentations()[0] as unknown as vtkCustomResliceCursorContextRepresentation;
    if (representation) {

      const axis1Name = representation.getAxis1Name();
      // @ts-ignore as viewWidget is a vtkCustomResliceCursorWidget
      const axisState = widgetState[`get${axis1Name}`]();

      const axisVector: Vector3 = [0, 0, 0];
      vtkMath.subtract(axisState.getPoint2(), axisState.getPoint1(), axisVector);
      // Need to invert axisVector to be consistent
      vtkMath.multiplyScalar(axisVector, -1);
      vtkMath.normalize(axisVector);

      return axisVector;
    }
  }

  return null;
}

/**
 * (Re)compute drilling value everytime implant is moved along its drilling axis.
 */
export function updateDrillingValue() {
  const treatmentStore = useTreatmentStore();
  const {
    currentCorrection,
    currentRod,
    currentImplant,
    selectedTooth,
  } = storeToRefs(treatmentStore);

  // Ensure that: 
  //  - currentRod origin is set
  //  - currentImplant origin is set
  //  - currentImplant model is selected
  // otherwise set drilling value to null

  let value: Nullable<number> = null;

  if (! (
    currentRod.value
    && currentRod.value.origin
    && currentImplant.value
    && currentImplant.value.origin
    && currentImplant.value.model
    && currentCorrection.value
  )) {
    return;
  } 
  
  if (selectedTooth.value) {
    // Return a positive value when going into the gum,
    // and a negative value when going away from it.
    // Value will depend on tooth position.
    const drillingAxis = getDrillingAxis();
    if (drillingAxis) {
      const drillingDirection: Vector3 = [0, 0, 0];
      vtkMath.subtract(
        getResliceCursor().getWidgetState().getCenter() as Vector3,
        currentRod.value.origin as Vector3,
        drillingDirection
      );

      const sign = isMaxillary(selectedTooth.value) ? 1 : -1;
      value = sign * vtkMath.dot(drillingDirection, drillingAxis);
    }
  }

  currentCorrection.value.drilling = value;
}

/**
 * Apply drilling value.
 * From the Reslice cursor widget origin, compute the new drilled origin.
 * The cursor widget origin should either be the '0 position' (i.e. rod position) center
 * or the '0 position' with an applied linear transform.
 * It should not be the implant origin.
 */
function applyDrilling(
  origin: Vector3,
  selectedTooth: number,
  drillingValue: Nullable<number>
): Nullable<Vector3> {
  if (selectedTooth && drillingValue !== null) {
    const drillingAxis = getDrillingAxis();
    if (drillingAxis) {
      // Drilling value is negative when going out from the gum and positive when going into it.
      const sign = isMaxillary(selectedTooth) ? 1 : -1;

      // Compute new origin
      const newOrigin: Vector3 = [0, 0, 0];
      vtkMath.multiplyAccumulate(
        origin, 
        drillingAxis,
        sign * drillingValue,
        newOrigin
      );

      return newOrigin;
    }
  }
  return null;
}

// -- Correction -- //
function getCorrecteurSourceYAxisInCurrentReferencial(rodPlanes: ResliceCursorPlanes, rodOrigin: Vector3): Vector3 {
  const matrix = getUserMatrix(rodPlanes, rodOrigin);

  const vector: vec3 = [0, 0, 0];
  vec3.transformMat4(vector, [0, 100000, 0], matrix);

  vtkMath.normalize(vector);

  return vector;
}

/**
 * Returns angle to apply to the custom corrector in case there is a linear
 * translation. Indeed, as the vtkCorrecteurSource defines a deport along the Y
 * axis, then it is necessary to rotate the corrector to be well positioned.
 */
export function rotateCustomCorrector(toothId: number): number {
  const treatment: Treatment = useTreatmentStore().treatments[toothId];

  const hasLinearTranslation = 
    treatment.correction?.corrector.translations.Sagittal !== 0
    || treatment.correction?.corrector.translations.Coronal !== 0;
  
  if (!treatment
    || !treatment.implant
    || !hasLinearTranslation
  ) {
    return 0;
  }

  // Everything is done in the axial plane (i.e. every vector has to be projected on this plane)
  const { planes } = treatment.implant;
  const axialPlaneNormal: Vector3 = [...planes![getViewType("Axial")].normal] as Vector3;

  // Invert axialPlaneNormal because of normal flipping for maxillary teeth
  if (isMaxillary(toothId)) {
    vtkMath.multiplyScalar(axialPlaneNormal, -1);
  }

  // Project shifting vector
  const shiftingVector: Nullable<Vector3> = getShiftingVector(treatment);
  if (!shiftingVector) {
    return 0;
  }
  const projected: Vector3 = [0, 0, 0];
  vtkPlane.projectVector(shiftingVector, axialPlaneNormal as Vector3, projected);
  
  const transformedY: vec3 = getCorrecteurSourceYAxisInCurrentReferencial(
    treatment.rod?.planes!,
    treatment.rod?.origin! as Vector3
  );
  const projectedY: Vector3 = [0, 0, 0];
  vtkPlane.projectVector(transformedY, axialPlaneNormal as Vector3, projectedY);
  vtkMath.normalize(projectedY);

  const radianAngle = vtkMath.signedAngleBetweenVectors(
    projectedY,
    projected,
    axialPlaneNormal
  );

  return vtkMath.degreesFromRadians(radianAngle);
}

/**
 * Compute params to define the custom corrector.
 * The vtkCorrecteurSource defines:
 *   - the corrector along the z axis
 *   - a deport along y axis
 *   - angle1 is the angle around the y axis
 *   - angle2 is the angle around the x axis
 *   - angle3 is the angle around the z axis
 */
function getCustomCorrectorParams(
  toothId: number,
  rotationAngle: number
): Nullable<CustomCorrectorParams> {
  const treatment = useTreatmentStore().treatments[toothId];
  if (!treatment) {
    return null;
  }

  const {rod, implant} = treatment;
  if (!rod || !rod.planes || !implant || !implant.planes) {
    return null;
  }

  const corrector = treatment.correction?.corrector;
  if (corrector?.type !== "Custom") {
    return null;
  }

  // Initialize parameters
  let deport = 0;
  let angle1 = 0;
  let angle2 = 0;

  let angle3: Nullable<number> = null; // not 0 as we are looking for the smaller angle to define index orientation
  let orientation: Nullable<IndexOrientation> = null;

  if (hasCorrection(treatment.correction)) {
    // Compute deport value
    if (corrector.translations.Sagittal !== 0 || corrector.translations.Coronal !== 0) {
      const shiftingVector = getShiftingVector(treatment);
      if (!shiftingVector) {
        return null;
      }
      deport = vtkMath.norm(shiftingVector, 3);
    }

    // ------ Compute angles values
    // angle1 corresponds to the angle between rod and implant Z axes around XZ_Plane normal.
    // angle2 corresponds to the angle between rod and implant Z axes around YZ_PLANE normal.

    // Axes to compute angle between
    const implantZAxis = [...implant.planes[ViewTypes.XY_PLANE].normal] as Vector3;
    const rodZAxis = [...rod.planes[ViewTypes.XY_PLANE].normal] as Vector3;
    const rodXAxis = [...rod.planes[ViewTypes.YZ_PLANE].normal] as Vector3;
    const rodYAxis = [...rod.planes[ViewTypes.XZ_PLANE].normal] as Vector3;

    // Invert axis of rotation for upper teeth (due to the flipped axial normal)
    if (isMaxillary(toothId)) {
      vtkMath.multiplyScalar(rodZAxis, -1);
      vtkMath.multiplyScalar(implantZAxis, -1);
      vtkMath.multiplyScalar(rodXAxis, -1);
      vtkMath.multiplyScalar(rodYAxis, -1);
    }
    
    // As corrector deport is only computed in one direction, corrector mesh is rotated.
    // It is necessary to rotate rodXAxis and rodYAxis to ensure we are working in the
    // right base to compute angle (rotation of rotationAngle degrees around rod Z axis).
    const matrix = vtkMatrixBuilder.buildFromDegree().rotate(rotationAngle, rodZAxis);
    matrix.apply(rodXAxis);
    matrix.apply(rodYAxis);

    // To compute angles, project vector on the right plane first.
    // rodZAxis is not projected as orthogonality is kept between rod planes
    // so projected_rodZAXis = rodZAxis
    const projectedZOnXPlane: Vector3 = [0, 0, 0];
    vtkPlane.projectVector(implantZAxis, rodYAxis, projectedZOnXPlane);

    angle1 = -1 * vtkMath.degreesFromRadians(
      vtkMath.signedAngleBetweenVectors(
        rodZAxis,
        projectedZOnXPlane,
        rodYAxis
      )
    );

    const projectedZOnYPlane : Vector3 = [0, 0, 0];
    vtkPlane.projectVector(implantZAxis, rodXAxis, projectedZOnYPlane);

    angle2 = 1 * vtkMath.degreesFromRadians(vtkMath.signedAngleBetweenVectors(
      rodZAxis,
      projectedZOnYPlane,
      rodXAxis
    ));
    
    // angle3 corresponds to the angle between the index direction and inverted deport
    // direction around XY_Plane normal.
    // It is used to set the index orientation. The index orientation corresponds to the
    // orientation with the smallest angle with the inverted deport direction
    getPossibleIndexOrientations(toothId).forEach((indexOrientation) => {
      // Get the index orientation direction
      const direction: Nullable<Vector3> = getAxisFromOrientation(toothId, indexOrientation);
      if (direction) {
        const projectedOnZPlane : Vector3 = [0, 0, 0];
        vtkPlane.projectVector(direction, rodZAxis, projectedOnZPlane);

        // Get the deport direction and invert it
        const yAxis: vec3 = getCorrecteurSourceYAxisInCurrentReferencial(
          treatment.rod?.planes!,
          treatment.rod?.origin! as Vector3
        );
        const projectedY: Vector3 = [0, 0, 0];
        vtkPlane.projectVector(yAxis, rodZAxis, projectedY);
        vtkMath.normalize(projectedY);
        vtkMath.multiplyScalar(projectedY, -1);
        matrix.apply(projectedY);

        // Compute angle between the index direction and the inverted deport direction
        const angle = vtkMath.degreesFromRadians(vtkMath.signedAngleBetweenVectors(
          projectedY,
          projectedOnZPlane,
          rodZAxis
        ));

        // Get the minimum angle
        if (angle3 === null || Math.abs(angle) < Math.abs(angle3)) {
          angle3 = angle;
          orientation = indexOrientation;
        }
      }
    });

    // set index orientation
    treatment.correction!.indexOrientation = orientation;
  }

  if (angle3 === null) {
    angle3 = 0;
  }

  return {
    deport,
    angle1,
    angle2,
    angle3,
  };
}

async function defineCustomCorrector(toothId: number) {
  const treatmentStore = useTreatmentStore();
  const currentTreatment: Treatment = treatmentStore.treatments[toothId];

  if (!currentTreatment || !currentTreatment.correction) {
    return;
  }
  const currentCorrection = ref<Correction>(currentTreatment.correction);

  if (
    currentTreatment.rod
    && currentCorrection
    && currentCorrection.value
    && currentCorrection.value.corrector.type === 'Custom'
  ) {

    const angle = rotateCustomCorrector(toothId);
    const params = getCustomCorrectorParams(toothId, angle);
    if (!params) {
      return;
    }

    currentCorrection.value.corrector.params = params;

    const geometries = await getCorrecteurGeometries(params);
    if (currentCorrection.value && currentCorrection.value.corrector.type === 'Custom') {
      // Define appended mesh
      const mesh = getAppendedCorrector(geometries as CustomOutputGeometries);
      
      const pointsData = mesh.getPoints().getData();
      // Re orient custom corrector in 3D view
      vtkMatrixBuilder
        .buildFromDegree()
        .rotateZ(angle)
        .apply(pointsData);

      mesh.getPoints().setData(pointsData, 3);

      // Recompute normals 
      const normalsFilter = vtkPolyDataNormals.newInstance();
      normalsFilter.setInputData(mesh);
      normalsFilter.update();

      if (isMaxillary(toothId)) {
        // Invert normals for maxillary teeth due to the flipped axis
        const normalsData = normalsFilter.getOutputData().getPointData().getNormals().getData();
        normalsFilter
          .getOutputData()
          .getPointData()
          .getNormals()
          .setData(normalsData.map((value: number) => value * -1));
      }
      currentCorrection.value.corrector.appendedMesh = mesh;
    }
  }
}

export function updateCustomCorrector(corrector: CustomCorrector, toothId: number) {
  return defineCustomCorrector(toothId).then(() => {
    updateIndex();
    addOrUpdateCustomCorrector3D(corrector, toothId, false);
    moveMeshesIn3DView(toothId);
  });
}

export async function downloadCustomCorrector(toothId: number) {
  const {treatments} = useTreatmentStore();
  const currentTreatment = treatments[toothId];
  if (currentTreatment) {
    const {correction} = currentTreatment;
    if (!correction || correction.corrector.type !== "Custom") {
      return;
    }

    const corrector: CustomCorrector = currentTreatment.correction?.corrector! as CustomCorrector;
    if (corrector.appendedMesh && corrector.params) {      
      // It is necessary to invert angles as Y axis is not oriented the same way
      // Do not apply userMatrix anymore as we want to stay axes aligned
      const downloadParams = {...corrector.params};
      downloadParams.angle2 = (isMaxillary(toothId) ? -1 : 1) * corrector.params.angle2!;
      downloadParams.angle3 = (isMaxillary(toothId) ? -1 : 1) * corrector.params.angle3!;

      const geometries = await getCorrecteurGeometries(downloadParams);
      const mesh = getAppendedCorrector(geometries as CustomOutputGeometries);

      const writer = vtkSTLWriter.newInstance({
        format: FormatTypes.ASCII,
      });
      writer.setInputData(mesh);

      const content = writer.getOutputData();
      const blob = new Blob([content], { type: 'application/octet-steam' });
      downloadFile(window.URL.createObjectURL(blob), 'custom_corrector.stl');
    }
  }
}

function translateImplant(origin: Vector3, planes: ResliceCursorPlanes, type: WorkingViewTypes, translation: number): Vector3 {
  // Translate implant along the axial axis representation in views (blue line)

  // In Coronal view, translate implant along the Sagittal normal
  // In Sagittal view, translate implant along the Coronal normal
  const planeType = getViewType(type === "Sagittal" ? "Coronal": "Sagittal");
  const translationNormal = planes[planeType].normal as Vector3;

  // Need to multiply by -1 to ensure a positive translation value moves implant to the right, and a negative to the left
  const sign = type === "Sagittal" ? -1 : 1;
  const currentOrigin = [...origin] as Vector3;
  return vtkMath.multiplyAccumulate(
    currentOrigin,
    translationNormal,
    sign * translation,
    [0, 0, 0]
  );
}

function rotateImplant(selectedTooth: number, type: WorkingViewTypes, angle: number): Nullable<ResliceCursorPlanes> {
  const viewWidget = getResliceCursorViewWidget(type);

  if (viewWidget !== undefined) {
    const widgetState = viewWidget.getWidgetState() as vtkCustomResliceCursorWidgetState;
    const radianAngle = vtkMath.radiansFromDegrees(angle);
    const representation = viewWidget.getRepresentations()[0] as unknown as vtkCustomResliceCursorContextRepresentation;
    const axis2Name = representation.getAxis2Name();

    // Do not use directly viewWidget.rotateLineInView() method
    // because it causes the rotation of both rod and implant.
    // Redo all the normal and viewup computations here (using vtk.js code)
    // to avoid this unwanted behavior.
    const planes = widgetState.getPlanes();

    // @ts-ignore as viewWidget is a vtkCustomResliceCursorWidget
    const axisState = widgetState[`get${axis2Name}`]();

    // Get "inview" information
    const inViewType = axisState.getInViewType();
    // @ts-ignore
    const inViewTypeNormal: Vector3 = [...planes[inViewType].normal] as Vector3;

    // Invert axis of rotation for upper teeth (due to the flipped axial normal)
    if (isMaxillary(selectedTooth)) {
      vtkMath.multiplyScalar(inViewTypeNormal, -1);
    }

    // Compute new normal and viewUp for the axis2
    const viewType = axisState.getViewType();

    // @ts-ignore
    // const viewTypeInformation = structuredClone(planes[viewType]);
    const viewTypeInformation = structuredClone(planes[viewType]);
    const newNormal = rotateVector(viewTypeInformation.normal, inViewTypeNormal, radianAngle);
    const newViewUp = rotateVector(viewTypeInformation.viewUp, inViewTypeNormal, radianAngle);

    // Keep orthogonality between axes and compute the axis1 normal and viewUp
    const associatedLineName = getAssociatedLinesName(axisState.getName());
    // @ts-ignore as viewWidget is a vtkCustomResliceCursorWidget
    const associatedLine = widgetState[`get${associatedLineName}`]();
    const associatedViewType = associatedLine.getViewType();

    // @ts-ignore
    const associatedViewTypeInformation = structuredClone(planes[associatedViewType]);
    const newAssociatedNormal = rotateVector(associatedViewTypeInformation.normal, inViewTypeNormal, radianAngle);
    const newAssociatedViewUp = rotateVector(associatedViewTypeInformation.viewUp, inViewTypeNormal, radianAngle);

    // Define newPlanes for the implant
    const newPlanes = { ...planes, [viewType]: {
      normal: newNormal,
      viewUp: newViewUp,
    }, [associatedViewType]: {
      normal: newAssociatedNormal,
      viewUp: newAssociatedViewUp,
    }} as ResliceCursorPlanes;

    return newPlanes;
  }

  return null;
}

export function applyCorrection() {
  const treatmentStore = useTreatmentStore();
  const { selectedTooth } = treatmentStore;

  if (!selectedTooth) {
    return;
  }

  const treatment = treatmentStore.treatments[selectedTooth];
  if (!treatment || !treatment.rod || !treatment.correction || !treatment.implant) {
    return;
  }

  const { corrector } = treatment.correction;
  if (!corrector) {
    return;
  }

  // Reset implant position / planes to rod's position / planes visually.
  // Rotation and translation have to be computed from the rod position.
  // It is considered as the "0 position".
  // It is not necessary to update the store now. It will be 
  // updated once new planes and origin are computed.
  let planes: ResliceCursorPlanes = structuredClone(treatment.rod.planes!);
  let origin: Vector3 = [...treatment.rod.origin!] as Vector3;

  const resetCamera = false; // Do not reset view when applying correction
  updateResliceCursor({
    planes,
    origin: origin as Vector3,
  }, resetCamera);

  // Update reslice cursor planes

  // Apply rotation.
  (['Sagittal', 'Coronal'] as const).forEach((viewType) => {
    if (corrector.rotations[viewType]!== 0) {
      const newPlanes = rotateImplant(selectedTooth, viewType, corrector.rotations[viewType]);
      if (newPlanes !== null) {
        planes = newPlanes;
  
        // Necessary to update reslice cursor planes (and by consequence its representation)
        // to have the right axes to compute translation and drilling.
        updateResliceCursor({
          planes,
          origin: origin as Vector3
        }, resetCamera);
      }
    }
  });

  // Update reslice cursor origin
  // Apply drilling
  const newOrigin = applyDrilling(origin, selectedTooth, treatment.correction.drilling);
  if (newOrigin !== null) {
    origin = newOrigin;
  }

  // Apply translation
  (['Sagittal', 'Coronal'] as const).forEach((viewType) => {
    if (corrector.translations[viewType]!== 0) {
      origin = translateImplant(origin, planes, viewType, corrector.translations[viewType]);
    }
  });

  // Finally update reslice cursor with the new origin
  updateResliceCursor({
    planes,
    origin
  }, resetCamera);
  
  // Synchronize with the store:
  treatment.implant.planes = structuredClone(planes);
  treatment.implant.origin = [...origin];

  moveMeshesIn3DView(selectedTooth);
  updateIndex();
  updateDrillingValue();
}

// -- 3D view cursor position -- //

export function set3DCursorPosition(
  worldPosition: [x: number, y: number, z: number]
) {
  get3dRepProxy().getCursorActor().setPosition(...worldPosition);
  get3dViewProxy().render();
}

export function set3DCursorVisibility(visibility: boolean) {
  get3dRepProxy().getCursorActor().setVisibility(visibility);
  get3dViewProxy().render();
}

// --- event callbacks --- //

/**
 * Save rod position/orientation after each reslice cursor EndInteraction events
 */
export function saveResliceCursorState() {
  const treatmentStore = useTreatmentStore();
  const moduleStore = useModuleStore();

  const { selectedTooth } = treatmentStore;
  const { selectedModuleIndex } = moduleStore;

  if (selectedTooth !== null) {
    const widgetState = getResliceCursor().getWidgetState();
    const planes = widgetState.getPlanes();
    const origin = widgetState.getCenter();

    // In case we update resliceCursor origin/planes, at
    // 'rod' step, need to update both implant and rod
    // origin and planes to ensure implant will have
    // the same position than rod when it is initialized.

    const treatment = treatmentStore.treatments[selectedTooth];
    if (treatment && treatment.rod && treatment.implant) {
      if (selectedModuleIndex === 1) {
        // Rod step
        treatment.rod.origin = [...origin] as Vector3;
        treatment.rod.planes = structuredClone(planes);

        treatment.implant.origin = [...origin] as Vector3;
        treatment.implant.planes = structuredClone(planes);

      } else {
        treatment.implant.origin = [...origin] as Vector3;
        treatment.implant.planes = structuredClone(planes);
      }
    }
  }
}

// --- Custom implant --- //
function updateCustomImplant(input: vtkPolyData) {
  const { currentImplant, selectedTooth } = useTreatmentStore();

  if (!currentImplant || !selectedTooth) {
    return;
  }

  configureResliceCursorForImplants();

  // 2D views
  getResliceCursorViewWidgets().forEach((viewWidget) => {
    const representation = viewWidget.getRepresentations()[0];
    // @ts-ignore: representation is a vtkCustomResliceCursorContextRepresentation
    const viewType = representation.getViewType() as ViewTypes;
    if (currentImplant.inputSource.views2D[viewType] !== null) {
      currentImplant.inputSource.views2D[viewType]!.setInputData(input);
      // @ts-ignore: representation is a vtkCustomResliceCursorContextRepresentation
      representation.getCenterMapper().setInputConnection(
        currentImplant.inputSource.views2D[viewType]!.getOutputPort()
      );
      // @ts-ignore: TODO add `updateActorVisibility` to vtkWidgetRepresentation index.d.ts
      representation.setVisibility(true);
      // @ts-ignore: TODO add `updateActorVisibility` to vtkWidgetRepresentation index.d.ts
      representation.updateActorVisibility();
      viewWidget.getInteractor().render();
    }
  });

   // 3D View
   addOrUpdateImplant3D(currentImplant, selectedTooth, false).then(
    () => moveMeshesIn3DView(selectedTooth)
  );

}

export function setImplantDiameter(diameter: number) {
  const { currentImplant } = useTreatmentStore();

  if (currentImplant === null) {
    return;
  }

  const { transformFilter } = currentImplant.inputSource;
  if (transformFilter === null) {
    return;
  }
  transformFilter.setDiameter(diameter);
  transformFilter.update();
  const output = transformFilter.getOutputData();

  updateCustomImplant(output);
}

export function setImplantLength(length: number) {
  const { currentImplant } = useTreatmentStore();

  if (currentImplant === null) {
    return;
  }

  const { transformFilter } = currentImplant.inputSource;
  if (transformFilter === null) {
    return;
  }
  transformFilter.setLength(length);
  transformFilter.update();
  const output = transformFilter.getOutputData();
  updateCustomImplant(output);
}

// --- watchers --- //

export function setupDisplayWatchers() {
  const treatmentStore = useTreatmentStore();
  const moduleStore = useModuleStore();

  const { currentTreatment, selectedTooth, treatments } = storeToRefs(treatmentStore);
  const { selectedModuleIndex } = storeToRefs(moduleStore);
  const { clippingType, inactiveImplantsVisibility } = storeToRefs(useView3DStore());

  const rodSize = computed(() => currentTreatment.value?.rod?.size);
  const implantModel = computed(() => currentTreatment.value?.implant?.model);


  // If the user selects another tooth, refresh the display AND reset the camera
  watch(selectedTooth, () => {
    display(currentTreatment.value, selectedModuleIndex.value, true).then(() => {
      updateIndex();

      // Update 3D view
      refresh3DActorsOpacity();
      get3dViewProxy().render();
    });
  });

  watch(treatments, () => {
    const teethIds = Object.keys(treatments.value);
    // Remove actors that are no longer on the treatment plan.
    // (Needed when loading a session file)
    Object.keys(actors3D).forEach((toothId) => {
      if (!teethIds.includes(toothId)) {
        removeTooth3DActors(Number(toothId));
      }
    });

    // Add 3D representations when needed
    Object.entries(treatments.value).forEach(([id, t]) => {
      const toothId = Number(id);
      if (!actors3D[toothId]) {
        const treatment = t as Treatment
        const promises = [];
        if (treatment.rod) {
          promises.push(addOrUpdateRod3D(treatment.rod, toothId));
        }
        if (treatment.implant && treatment.implant.model !== null) {
          promises.push(addOrUpdateImplant3D(treatment.implant, toothId));
        }
        if (treatment.correction?.corrector.type === 'Custom' && hasCorrection(treatment.correction)) {
          // Get corrector geometries, and mesh for future display
          promises.push(updateCustomCorrector(treatment.correction?.corrector, toothId));
        }

        Promise.all(promises).then(() => {
          moveMeshesIn3DView(toothId);
        })
      }
    });
  });

  // If only the rod size changes, refresh the display
  watch(rodSize, (newSize, oldSize) => {
    if (newSize === oldSize) {
      return;
    }
    if (currentTreatment.value && currentTreatment.value.rod) {
      display(currentTreatment.value, selectedModuleIndex.value);
      addOrUpdateRod3D(currentTreatment.value.rod, selectedTooth.value!).then(() => {
        moveMeshesIn3DView(selectedTooth.value!);
      });
    }
  });

  // If only the implant model changes, refresh the display
  watch(implantModel, async (newModel, oldModel) => {
    // Do not reload data, if we only update the diameter and / or
    // length in case of a 'generic' implant
    if (
      oldModel && newModel 
      && isGenericManufacturerOrModel(oldModel.manufacturer) 
      && isGenericManufacturerOrModel(newModel.manufacturer) 
      && (newModel.diameter !== oldModel.diameter || newModel.length !== oldModel.length)
    ) {
      return;
    }

    if (currentTreatment.value && currentTreatment.value.implant) {
      await display(currentTreatment.value, selectedModuleIndex.value);
      addOrUpdateImplant3D(currentTreatment.value.implant, selectedTooth.value!, false).then(
        () => {
          moveMeshesIn3DView(selectedTooth.value!);
        }
      );
    }
  });

  // If the user changes tab, refresh the display
  watch(selectedModuleIndex, (newModuleId, oldModuleId) => {
    if (newModuleId !== oldModuleId) {
      // Update clipping plane:
      useView3DStore().clippingType = 'None';
      display(currentTreatment.value, selectedModuleIndex.value, false);

      if (newModuleId === 2) {
        // implant tab
        if (hasCorrection(currentTreatment.value!.correction)) {
          applyCorrection();
        }
      }
    }
  });

  watch(inactiveImplantsVisibility, () => {
    updateInactiveImplantsVisibility();
    if (clippingType.value === 'Longitudinal') {
      actors3D[selectedTooth.value!].rod?.setVisibility(inactiveImplantsVisibility.value);

      get3dViewProxy().render();
    }
  });

  watch(clippingType, (newValue, oldValue) => {
    if (newValue === 'Longitudinal' || oldValue === 'Longitudinal') {
      inactiveImplantsVisibility.value = oldValue === 'Longitudinal';
    }

    updateClippingPlane();
  });
}
