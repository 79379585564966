
import { defineStore } from 'pinia';
import { View3DClippingType } from '../types/views';

interface State {
  inactiveImplantsVisibility: boolean,
  clippingType: View3DClippingType,
}

export const useView3DStore = defineStore('view3D', {
  state: (): State => ({
    inactiveImplantsVisibility: true,
    clippingType: 'None',
  }),
});
