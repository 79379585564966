import '@/public/global.css';
import '@kitware/vtk.js/Rendering/Profiles/All';
import 'vue-toastification/dist/index.css';

import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import VueToast from 'vue-toastification';
import { createPinia, PiniaVuePlugin } from 'pinia';
import vtkProxyManager from '@kitware/vtk.js/Proxy/Core/ProxyManager';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkImageMapper from '@kitware/vtk.js/Rendering/Core/ImageMapper';

// import App from './components/App.vue';
import VueTour from 'vue-tour'
import HomePage from './auth/HomePage.vue';
import vuetify from './plugins/vuetify';
import { DICOMIO } from './io/dicom';
import { FILE_READERS } from './io';
import { registerAllReaders } from './io/readers';
import proxyConfiguration from './vtk/proxy';
import { CorePiniaProviderPlugin } from './core/provider';
import ProxyWrapper from './core/proxies';
import { Auth0Plugin } from './auth';
// Import the Auth0 configuration
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID } from './auth/config';

import i18n from "../translation/i18n";

require('vue-tour/dist/vue-tour.css');

Vue.config.productionTip = false;

Vue.use(VueCompositionAPI);
Vue.use(VueToast);
Vue.use(PiniaVuePlugin);
Vue.use(VueTour);

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  onRedirectCallback: appState => {
    // fdqsf
    console.log('onRedirectCallback', appState);
  }
});

// Initialize global mapper topologies
// polys and lines in the front
vtkMapper.setResolveCoincidentTopologyToPolygonOffset();
vtkMapper.setResolveCoincidentTopologyPolygonOffsetParameters(-3, -3);
vtkMapper.setResolveCoincidentTopologyLineOffsetParameters(-3, -3);
// image poly in the back
vtkImageMapper.setResolveCoincidentTopologyToPolygonOffset();
vtkImageMapper.setResolveCoincidentTopologyPolygonOffsetParameters(1, 1);

registerAllReaders(FILE_READERS);

const proxyManager = vtkProxyManager.newInstance({ proxyConfiguration });

const dicomIO = new DICOMIO();
dicomIO.initialize();

const pinia = createPinia();
pinia.use(
  CorePiniaProviderPlugin({
    proxies: new ProxyWrapper(proxyManager),
    dicomIO,
  })
);

const app = new Vue({
  i18n,
  vuetify,
  proxyManager,
  pinia,
  provide: {
    ProxyManager: proxyManager,
  },
  render: (h) => h(HomePage),
});

app.$mount('#app');
