import macro from '@kitware/vtk.js/macros';

import vtkMatrixBuilder from '@kitware/vtk.js/Common/Core/MatrixBuilder';
import vtkMath from '@kitware/vtk.js/Common/Core/Math';
import vtkPoints from '@kitware/vtk.js/Common/Core/Points';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkBoundingBox from '@kitware/vtk.js/Common/DataModel/BoundingBox';

// ----------------------------------------------------------------------------
// vtkImplantCustom methods
// ----------------------------------------------------------------------------
const DEFAULT_LENGTH = 10;
const DEFAULT_DIAMETER = 4;

function vtkTransformFilter(publicAPI, model) {
  // Set our classname
  model.classHierarchy.push('vtkTransformFilter');

  publicAPI.requestData = (inData, outData) => {
    const input = inData[0];

    const output = vtkPolyData.newInstance();
    output.setPolys(input.getPolys());

    const points = vtkPoints.newInstance({
      size: input.getNumberOfPoints() * 3,
    });
    output.setPoints(points);

    const pointsData = Float32Array.from(input.getPoints().getData());

    // Compute center
    const bounds = output.getBounds();
    const center = vtkBoundingBox.getCenter(bounds);
  
    // Apply diameter and new length
    const diameterFactor = (model.diameter / DEFAULT_DIAMETER);
    const lengthFactor = model.length / DEFAULT_LENGTH;

    vtkMatrixBuilder
      .buildFromDegree()
      .translate(...center)
      .scale(diameterFactor, diameterFactor, lengthFactor)
      .translate(... vtkMath.multiplyScalar(center, -1))
      .apply(pointsData);

    points.setData(pointsData, 3);
    outData[0] = output;
  };
}

// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
  length: DEFAULT_LENGTH,
  diameter: DEFAULT_DIAMETER,
};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  macro.setGet(publicAPI, model, ['length', 'diameter']);
  macro.obj(publicAPI, model);
  macro.algo(publicAPI, model, 1, 1);

  // Object specific methods
  vtkTransformFilter(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'vtkTransformFilter');

// ----------------------------------------------------------------------------

export default { newInstance, extend };
