import macro from '@kitware/vtk.js/macros';
import vtkResliceCursorWidget from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget';
import { ViewTypes } from '@kitware/vtk.js/Widgets/Core/WidgetManager/Constants';
import vtkCustomResliceCursorContextRepresentation from './customResliceCursorContextRepresentation';

import widgetBehavior from './behavior';
import stateGenerator from './state';

// ----------------------------------------------------------------------------
// Factory
// ----------------------------------------------------------------------------

function vtkCustomResliceCursorWidget(publicAPI, model) {
  model.classHierarchy.push('vtkResliceCursorWidget');

  // --------------------------------------------------------------------------
  // initialization
  // --------------------------------------------------------------------------

  publicAPI.getRepresentationsForViewType = (viewType) => {
    switch (viewType) {
      case ViewTypes.XY_PLANE:
        return [
          {
            builder: vtkCustomResliceCursorContextRepresentation,
            labels: ['AxisXinZ', 'AxisYinZ'],
            initialValues: {
              axis1Name: 'AxisXinZ',
              axis2Name: 'AxisYinZ',
              viewType: ViewTypes.XY_PLANE,
              rotationEnabled: model.widgetState.getEnableRotation(),
              visibility: false,
            },
          },
        ];
      case ViewTypes.XZ_PLANE:
        return [
          {
            builder: vtkCustomResliceCursorContextRepresentation,
            labels: ['AxisXinY', 'AxisZinY'],
            initialValues: {
              axis1Name: 'AxisXinY',
              axis2Name: 'AxisZinY',
              viewType: ViewTypes.XZ_PLANE,
              rotationEnabled: model.widgetState.getEnableRotation(),
              visibility: false,
            },
          },
        ];
      case ViewTypes.YZ_PLANE:
        return [
          {
            builder: vtkCustomResliceCursorContextRepresentation,
            labels: ['AxisYinX', 'AxisZinX'],
            initialValues: {
              axis1Name: 'AxisYinX',
              axis2Name: 'AxisZinX',
              viewType: ViewTypes.YZ_PLANE,
              rotationEnabled: model.widgetState.getEnableRotation(),
              visibility: false,
            },
          },
        ];
      case ViewTypes.DEFAULT:
      case ViewTypes.GEOMETRY:
      case ViewTypes.SLICE:
      case ViewTypes.VOLUME:
      default:
        return [];
    }
  };
}

// ----------------------------------------------------------------------------

const defaultValues = (initialValues) => ({
  behavior: widgetBehavior,
  widgetState: stateGenerator(),
  ...initialValues,
});

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
  vtkResliceCursorWidget.extend(publicAPI, model, defaultValues(initialValues));

  vtkCustomResliceCursorWidget(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(
  extend,
  'vtkCustomResliceCursorWidget'
);

// ----------------------------------------------------------------------------

export default { newInstance, extend };
