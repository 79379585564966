import Vue from 'vue';
import { defineStore } from 'pinia';

import { Nullable } from '@kitware/vtk.js/types';

import { vec3 } from 'gl-matrix';
import { ViewTypes } from '@kitware/vtk.js/Widgets/Core/WidgetManager/Constants';
import { ResliceCursorPlanes } from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/state';

import { ImplantModel } from '@/src/types/implant';
import { TreatmentsType } from '@/src/types/treatment';

import { getDefaultCorrector } from '../utils/correctors';

interface State {
  implantModels: Array<ImplantModel>;
  treatments: TreatmentsType;
  selectedTooth: Nullable<number>;
}

export const useTreatmentStore = defineStore('treatment', {
  state: (): State => ({
    implantModels: [],
    treatments: {},
    selectedTooth: null,
  }),
  getters: {
    currentTreatment: (state) =>
      state.selectedTooth ? state.treatments[state.selectedTooth] : null,
    currentRod: (state) => 
      state.selectedTooth ? state.treatments[state.selectedTooth].rod : null,
    currentImplant: (state) => 
      state.selectedTooth ? state.treatments[state.selectedTooth].implant : null,
    currentCorrection: (state) => {
      return state.selectedTooth ? state.treatments[state.selectedTooth].correction : null;
    }
  },
  actions: {
    async load() {
      const token = await Vue.prototype.$auth.getTokenSilently({ audience: process.env.VUE_APP_API_URL });
      const params = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      };
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/api/implants/mapping`,
        params
      );
      const data = (await response.json()) || {};
      Object.entries(data.manufacturers).forEach(([manufacturer, models]) => {
        Object.entries(models as object).forEach(([model, options]) => {
          Object.entries(options as object).forEach(([reference, option]) => {
            this.implantModels.push({
              manufacturer,
              model,
              reference: reference.replace('.vtp', ''),
              ...option,
            });
          });
        });
      });

      /**
       * By default the first element should have:
       * diameter: 4mm
       * length: 10mm (or 11mm if 10mm not defined)
       * cuff: 2mm (only if defined i.e. for IBS / Magicore implants)
       */
      const indices: number[] = [];

      // First get indices of implants that match the rule.
      // Then, move them all at the begining of the table.
      this.implantModels.forEach((implant, index) => {
        if (
          implant.diameter === 4
          && (implant.length === 10 || implant.length === 11)
          && (implant.cuff === 2 || implant.cuff === null)) {
          indices.push(index);
        }
      });

      indices.forEach((index) => {
        const val = this.implantModels[index];
        this.implantModels.splice(index, 1);
        this.implantModels.splice(0, 0, val);
      });
    },
    addTreatment(toothId: number, rodInitialPosition:  {origin: vec3, planes: ResliceCursorPlanes} | null) {
      if (rodInitialPosition === null) {
        return;
      }
      Vue.set(this.treatments, toothId, {
        rod: {
          origin: rodInitialPosition.origin,
          planes: rodInitialPosition.planes,
          size: 'standard',
        },
        implant: {
          model: null,
          origin: rodInitialPosition.origin,
          planes: rodInitialPosition.planes,
          inputSource:{
            views2D: {
              [ViewTypes.XY_PLANE]: null,
              [ViewTypes.XZ_PLANE]: null,
              [ViewTypes.YZ_PLANE]: null,
            },
            transformFilter: null,
          },
        },
        correction: {
          corrector: getDefaultCorrector('Classic'),
          indexOrientation: null,
          drilling: 0,
          reference: null,
        },
        report: {
          bone: null,
          gum: null,
          other: null,
        },
      });
    },
    removeTreatment(toothId: number) {
      Vue.delete(this.treatments, toothId);
      if (this.selectedTooth === toothId) {
        this.selectedTooth = null;
      }
    },
    resetTreatments() {
      Object.assign(this, {
        treatments: {},
        selectedTooth: null,
      });
    },
    setTreatments(treatments: any) {
      Object.assign(this, {
        treatments,
      });
    },
    setImplantModel(toothId: number, model: ImplantModel) {
      const treatment = this.treatments[toothId];
      if (treatment.implant) {
        treatment.implant.model = model;
      }
    },
  },
});
