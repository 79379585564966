import { ViewTypes } from "@kitware/vtk.js/Widgets/Core/WidgetManager/Constants";
import { LPSAxis } from "../types/lps";

export type LPSViewType = Extract<ViewTypes, ViewTypes.XY_PLANE | ViewTypes.YZ_PLANE | ViewTypes.XZ_PLANE>;

const LPSAxisToViewType: Record<LPSAxis, LPSViewType> = {
  Axial: ViewTypes.XY_PLANE,
  Sagittal: ViewTypes.YZ_PLANE,
  Coronal: ViewTypes.XZ_PLANE,
};

export function getViewType(lpsAxis: LPSAxis): LPSViewType {
  return LPSAxisToViewType[lpsAxis]
}
