var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"6","align-self":"center"}},[_c('v-checkbox',{staticClass:"ml-3 align-center justify-center",attrs:{"indeterminate":_vm.selectedSome && !_vm.selectedAll,"label":_vm.$t('importTab.selectAll'),"dense":"","hide-details":""},model:{value:(_vm.selectedAll),callback:function ($$v) {_vm.selectedAll=$$v},expression:"selectedAll"}})],1),_c('v-col',{staticClass:"d-flex justify-end mt-2",attrs:{"cols":"6","align-self":"center"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","disabled":!_vm.selectedSome},on:{"click":function($event){$event.stopPropagation();return _vm.removeSelectedDICOMVolumes.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_vm._v(" Delete selected ")])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"my-2 volume-list"},_vm._l((_vm.volumes),function(volume){return _c('groupable-item',{key:volume.info.VolumeID,attrs:{"value":volume.selectionKey},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var select = ref.select;
return [_c('v-card',{class:{
              'volume-card': true,
              'volume-card-active': active,
            },attrs:{"outlined":"","ripple":"","min-height":"180px","min-width":"180px","title":volume.info.SeriesDescription},on:{"click":select}},[_c('v-row',{staticClass:"pa-0",attrs:{"no-gutters":"","justify":"center"}},[_c('div',[_c('v-img',{attrs:{"contain":"","max-height":"150px","max-width":"150px","src":(_vm.thumbnailCache || {})[volume.cacheKey] || ''},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[_c('v-overlay',{staticClass:"thumbnail-overlay",attrs:{"absolute":"","value":true,"opacity":"0"}},[_c('div',{staticClass:"d-flex flex-column fill-height"},[_c('v-row',{attrs:{"no-gutters":"","justify":"end","align-content":"start"}},[_c('v-checkbox',{key:volume.info.VolumeID,attrs:{"value":volume.key,"dense":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-spacer'),_c('v-row',{attrs:{"no-gutters":"","justify":"start","align":"end"}},[_c('div',{staticClass:"mb-1 ml-1 text-caption"},[_vm._v(" ["+_vm._s(volume.info.NumberOfSlices)+"] ")])])],1)])],1)],1)]),_c('v-card-text',{staticClass:"text--primary text-caption text-center series-desc mt-n3"},[_c('div',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(volume.info.SeriesDescription || '(no description)')+" ")])])],1)]}}],null,true)})}),1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }