import vtkMatrixBuilder from "@kitware/vtk.js/Common/Core/MatrixBuilder";
import { Nullable, Vector3 } from "@kitware/vtk.js/types";
import { isLeftQuadrant } from "./teeth";

import { LPSDirections } from '../types/lps';


const teethAngle: Record<number,  number> = {};
export type Base = Record<'distal'|'vestibular', Nullable<Vector3>>;

const initialBases: Record<number, Base> = {
  18: {
    'distal': null,
    'vestibular': null
  },
  28: {
    'distal': null,
    'vestibular': null
  },
  38: {
    'distal': null,
    'vestibular': null
  },
  48: {
    'distal': null,
    'vestibular': null
  },
}


function initializeBaseAngles() {
  const angle = 90 / 7;
  [40, 30, 20, 10].forEach((quandrant: number) => {
    [8, 7, 6, 5, 4, 3, 2, 1].forEach((tooth: number, toothIndex) => {
      const toothId: number = quandrant + tooth;
      const left = isLeftQuadrant(toothId);
      const sign = left ? -1 : 1;
      teethAngle[toothId] = sign * angle * toothIndex
    });
  });
}

export function initializeBase(lpsOrientation: LPSDirections) {
  initializeBaseAngles();
  initialBases[18] = {
    'distal': [...lpsOrientation.Posterior] as Vector3,
    'vestibular': [...lpsOrientation.Right] as Vector3
  };
  initialBases[28] = {
    'distal': [...lpsOrientation.Posterior] as Vector3,
    'vestibular': [...lpsOrientation.Left] as Vector3
  };
  initialBases[38] = {
    'distal': [...lpsOrientation.Posterior] as Vector3,
    'vestibular': [...lpsOrientation.Left] as Vector3
  };
  initialBases[48] = {
    'distal': [...lpsOrientation.Posterior] as Vector3,
    'vestibular': [...lpsOrientation.Right] as Vector3
  };
}

export function getToothBase(toothId: number): Nullable<Base> {
  let referenceToothId = 18;
  if (toothId <= 28 && toothId >= 21) {
    referenceToothId = 28;
  }
  else if (toothId <= 38 && toothId >= 31) {
    referenceToothId = 38;
  }
  else if (toothId <= 48 && toothId >= 41) {
    referenceToothId = 48;
  }

  const initialBase = initialBases[referenceToothId];
  if (!initialBase.distal || !initialBase.vestibular) {
    return null;
  }

  const transform = 
    vtkMatrixBuilder
    .buildFromDegree()
    .identity()
    .rotateZ(teethAngle[toothId]);
  const vestibular = [...initialBase.vestibular] as Vector3;
  transform.apply(vestibular);
  const distal = [...initialBase.distal] as Vector3;
  transform.apply(distal);

  return {
    distal,
    vestibular
  }
}

