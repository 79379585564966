import { Nullable, Vector3 } from "@kitware/vtk.js/types"
import vtkMath from "@kitware/vtk.js/Common/Core/Math";
import { ResliceCursorPlanes } from "@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/state";
import vtkXMLPolyDataReader from "@kitware/vtk.js/IO/XML/XMLPolyDataReader";

import { ArrowButtonType } from "@/src/types";

import { 
  Correction,
  ClassicCorrector,
  IndexOrientation,
  TransformType,
  WorkingViewTypes,
  CustomCorrectorParams,
  CustomCorrector,
} from "@/src/types/corrector";

import vtkPlane from "@kitware/vtk.js/Common/DataModel/Plane";
import { isMaxillary } from "./teeth";
import { getViewType } from "./view";
import { getToothBase } from "./toothBase";

function getDefaultClassicCorrector(): ClassicCorrector {
  return {
    type: 'Classic',
    translations: {
      'Sagittal': 0,
      'Coronal': 0
    },
    rotations: {
      'Sagittal': 0,
      'Coronal': 0
    },
  }
}

function getDefaultCustomCorrector(): CustomCorrector {
  return {
    type: 'Custom',
    translations: {
      'Sagittal': 0,
      'Coronal': 0
    },
    rotations: {
      'Sagittal': 0,
      'Coronal': 0
    },
    appendedMesh: null,
    params: null,
  }
}

export function getDefaultCorrector(
  type: 'Classic' | 'Custom'
): ClassicCorrector | CustomCorrector {
  return type === 'Classic' ? getDefaultClassicCorrector() : getDefaultCustomCorrector();
}

export function hasCorrection(correction: Nullable<Correction>): boolean {
  if (!correction) {
    return false;
  }
  
  const {corrector} = correction;
  if (!corrector) {
    return false;
  }

  return (corrector.translations.Coronal !== 0
    || corrector.translations.Sagittal !== 0
    || corrector.rotations.Coronal !== 0
    || corrector.rotations.Sagittal !== 0
  );
}

export function getPossibleIndexOrientations(toothId: number): IndexOrientation[] {
  if (isMaxillary(toothId)) {
    return ["distal", "mesial", "vestibular", "palatal"];
  }
  return ["distal", "mesial", "vestibular", "lingual"];
}

// ----------------------------------------------------------------------------
//                            CLASSIC CORRECTOR
// ----------------------------------------------------------------------------

/**
 * Get list of possible correctors, for given 'action' and transform types,
 * from a given list of corrections.
 */
export function getCorrectorsFromCorrections(
  corrections: ClassicCorrector[],
  viewType: WorkingViewTypes,
  currentCorrector: ClassicCorrector,
  transform: TransformType,
  action: ArrowButtonType
): ClassicCorrector[] {
  const plural = `${transform}s`;

  return corrections.filter((corrector: ClassicCorrector) => {
    let toKeep = true;
    (['translations', 'rotations'] as const).forEach((transformType) => {
      (['Sagittal', 'Coronal'] as const).forEach((vt) => {
        if (vt === viewType && transformType === plural) {
          toKeep = toKeep && (
            action === 'next'
              ? corrector[transformType][vt] > currentCorrector[transformType][vt]
              :  corrector[transformType][vt] < currentCorrector[transformType][vt]
          );
        } else {
          toKeep = toKeep && corrector[transformType][vt] === currentCorrector[transformType][vt];
        }
      });
    });

    return toKeep;
  });
}

export function getAxisFromOrientation(
  toothId: number,
  orientation: IndexOrientation,
): Nullable<Vector3> {
  const toothBase = getToothBase(toothId);
  if (!toothBase || !toothBase.distal || !toothBase.vestibular) {
    return null;
  }

  switch(orientation) {
    case 'distal':
      return toothBase.distal;
    case 'vestibular':
      return toothBase.vestibular;
    case "mesial":
      return vtkMath.multiplyScalar(toothBase.distal, -1);
    case "lingual":
    case "palatal":
      return  vtkMath.multiplyScalar(toothBase.vestibular, -1);
    default:
      return null;
  }
}

export function computeIndexOrientation(
  toothId: number,
  vector: Nullable<Vector3>,
  distalDirection: Nullable<Vector3>,
  vestibularDirection: Nullable<Vector3>
): Nullable<IndexOrientation> {
  let orientation = null;

  if (vector === null || distalDirection === null || vestibularDirection === null) {
    return null;
  }

  const distalProjection: Vector3 = [0, 0, 0];
  vtkMath.projectVector(vector, distalDirection, distalProjection);
  const distalNorm = vtkMath.norm(distalProjection, 3);
  const vestibularProjection: Vector3 = [0, 0, 0];
  vtkMath.projectVector(vector, vestibularDirection, vestibularProjection);
  const vestibularNorm = vtkMath.norm(vestibularProjection, 3);

  if (vestibularNorm < distalNorm) {
    orientation = vtkMath.dot(distalDirection, distalProjection) > 0 ? 'distal' : 'mesial';
  } else {
    const dot = vtkMath.dot(vestibularDirection, vestibularProjection);
    if (dot < 0) {
      orientation = isMaxillary(toothId) ? 'palatal' : 'lingual'; 
    } else {
      orientation = 'vestibular';
    }
  }

  return orientation as Nullable<IndexOrientation>;
}

/**
 * Format corrector name for corrector with a unique translation to +-TT
 * with TT: Translation value
 */
function formatTranslationCorrectorName(translation: number): Nullable<string> {
  return `+-${Math.abs(translation)}`;
}

/**
 * Format corrector with only angular transform name to RR°
 * with RR: Rotation angle
 */
function formatRotationCorrectorName(rotation: number): Nullable<string> {
  return `${Math.abs(rotation)}°`;
}

/**
 * Format corrector with both linear and angular transform in the same view name to RR°sTT
 * with RR: Rotation angle
 * s: sign (+|-)
 * TT: translation value
 */
function formatTranslationRotationCorrectorName(translation: number, rotation: number): Nullable<string> {
  let correctorName: Nullable<string> = null;
  if ((translation > 0 && rotation > 0) || (translation < 0 && rotation <0)) {
    // i.e. translaton / rotation are both positive or negative
    correctorName = `${Math.abs(rotation)}°+${Math.abs(translation)}`;
  } else {
    // i.e. one of the two values is positivev and the other one is negative
    correctorName = `${Math.abs(rotation)}°-${Math.abs(translation)}`;
  }

  return correctorName;
}

/**
 * Format complex corrector name to RR°O-TTmm
 * with RR: Rotation angle
 * O: Corrector orientation (G | D)
 * TT: translation value
 */
function formatComplexCorrectorName(
  translation: number,
  rotation: number,
  orientation: string
): Nullable<string> {
  return `${Math.abs(rotation)}°${orientation}-${Math.abs(translation)}mm`;
}

/**
 * Compute corrector orientation (Right 'D' or Left 'G') for corrector
 * with both linear and angular transformations.
 * Corrector with linear and angular transformations are named 'Complex Corrector' here.
 */
export function getComplexCorrectorOrientation(
  linearShifitingVector: Vector3,
  angularShiftingVector: Vector3,
  axialPlaneNormal: Vector3
): Nullable<string>{
  const toLeftDirection: Vector3 = [0, 0, 0];
  vtkMath.cross(axialPlaneNormal, linearShifitingVector, toLeftDirection);
  return vtkMath.dot(angularShiftingVector, toLeftDirection) > 0 ? 'G' : 'D';
}

export function computeClassicCorrectorReference(
  originalCorrectors: Nullable<Record<string, ClassicCorrector[]>>,
  corrector: Nullable<ClassicCorrector>,
  orientation: Nullable<string>
): Nullable<string> {
  if (corrector === null || originalCorrectors === null) {
    return null;
  }

  const {translations, rotations}  = corrector;
  let reference: Nullable<string> = null;

  (['Sagittal', 'Coronal'] as const).forEach((viewType) => {
    const otherViewType = viewType === 'Sagittal' ? 'Coronal' : 'Sagittal';
    // Check if translation is not null
    if (translations[viewType] !== 0) {
      const translation = translations[viewType];
      // Check if there is an associated rotation on the same viewType
      if (rotations[viewType] !== 0) {
        reference = formatTranslationRotationCorrectorName(
          translation,
          rotations[viewType]
        );
      } else if (rotations[otherViewType] !== 0 && orientation) {
        // Check if there is an associated rotation on the other viewType
        reference = formatComplexCorrectorName(
          translation,
          rotations[otherViewType],
          orientation
        );
      } else {
        reference = formatTranslationCorrectorName(translation);
      }
    } else if (rotations[viewType] !== 0) {
      if (translations[otherViewType] !== 0 && orientation) {
        // Check if there is an associated translation on the other viewType
        // Do not check on the same viewType because it would have already been
        // computed on previous cases if it was the case.
        reference = formatComplexCorrectorName(
          translations[otherViewType],
          rotations[viewType],
          orientation
        );
      } else {
        reference = formatRotationCorrectorName(rotations[viewType]);
      }
    }
  });

  // Check if the corrector is listed in the reference list of correctors
  if (reference !== null) {
    const filename = `${reference}.stl`;
    const referenceList = originalCorrectors[filename];
    if (referenceList) {
      const found = referenceList.find(
        (c: ClassicCorrector) => {
          return (['translations', 'rotations'] as const).every((transform) => {
            return (['Sagittal', 'Coronal'] as const).every(
              (viewType) => c[transform][viewType] === corrector[transform][viewType]
            )
          })
        }
      );
      
      return found !== undefined ? reference : null;
    }
  }

  return null;
}

/**
 * Compute linear displacement vector.
 * This vector corresponds to the vector between newOrign and the
 * old one projected on the axial axis (in views).
 */
export function computeLinearShiftingVector(
  currentOrigin: Vector3,
  originalOrigin: Vector3,
  planeNormal: Vector3,
): Vector3 {
  // Compute translation vector
  const translationVector: Vector3 = [0, 0, 0];
  vtkMath.subtract(
    currentOrigin, 
    originalOrigin,
    translationVector
  );
  // Project vector on planeNormal.
  const projection: Vector3 = [0, 0, 0];
  vtkPlane.projectVector(translationVector, planeNormal, projection);

  return projection;
}

/**
 * Compute the angular displacement vector.
 * The vector is defined as the difference between the new axial normal and the previous one.
 */
export function computeAngularShiftingVector(
  currentPlanes: ResliceCursorPlanes,
  originalPlanes: ResliceCursorPlanes
): Vector3 {
  const axialViewType = getViewType("Axial");
  const currentAxialNormal = currentPlanes[axialViewType].normal as Vector3;
  const previousAxialNormal = originalPlanes[axialViewType].normal as Vector3;

  const vector:Vector3 = [0, 0, 0];
  vtkMath.subtract(currentAxialNormal, previousAxialNormal, vector);
  return vector;
}

// ----------------------------------------------------------------------------
//                            CUSTOM CORRECTOR
// ----------------------------------------------------------------------------
export async function getCorrecteurGeometries(params: CustomCorrectorParams = {}) {
  const workerResultPromise = new Promise((resolve) => {
    const worker = new Worker("correcteur/correcteurWorker.js");
    worker.postMessage({
      params
    });

    worker.onmessage = function onCorrecteurWorkerMessage(e) {
      const results = e.data;
      const polyLineReader = vtkXMLPolyDataReader.newInstance();
      polyLineReader.parseAsArrayBuffer(results.polyLineArrayBufferView);
    
      const tubeReader = vtkXMLPolyDataReader.newInstance();
      tubeReader.parseAsArrayBuffer(results.tubeArrayBufferView);
    
      const ergotReader = vtkXMLPolyDataReader.newInstance();
      ergotReader.parseAsArrayBuffer(results.ergotBufferView);
    
      const renfortReader = vtkXMLPolyDataReader.newInstance();
      renfortReader.parseAsArrayBuffer(results.renfortBufferView);

      resolve({
        polyline: polyLineReader.getOutputData(),
        tube: tubeReader.getOutputData(),
        ergot: ergotReader.getOutputData(),
        renfort: renfortReader.getOutputData()
      });
    };
  });

  return workerResultPromise;
}
