import { storeToRefs } from 'pinia';

import { Nullable, Vector3 } from '@kitware/vtk.js/types';
import vtkAppendPolyData from '@kitware/vtk.js/Filters/General/AppendPolyData';
import vtkMath from '@kitware/vtk.js/Common/Core/Math';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';

import { useCorrectorsStore } from '@/src/store/correctors';
import { useTreatmentStore } from '@/src/store/treatments';

import { 
  computeAngularShiftingVector,
  computeClassicCorrectorReference,
  computeLinearShiftingVector,
  getComplexCorrectorOrientation,
  getCorrectorsFromCorrections,
  getDefaultCorrector
} from '@/src/utils/correctors';
import { getViewType } from '@/src/utils/view';

import { ArrowButtonType } from '@/src/types/index';
import { ClassicCorrector, CorrectorType, CustomOutputGeometries, TransformType, WorkingViewTypes } from '@/src/types/corrector';
import { Implant } from '../types/implant';
import { Rod } from '../types/rod';

/**
 * When clicking on "next" or "previous" buttons, computes a list of possible
 * correctors with the same values than currentCorrector values exept for
 * the transform being modified.
 * Look for greater values if clicking on "next" and lower values if clicking
 * on "previous".
 * @param viewType 
 * @param currentCorrector 
 * @param transform 
 * @param action 
 * @returns 
 */

export function getCorrectors(
  viewType: WorkingViewTypes,
  currentCorrector: ClassicCorrector,
  transform: TransformType,
  action: ArrowButtonType
): ClassicCorrector[] {
  const { corrections } = useCorrectorsStore();

  if (corrections === null) {
    return [];
  }

  return getCorrectorsFromCorrections(corrections, viewType, currentCorrector, transform, action);
}

/**
 * Compute, from a list of correctors, the previous or next to select.
 * @param viewType 
 * @param correctorsList 
 * @param transform 
 * @param currentValue 
 * @returns 
 */
export function getCorrector(
  viewType: WorkingViewTypes,
  correctorsList: Array<ClassicCorrector>,
  transform: TransformType,
  currentValue: number
) {
  const plural: `${TransformType}s` = `${transform}s`;

  const values: number[] = correctorsList.map(
    (corrector: ClassicCorrector) => Math.abs(currentValue - corrector[plural][viewType])
  );
  const min = Math.min(...values);
  const id = values.findIndex((distance) => distance === min);

  return id > -1 ? correctorsList[id] : null;
}

export function getCorrectorReference(
  rod: Nullable<Rod>,
  implant: Nullable<Implant>,
  corrector: CorrectorType
): Nullable<string> {
  if (!(rod && implant && implant.planes && corrector)) {
    return null;
  }

  // Compute corrector orientation for complex correctors with both
  // linear and angular transforms in different view types.
  // Should be done here as we do not want to send reslice cursor information
  // to computeClassicCorrectorReference function to make testing easier
  const linearShifitingVector: Vector3 = computeLinearShiftingVector(
    implant.origin as Vector3,
    rod.origin as Vector3,
    implant.planes[getViewType("Axial")].normal as Vector3
  );

  const angularShiftingVector: Vector3 = computeAngularShiftingVector(
    implant.planes,
    rod.planes
  );

  let computedOrientation = null;
  if (
    !vtkMath.areEquals(linearShifitingVector, [0, 0, 0]) 
    && !vtkMath.areEquals(angularShiftingVector, [0, 0, 0])
  ) {
    computedOrientation = getComplexCorrectorOrientation(
      linearShifitingVector,
      angularShiftingVector,
      implant.planes[getViewType('Axial')].normal as Vector3,
    );
  }

  if (corrector.type === 'Classic') {
    return computeClassicCorrectorReference(
      useCorrectorsStore().originalsCorrectors,
      corrector,
      computedOrientation
    );
  }
  
  return 'Custom';
}

export function updateCorrectorType(newType: 'Classic' | 'Custom') {
  const {currentCorrection} = storeToRefs(useTreatmentStore());
  if (currentCorrection.value && currentCorrection.value.corrector && currentCorrection.value.corrector.type !== newType) {
    currentCorrection.value.corrector = getDefaultCorrector(newType);
  }
}

export function getAppendedCorrector(outputs: CustomOutputGeometries): vtkPolyData {
  // Append polydatas
  const appendPolyData = vtkAppendPolyData.newInstance();
  appendPolyData.setInputData(outputs.tube);
  appendPolyData.addInputData(outputs.ergot);
  appendPolyData.addInputData(outputs.renfort);
  appendPolyData.update();

  return appendPolyData.getOutputData();
}
