import macro from '@kitware/vtk.js/macros';

import defaultBehavior from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/behavior';
import * as vtkMath from '@kitware/vtk.js/Common/Core/Math';

import {
  updateState,
  getAssociatedLinesName
} from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/helpers';

import {
  ScrollingMethods,
  InteractionMethodsName,
} from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/Constants';

export default function widgetBehavior(publicAPI, model) {
  let previousPosition;
  // We inherit reslice cursor
  defaultBehavior(publicAPI, model);

  publicAPI.startScrolling = (newPosition) => {
    if (newPosition) {
      previousPosition = newPosition;
    }
    publicAPI.resetUpdateMethod();
    publicAPI.startInteraction();
  };

  publicAPI.handleLeftButtonPress = (callData) => {
    if (model.activeState && model.activeState.getActive()) {
      model._isDragging = true;
      const {viewType} = model;
      const currentPlaneNormal = model.widgetState.getPlanes()[viewType].normal;
      model.planeManipulator.setWidgetOrigin(model.widgetState.getCenter());
      model.planeManipulator.setWidgetNormal(currentPlaneNormal);
      const worldCoords = model.planeManipulator.handleEvent(
        callData,
        model._apiSpecificRenderWindow
      );
      previousPosition = worldCoords;

      publicAPI.startInteraction();
    } else if (
      model.widgetState.getScrollingMethod() ===
      ScrollingMethods.LEFT_MOUSE_BUTTON
    ) {
      publicAPI.startScrolling(callData.position);
    } else {
      return macro.VOID;
    }

    return macro.EVENT_ABORT;
  };

  publicAPI[InteractionMethodsName.TranslateCenter] = (calldata) => {
    const worldCoords = model.planeManipulator.handleEvent(
      calldata,
      model._apiSpecificRenderWindow
    );
    const translation = vtkMath.subtract(worldCoords, previousPosition, []);
    previousPosition = worldCoords;
    let newCenter = vtkMath.add(model.widgetState.getCenter(), translation, []);
    newCenter = publicAPI.getBoundedCenter(newCenter);
    model.widgetState.setCenter(newCenter);
    updateState(model.widgetState);
  };

  publicAPI[InteractionMethodsName.TranslateAxis] = (calldata) => {
    const stateLine = model.widgetState.getActiveLineState();
    const worldCoords = model.planeManipulator.handleEvent(
      calldata,
      model._apiSpecificRenderWindow
    );

    const point1 = stateLine.getPoint1();
    const point2 = stateLine.getPoint2();

    // Translate the current line along the other line
    const otherLineName = getAssociatedLinesName(stateLine.getName());
    const otherLine = model.widgetState[`get${otherLineName}`]();
    const otherLineVector = vtkMath.subtract(
      otherLine.getPoint2(),
      otherLine.getPoint1(),
      []
    );
    vtkMath.normalize(otherLineVector);
    const axisTranslation = otherLineVector;

    const translation = vtkMath.subtract(worldCoords, previousPosition, []);
    previousPosition = worldCoords;

    const currentLineVector = vtkMath.subtract(point2, point1, [0, 0, 0]);
    vtkMath.normalize(currentLineVector);

    const dot = vtkMath.dot(currentLineVector, otherLineVector);
    // lines are colinear, translate along perpendicular axis from current line
    if (dot === 1 || dot === -1) {
      vtkMath.cross(
        currentLineVector,
        model.planeManipulator.getWidgetNormal(),
        axisTranslation
      );
    }

    const translationDistance = vtkMath.dot(translation, axisTranslation);

    const center = model.widgetState.getCenter();
    let newOrigin = vtkMath.multiplyAccumulate(
      center,
      axisTranslation,
      translationDistance,
      [0, 0, 0]
    );
    newOrigin = publicAPI.getBoundedCenter(newOrigin);
    model.widgetState.setCenter(newOrigin);
    updateState(model.widgetState);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  publicAPI.handleStartMouseWheel = (callData) => {};

  // Disable slice scroll
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  publicAPI.handleMouseWheel = (calldata) => {
    return macro.VOID;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  publicAPI.handleEndMouseWheel = (calldata) => {};
}
