import vtkAbstractWidget from '@kitware/vtk.js/Widgets/Core/AbstractWidget';
import { ResliceCursorPlanes } from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/state';

import vtkCustomResliceCursorContextRepresentation from '../vtk/CustomResliceCursorWidget/customResliceCursorContextRepresentation';


import vtkCustomResliceCursorWidget from '../vtk/CustomResliceCursorWidget';
import vtkLPSView2DProxy from '../vtk/LPSView2DProxy';
import vtkMPRRepresentationProxy from '../vtk/MPRRepresentationProxy';
import { getCameraInitialParallelScale } from '../utils/camera';
import { getViewType } from '../utils/view';
import { LPSAxis } from '../types/lps';

let resliceCursor = null as vtkCustomResliceCursorWidget | null;
let initialPlaneState = null as ResliceCursorPlanes | null;

/**
 * Initialize the reslice cursor widget.
 * This function should be called before instantiating any view or representation proxy.
 */
export function initializeResliceCursor() {
  resliceCursor = vtkCustomResliceCursorWidget.newInstance();
  const widgetState = resliceCursor.getWidgetState();
  widgetState.setKeepOrthogonality(true);
  widgetState.setOpacity(0.6);
  widgetState.setSphereRadius(10);
  widgetState.setLineThickness(2);
  initialPlaneState = { ...widgetState.getPlanes() };

  if (process.env.NODE_ENV === 'development') {
    // Expose ResliceCursorWidget in debug console
    // @ts-ignore
    window.resliceCursor = resliceCursor;
  }
}

/**
 * Return the reslice cursor widget.
 */
export function getResliceCursor() {
  return resliceCursor as vtkCustomResliceCursorWidget;
}

/**
 * Return the list of all registered reslice cursor view widgets.
 */
export function getResliceCursorViewWidgets() {
  return resliceCursor
    ? (resliceCursor
        .getViewIds()
        .map((viewId) => resliceCursor!.getWidgetForView({ viewId }))
        .filter((x) => x) as vtkAbstractWidget[]) // filter 'null' values
    : [];
}

/**
 * Get resliceCursorWidget of a specific view
 */
export function getResliceCursorViewWidget(
  axisType: 'Axial' | 'Sagittal' | 'Coronal'
): undefined | vtkAbstractWidget {
  const viewType = getViewType(axisType);
  
  return getResliceCursorViewWidgets().find((viewWidget) => {
    const representation = viewWidget.getRepresentations()[0] as unknown as vtkCustomResliceCursorContextRepresentation;
    return representation.getViewType() === viewType
  });
}

export function getResliceCursorDefaults() {
  if (resliceCursor && initialPlaneState) {
    return {
      origin: resliceCursor.getWidgetState().getImage().getCenter(),
      planes: {...initialPlaneState}
    }
  }
  return null;
}

/**
 * Reset reslice cursor center and planes (orientation).
 */
export function resetResliceCursorState() {
  if (resliceCursor && initialPlaneState) {
    resliceCursor.getWidgetState().setPlanes({ ...initialPlaneState });
    resliceCursor.setCenter(
      resliceCursor.getWidgetState().getImage().getCenter()
    );
  }
}

/**
 * Update the reslice filter on a give 2D view proxy.
 */
export function updateViewResliceCursorFilter(viewProxy: vtkLPSView2DProxy, lpsAxis: LPSAxis, reset=false) {
  const representationProxy =
    viewProxy.getRepresentations()[0] as vtkMPRRepresentationProxy;
  representationProxy.updateReslice({
    resetFocalPoint: reset,
    keepFocalPointPosition: false,
    computeFocalPointOffset: reset,
  });

  if (reset) {
    // Compute default zoom
    const viewType = getViewType(lpsAxis);
    const parallelScale = getCameraInitialParallelScale(
      viewType,
      resliceCursor?.getWidgetState().getImage().getBounds(),
      viewProxy.getOpenglRenderWindow().getSize()
    );
    if (parallelScale) {
      viewProxy.getCamera().setParallelScale(parallelScale);
      viewProxy.render();
    }
  }
}

export function flipAxial(isMaxillary = false) {
  const planes: ResliceCursorPlanes = { 
    ...initialPlaneState,
   } as ResliceCursorPlanes;
  if (resliceCursor && planes && planes[6]) {

    // Update only XY_PLANE parameters as we only want the axial view to be flipped
    const z = isMaxillary ? -1 : 1;
    planes[6].normal = [0, 0, z];
    planes[6].viewUp = [0, z, 0];

    resliceCursor.getWidgetState().setPlanes({ ...planes });
    resliceCursor.setCenter(
      resliceCursor.getWidgetState().getImage().getCenter()
    );
  }
}

export default {
  initializeResliceCursor,
  getResliceCursor,
  getResliceCursorViewWidgets,
  resetResliceCursorState,
  flipAxial,
};
