import { Nullable, Vector3 } from '@kitware/vtk.js/types';
import { ResliceCursorPlanes } from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/state';

import { Treatment, Report } from '@/src/types/treatment';

import { Correction, IndexOrientation, WorkingViewTypes } from '@/src/types/corrector';
import { ImplantModel, InputSource } from '@/src/types/implant';
import { Rod } from '@/src/types/rod';

import {
  useDatasetStore,
  convertSuccessResultToDataSelection,
  LoadResult,
  FileLoadSuccess,
  DICOMLoadSuccess,
  FileLoadFailure,
  DICOMLoadFailure,
} from '../store/datasets';
import { useMessageStore } from '../store/messages';
import { useDICOMStore } from '../store/datasets-dicom';
import { useTreatmentStore } from '../store/treatments';

import { plural } from '../utils';

import { useCurrentImage } from '../composables/useCurrentImage';

import i18n from "../../translation/i18n"

export async function openDicomFiles(files: File[] | FileList | null) {
  if (!files) {
    return;
  }

  const dataStore = useDatasetStore();
  const messageStore = useMessageStore();

  const nFiles = files.length;

  const loadFirstDataset = !dataStore.primarySelection;
  const msgID = messageStore.addInfo(
    i18n.t(
      'app.fileLoading.inProgressWithFilesNumber', 
      {
        nFiles,
        files: plural(nFiles, i18n.t('app.fileLoading.file') as string)
      }
    ) as string
  );

  let statuses: LoadResult[] = [];

  try {
    statuses = await dataStore.loadFiles(Array.from(files));
  } catch (error) {
    messageStore.addError(i18n.t('app.fileLoading.fail') as string, error as Error);
  } finally {
    messageStore.clearOne(msgID);
  }

  const loaded = statuses.filter((s) => s.loaded) as (
    | FileLoadSuccess
    | DICOMLoadSuccess
  )[];
  const errored = statuses.filter((s) => !s.loaded) as (
    | FileLoadFailure
    | DICOMLoadFailure
  )[];

  if (loaded.length && (loadFirstDataset || loaded.length === 1)) {
    const selection = convertSuccessResultToDataSelection(loaded[0]);
    dataStore.setPrimarySelection(selection);
  }

  const failedFilenames = errored.map((result) => {
    if (result.type === 'file') {
      return result.filename;
    }
    return 'DICOM files';
  });
  const failedFileMessage = i18n.t(
    'app.fileLoading.failedFileMessage', 
    {fileNames: failedFilenames.join('\n')}
  ) as string;


  if (loaded.length && !errored.length) {
    messageStore.addSuccess(i18n.t('app.fileLoading.success') as string);
  }
  if (loaded.length && errored.length) {
    messageStore.addWarning(i18n.t('app.fileLoading.someFailed') as string, failedFileMessage);
  }
  if (!loaded.length && errored.length) {
    messageStore.addError(i18n.t('app.fileLoading.error') as string, failedFileMessage);
  }
}

export async function openSessionFile(file: File | null) {
  if (!file) {
    return;
  }

  const content = await file.text();
  const parsedContent = JSON.parse(content);
  
  // Check series UID
  const { currentImageID } = useCurrentImage();
  const dicomStore = useDICOMStore();
  const messageStore = useMessageStore();

  if (!currentImageID.value || !dicomStore.imageIDToVolumeKey[currentImageID.value]) {
    messageStore.addError(i18n.t('app.fileLoading.dataNotLoaded') as string);
    return;
  }

  if (parsedContent.seriesInstanceUID === null) {
    messageStore.addError(i18n.t('app.fileLoading.missingUID') as string);
    return;
  }

  // Get data information
  const volumeKey = dicomStore.imageIDToVolumeKey[currentImageID.value];
  if (!volumeKey || parsedContent.seriesInstanceUID !== dicomStore.volumeInfo[volumeKey].SeriesInstanceUID) {
    messageStore.addError(i18n.t('app.fileLoading.wrongUID') as string);
    return;
  }

  // Add support to old session files.
  if (!('version' in parsedContent) ||  Number(parsedContent.version) < 2) {
    interface IOldTreatment {
      rod: Rod;
      implant: {
        model: Nullable<ImplantModel>;
        origin: Nullable<Vector3>;
        planes: Nullable<ResliceCursorPlanes>;
        correction: {
          corrector: {
            translations: Record<WorkingViewTypes, number>;
            rotations: Record<WorkingViewTypes, number>;
          }
          indexOrientation: Nullable<IndexOrientation>;
          drilling: Nullable<number>;
          reference: Nullable<string>;
        };
        inputSource: InputSource,
      };
      report: Report;
    };

    Object.keys(parsedContent.treatments).forEach((toothId) => {
      const oldVersionTreatment = parsedContent.treatments[toothId] as IOldTreatment;
      const treatment: Treatment = {
        rod: oldVersionTreatment.rod,
        report: oldVersionTreatment.report,
        implant: {
          model: oldVersionTreatment.implant.model,
          origin: oldVersionTreatment.implant.origin,
          planes: oldVersionTreatment.implant.planes,
          inputSource: oldVersionTreatment.implant.inputSource,
        },
        correction: null,
      }
      // Move correction object from implant to treatment directly
      // and append its value with 'type': Classic
      if (oldVersionTreatment.implant.correction !== null) {
        treatment.correction = {...oldVersionTreatment.implant.correction} as Correction;
        treatment.correction.corrector.type = 'Classic';
      }
      
      // Drilling value typed changed. It used to be a string and it is now a number.
      const {drilling} = treatment.correction!;
      if (typeof(drilling) === "string") {
        treatment.correction!.drilling = Number(drilling);
      }
      parsedContent.treatments[toothId] = treatment;
    });
  }


  useTreatmentStore().setTreatments(parsedContent.treatments);
  messageStore.addSuccess(i18n.t('app.fileLoading.sessionRestoredSuccess') as string);
}
