import { Bounds, Nullable } from "@kitware/vtk.js/types";
import { ViewTypes } from "@kitware/vtk.js/Widgets/Core/WidgetManager/Constants";

/**
 * Compute initial parallel scale for camera to fit better images in 2D views.
 * Because resliceCursor lines are longer than the image itself, it is needed to zoom
 * in.
 * @param viewType 
 * @param imageBounds 
 * @param viewPortSize 
 * @returns number or null corresponding to the parallelScale to apply.
 */
export function getCameraInitialParallelScale(viewType: ViewTypes, imageBounds: number[] | Bounds | undefined, viewPortSize: number[]) : Nullable<number> {
  if (!imageBounds) {
    return null;
  }

  // To compute parallel scale, we need to compute the distance between camera and
  // the center of the image
  
  // Depending on view type, compute view display width and height
  // Then, using arithmetic, compute distance from camera to center.
  let displayWidth = 0;
  let displayHeight = 0;

  if (viewType === ViewTypes.XY_PLANE) {
    displayWidth = (imageBounds[3] - imageBounds[2]) / 2;
    displayHeight = (imageBounds[1] - imageBounds[0]) / 2;
  } else if (viewType === ViewTypes.YZ_PLANE) {
    displayWidth = (imageBounds[3] - imageBounds[2]) / 2;
    displayHeight = (imageBounds[5] - imageBounds[4]) / 2;
  } else if (viewType === ViewTypes.XZ_PLANE) {
    displayWidth = (imageBounds[1] - imageBounds[0]) / 2;
    displayHeight = (imageBounds[5] - imageBounds[4]) / 2;
  }

  const ratio = viewPortSize[0] / viewPortSize[1];

  const viewAngle = 30; // considering camera view angle is always 30.
  const tangent = Math.tan((viewAngle / 2 * Math.PI) / 180);

  const distanceWidth = displayWidth / tangent;
  const distanceHeight = displayHeight / tangent;

  const maxDistance = Math.max(distanceWidth / ratio, distanceHeight);

  const parallelScale = maxDistance * tangent * 1.3;

  return parallelScale;
}
