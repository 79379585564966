import { Nullable } from "@kitware/vtk.js/types";
import { useCurrentImage } from "../composables/useCurrentImage";
import { useDICOMStore } from "../store/datasets-dicom";

export function getPatientName(): Nullable<string> {
    const { currentImageID } = useCurrentImage();
    const dicomStore = useDICOMStore();
    if (
        currentImageID.value !== null &&
        currentImageID.value in dicomStore.imageIDToVolumeKey
    ) {
        const volumeKey = dicomStore.imageIDToVolumeKey[currentImageID.value];
        const studyKey = dicomStore.volumeStudy[volumeKey];
        const patientKey = dicomStore.studyPatient[studyKey];
        const patientInfo = dicomStore.patientInfo[patientKey];
        return patientInfo.PatientName;
    }
    return null;
}
