import macro from '@kitware/vtk.js/macros';
import vtkCutter from '@kitware/vtk.js/Filters/Core/Cutter';
import { getCenter } from '@kitware/vtk.js/Common/DataModel/BoundingBox';
import vtkPlane from '@kitware/vtk.js/Common/DataModel/Plane';
import vtkAppendPolyData from '@kitware/vtk.js/Filters/General/AppendPolyData';

import vtkMultiCutter from '../MultiCutter';
import vtkScalarsFilter from '../ScalarsFilter';

// ----------------------------------------------------------------------------
// vtkImplantFilter methods
// ----------------------------------------------------------------------------

function vtkImplantFilter(publicAPI, model) {
  // Set our classname
  model.classHierarchy.push('vtkImplantFilter');

  publicAPI.requestData = (inData, outData) => {
    const implantPolydata = inData[0];
    const markerPolydata = inData[1];

    // Add scalar filter to the original polydata to facilitate implant picking 
    const originalImplantScalarsFilter = vtkScalarsFilter.newInstance({
      color: [0, 0, 0],
      opacity: 1,
    });
    originalImplantScalarsFilter.setInputData(implantPolydata);

    // Cut implant model, set its color to cyan blue and change opacity.
    let implantCutter = null;
    if (model.implantNbSlices === 1) {
      implantCutter = vtkCutter.newInstance();
      const implantCuttingPlane = vtkPlane.newInstance({
        origin: [0, 0, -1e-6],
        normal: model.viewNormal,
      });
      implantCutter.setCutFunction(implantCuttingPlane);
    } else {
      implantCutter = vtkMultiCutter.newInstance({
        normal: model.viewNormal,
        nbSlices: model.implantNbSlices,
      });
    }

    const cuttedImplantScalarsFilter = vtkScalarsFilter.newInstance({
      color: model.implantColor,
      opacity: model.implantOpacity
    });

    implantCutter.setInputConnection(originalImplantScalarsFilter.getOutputPort());
    cuttedImplantScalarsFilter.setInputConnection(implantCutter.getOutputPort());

    // Marker
    // Cut marker at its center
    const originalMarkerScalarsFilter = vtkScalarsFilter.newInstance({
      color: [0, 0, 0],
      opacity: 1,
    });
    originalMarkerScalarsFilter.setInputData(markerPolydata);

    const markerCutter = vtkCutter.newInstance();
    const markerCuttingPlane = vtkPlane.newInstance({
      origin: getCenter(markerPolydata.getBounds()),
      normal: model.viewNormal,
    });
    markerCutter.setCutFunction(markerCuttingPlane);
    markerCutter.setInputData(markerPolydata);

    const cuttedMarkerScalarsFilter = vtkScalarsFilter.newInstance({
      color: [255, 255, 0],
      opacity: 255,
    });
    cuttedMarkerScalarsFilter.setInputConnection(markerCutter.getOutputPort());

    // Append implant and marker
    const append = vtkAppendPolyData.newInstance();
    append.addInputConnection(cuttedMarkerScalarsFilter.getOutputPort());
    append.addInputConnection(originalMarkerScalarsFilter.getOutputPort());
    append.addInputConnection(cuttedImplantScalarsFilter.getOutputPort());
    append.addInputConnection(originalImplantScalarsFilter.getOutputPort());

    append.update();
    
    outData[0] = append.getOutputData();
  };
}

// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
  viewNormal: [1, 0, 0],
  implantColor: [0, 255, 255],
  implantOpacity: 255,
  implantNbSlices: 1,
};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  macro.obj(publicAPI, model);
  macro.algo(publicAPI, model, 2, 1);

  // Object specific methods
  vtkImplantFilter(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'vtkImplantFilter');

// ----------------------------------------------------------------------------

export default { newInstance, extend };
